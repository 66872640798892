/* eslint-disable @typescript-eslint/naming-convention */
import firebase from 'firebase/compat/app';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductType, ProductUomType } from './types';
import { Guid } from '@core/models/common-types';
import { PTableColumn } from '@core/models/common-ux-types';

export class Product {
  id?: Guid;
  clientId: Guid;
  // itemName?: string;
  description?: string;
  itemNumber: string;
  imageUrl?: string;
  imagePath?: string;
  length?: string;
  manufacturer?: string;
  price?: number;
  productType: ProductType;
  sku?: string;
  supplier?: string; // someday it will be a guid and be an Organization
  title: string;
  uomType: ProductUomType;
  updatedTimestamp: firebase.firestore.Timestamp;
  width?: string;
}

export class ProductItem {
  productId: Guid;
  itemNumber: string;
  length?: string;
  price: number;
  priceUpdatedTimestamp: firebase.firestore.Timestamp;
  quantity: number;
  title: string;
  total: number;
  uomType: ProductUomType;
  width?: string;
}

export class ProductListItem extends Product {
  currentPrice: number;
  isSelected: boolean;
  clientName?: string;
  updatedDateStr: string;
  quantity: number;
  total: number;

  static asFormGroup(productItem: ProductListItem): FormGroup {
    const fg = new FormGroup({
      id: new FormControl(productItem.id, Validators.required),
      // isSelected: new FormControl(productItem.isSelected, Validators.required),
      title: new FormControl(productItem.title, Validators.required),
      description: new FormControl(productItem.description),
      itemNumber: new FormControl(productItem.itemNumber, Validators.required),
      price: new FormControl(productItem.price, Validators.required),
      uomType: new FormControl(productItem.uomType),
      currentPrice: new FormControl(productItem.currentPrice),
      quantity: new FormControl(productItem.quantity, Validators.required),
      total: new FormControl(productItem.total, Validators.required),
      updatedDateStr: new FormControl(productItem.updatedDateStr, Validators.required),
    });
    return fg;
  }
}

export function toProductItem(item: ProductListItem): ProductItem {
  return {
    productId: item.id,
    itemNumber: item.itemNumber,
    title: item.title,
    quantity: item.quantity,
    price: item.price,
    length: item.length,
    width: item.width,
    total: item.total,
    priceUpdatedTimestamp: item.updatedTimestamp,
    uomType: item.uomType,
  };
}

export function getInstallationProductsString(products: ProductListItem[]): string {
  let str = '';
  products.forEach((prod) => (str = str + `${prod.title}, `));
  return str.slice(0, -2);
}

// export const productTableFormDisplayColumns =
//   ['itemNumber', 'title', 'description', 'quantity', 'price', 'uomType', 'total', 'currentPrice', 'updatedDateStr', 'actions'];

export const defaultProductListItem: ProductListItem = {
  clientId: '',
  clientName: '',
  description: '',
  itemNumber: '',
  manufacturer: '',
  price: 0,
  total: 0,
  productType: ProductType.PRODUCT,
  sku: '',
  supplier: '',
  title: '',
  updatedTimestamp: null,
  updatedDateStr: '',
  isSelected: false,
  currentPrice: 0,
  quantity: 0,
  uomType: ProductUomType.EACH,
  width: '',
  length: '',
};

export const dummyProductListItem: ProductListItem = {
  productType: ProductType.PRODUCT,
  clientName: '',
  clientId: '',
  description: 'This is the description',
  itemNumber: '0485001A',
  title: 'This is the title',
  supplier: 'supplier',
  sku: '',
  updatedTimestamp: null,
  updatedDateStr: '',
  // imageFile: null,
  total: 0,
  quantity: 0,
  isSelected: false,
  currentPrice: 0,
  uomType: ProductUomType.EACH,
  width: '4"',
  length: '7"',
};

// export const userProductTableDisplayColumns2 =
//   ['productType', 'itemNumber', 'title', 'description', 'supplier',
//     'price', 'uomType', 'length', 'width', 'updatedDateStr', 'imagePath', 'actions'];

// export const adminProductListDisplayColumns2 =
//   ['clientName', 'productType', 'itemNumber', 'title', 'manufacturer', 'description',
//     'supplier', 'price', 'uomType', 'length', 'width', 'updatedDateStr', 'imagePath', 'actions'];
// PUNT FOR NOW
// ['title', 'content', 'price', 'imagePath'];

export const userProductTableDisplayColumns: PTableColumn[] = [
  { field: 'productType', header: 'Type', width: '8%' },
  { field: 'itemNumber', header: 'Item #', width: '5%' },
  { field: 'title', header: 'Title', width: '12%' },
  { field: 'description', header: 'Description', width: '22%' },
  { field: 'manufacturer', header: 'manufacturer', width: '12%' },
  { field: 'supplier', header: 'supplier', width: '12%' },
  { field: 'price', header: 'Price', width: '12%' },
  { field: 'length', header: 'length', width: '12%' },
  { field: 'width', header: 'Width', width: '12%' },
  { field: 'uomType', header: 'UOM', width: '12%' },
  { field: 'updatedDateStr', header: 'Updated', width: '12%' },
  { field: 'actions', header: '', width: '12%' },
];
export const adminProductListDisplayColumns: PTableColumn[] = [
  { field: 'clientName', header: 'clientName', width: '8%' },
  ...userProductTableDisplayColumns,
];

export function compareProducts(c1: Product, c2: Product) {
  const compareIt = c1.title.localeCompare(c2.title);

  if (compareIt > 0) {
    return 1;
  } else if (compareIt < 0) {
    return -1;
  } else return 0;
}
