import { createSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { EditingStatus } from 'app/core/models/common-types';
import { ClientState } from './client.state.interface';

export const clientStateSelector = (state: AppState): ClientState => state.clientState;

export const clientListItemsSelector = createSelector(
  clientStateSelector,
  (state: ClientState) => (state.clientListItems ? state.clientListItems : [])
  // (state: RcAdminState) => state.customers? state.customers.sort((a, b) => a.name.localeCompare(b.name)) : []
);

export const clientDropdownItemsSelector = createSelector(
  clientStateSelector,
  (state: ClientState) => (state.clientDropdownItems ? state.clientDropdownItems : [])
  // (state: RcAdminState) => state.customers? state.customers.sort((a, b) => a.name.localeCompare(b.name)) : []
);
export const selectedClientSelector = createSelector(
  clientStateSelector,
  (state: ClientState) => state.selectedClient ?? undefined
);

export const isClientSelectedSelector = createSelector(clientStateSelector, (state: ClientState) =>
  state.selectedClient ? true : false
);

// export const loadedUserClientSelector = createSelector(
//   clientStateSelector,
//   (state: ClientState) => state.loadedUserClient ? state.loadedUserClient : undefined
// );

export const clientEditingStatusSelector = createSelector(clientStateSelector, (state: ClientState) =>
  state.clientEditingStatus ? state.clientEditingStatus : EditingStatus.NOT_EDITING
);
