import { ClientListItem, toClientListItem } from 'app/features/admin/clients/model/Client';
import { ClientsActions } from './action-types';
import { INITIAL_CLIENTS_STATE } from './client.state.interface';
import { createReducer, on } from '@ngrx/store';

export const clientsReducer = createReducer(
  INITIAL_CLIENTS_STATE,

  on(ClientsActions.ClientsLoad, ClientsActions.ClientsLoadFail, (state) => ({
    ...state,
    selectedClient: undefined,
    clientListItems: [],
    clientDropdownItems: [],
  })),
  on(ClientsActions.ClientsLoadSuccess, (state, { clients }) => {
    const clientListItems: ClientListItem[] = [];
    const clientDropdownItems = [];

    clients.forEach((client) => {
      clientListItems.push(toClientListItem(client));
      clientDropdownItems.push({
        id: client.id,
        name: client.name,
      });
    });
    return {
      ...state,
      clientListItems: clientListItems.sort((a, b) => a.name.localeCompare(b.name)),
      clientDropdownItems: clientDropdownItems.sort((a, b) => a.name.localeCompare(b.name)),
    };
  }),
  on(ClientsActions.ClientsLoadClientForUserSuccess, (state, { clientListItem }) => {
    return {
      ...state,
      selectedClient: clientListItem
    };
  }),
  on(ClientsActions.SetClientEditingStatus, (state, { editingStatus }) => {
    return {
      ...state,
      editingStatus,
    };
  }),
  on(ClientsActions.SetSelectedClient, (state, { clientListItem }) => {
    return {
      ...state,
      selectedClient: clientListItem,
    };
  }),
  on(ClientsActions.SetClientEditingStatus, (state, { editingStatus }) => {
    return {
      ...state,
      editingStatus,
    };
  }),
);
