import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { UiStateService } from 'app/core/store/ui/state-service';
import { FirestoreCollectionType, Guid } from 'app/core/models/common-types';
import { Observable, from } from 'rxjs';
import { PROJASTIC_ADMIN_CLIENT_ID } from 'app/core/models/common-ux-types';
import { Product, ProductListItem } from './model/Product';

@Injectable({ providedIn: 'root' })
export class ProductService {
  constructor(
    private db: AngularFirestore,
    // private uiService: UiService,
    // private productStateService: ProductStateService,
    private uiStateService: UiStateService
  ) { }

  loadProducts(clientId: Guid) {
    this.uiStateService.startLoading();
    if (clientId === PROJASTIC_ADMIN_CLIENT_ID) {
      const customerCollection = this.db.collection<Product>(FirestoreCollectionType.PRODUCTS, (ref) =>
        ref.orderBy('title', 'asc')
      );
      return customerCollection.snapshotChanges();
    } else {
      const customerCollection = this.db.collection<Product>(FirestoreCollectionType.PRODUCTS, (x) =>
        x.where('clientId', '==', clientId)
      );
      return customerCollection.snapshotChanges();
    }
    // or .valueChanges() does not have the id
  }

  // loadProducts(sortField: string, directionStr: firebase.firestore.OrderByDirection) {

  //   this.uiStateService.startLoading();
  //   const subscription = this.db
  //     .collection<Product>(FirestoreCollectionType.PRODUCTS, x => x.orderBy(sortField, directionStr))
  //     .snapshotChanges().pipe(
  //       map(docArray =>
  //         docArray.map(a => {
  //           const data = a.payload.doc.data() as Product;
  //           const id = a.payload.doc['id'];
  //           return {
  //             ...data,
  //             id
  //           } as ProductListItem;
  //         })
  //       ))
  //     .subscribe(
  //       (products: ProductListItem[]) => {
  //         this.uiStateService.stopLoading();
  //         this.productStateService.loadProductsSuccess(products);
  //         subscription.unsubscribe();
  //       },
  //       error => {
  //         console.log('MS loadProducts error = ', error);
  //         this.uiStateService.stopLoading();
  //         this.uiService.showSnackbar(
  //           'Fetching Products failed',
  //           null,
  //           3000
  //         );
  //         subscription.unsubscribe();
  //       }
  //     )
  // }

  addProduct(product: Product): Observable<DocumentReference<any>> {
    //  return this.http.post<CustomerResponse>(AppServiceApiConfig.customers, customer);
    return from(this.db.collection(FirestoreCollectionType.PRODUCTS).add(product));
  }

  deleteProduct(productId: string) {
    const collection = `${FirestoreCollectionType.PRODUCTS}`;
    return from(this.db.collection(collection).doc(productId).delete());
  }

  deleteMultipleProducts(productIdList: string[]) {
    const batch = this.db.firestore.batch();
    productIdList.forEach((productId) => {
      const transDoc = this.db.firestore.doc(`${FirestoreCollectionType.PRODUCTS}/${productId}`);
      batch.delete(transDoc);
    });
    return from(batch.commit());
  }

  updateProduct(productListItem: ProductListItem) {
    const collection = `${FirestoreCollectionType.PRODUCTS}`;
    return from(this.db.collection(collection).doc(productListItem.id).set(this.toProductPayload(productListItem)));
  }

  private toProductPayload(productListItem: ProductListItem): Product {
    const product: Product = {
      clientId: productListItem.clientId,
      description: productListItem.description,
      itemNumber: productListItem.itemNumber,
      imagePath: productListItem.imagePath ?? null,
      imageUrl: productListItem.imageUrl ?? null,
      price: productListItem.price,
      productType: productListItem.productType,
      supplier: productListItem.supplier ?? null,
      title: productListItem.title,
      manufacturer: productListItem.manufacturer ?? null,
      updatedTimestamp: productListItem.updatedTimestamp,
      uomType: productListItem.uomType,
    };
    return product;
  }
}
