import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, catchError, map, tap, withLatestFrom } from 'rxjs/operators';

import { ClientService } from 'app/features/admin/clients/clients.service';
import { Router } from '@angular/router';
import { UiStateService } from '../../../../core/store/ui/state-service';
import { UiService } from 'app/core/services/ui/ui.service';
import { EditingStatus } from 'app/core/models/common-types';
import { Client, DEFAULT_REPORT_HEADER_COLOR, toClientListItem } from 'app/features/admin/clients/model/Client';
import { User } from 'app/auth/model/User';
import { AuthStateService } from 'app/auth/store/auth.state-service';
// import { AuthStateService } from 'app/auth/store/auth.state-service';
import { ClientStateService } from './client.state-service';
import { ClientsActions } from './action-types';

@Injectable()
export class ClientListEffects {
  // @Effect({ dispatch: false })
  // clientListLoadClients2$ = createEffect(() => this.actions$.pipe(
  //   ofType<ClientListLoadClients>(CLIENT_LIST_LOAD_CLIENTS),
  //   map(() => {
  //     this.clientsService.loadClients('name', 'asc');
  //     })
  // );

  clientListLoadClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.ClientsLoad),
      switchMap(() =>
        this.clientsService.loadClients('name', 'asc').pipe(
          map((docArray) => {
            const clients: Client[] = docArray.map((a) => {
              const data = a.payload.doc.data() as Client;
              const id = a.payload.doc['id'];
              return {
                ...data,
                id,
                //  reportHeaderColor: data.reportHeaderColor ?? DEFAULT_REPORT_HEADER_COLOR
              };
            });
            this.uiStateService.stopLoading();
            return ClientsActions.ClientsLoadSuccess({ clients });
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            if (error.code !== 'permission-denied') {
              this.uiService.showSnackbar('Fetching Clients failed', null, 3000);
            }
            return of(ClientsActions.ClientsLoadFail(error));
          })
        )
      )
    )
  );

  loadClientForUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.ClientsLoadClientForUser),
      withLatestFrom(
        this.authStateService.loggedInUser$
        //  this.authStateService.isClientUser$
      ),
      switchMap(([_, user]: [Action, User]) =>
        this.clientsService.loadClient(user.clientId).pipe(
          map((client: Client) => {
            console.log('MS ClientsLoadClientForUser client = ', client);
            const newClient: Client = {
              id: user.clientId,
              reportHeaderColor: client.reportHeaderColor ?? DEFAULT_REPORT_HEADER_COLOR,
              ...client
            }
            const clientListItem = toClientListItem(newClient);
            this.uiStateService.stopLoading();
            return ClientsActions.ClientsLoadClientForUserSuccess({ clientListItem });
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            if (error.code !== 'permission-denied') {
              this.uiService.showSnackbar('Fetching Clients failed', null, 3000);
            }
            return of(ClientsActions.ClientsLoadClientForUserFail(error));
          })
        )
      )
    )
  );

  // @Effect({ dispatch: false })
  clientsLoadClientForUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientsActions.ClientsLoadClientForUserSuccess,
          ClientsActions.SetSelectedClient
        ),
        withLatestFrom(this.authStateService.isUserAuthenticated$),
        map(([action, isUserAuthenticated]: [Action, boolean]) => {
          console.log('MS clientsLoadClientForUserSuccess isUserAuthenticated = ', isUserAuthenticated);
          if (isUserAuthenticated) {
            // this.propertyStateService.loadProperties();
            // const fetchPayloadData: FetchPayloadData = {
            //   entityId: action.payload.id,
            //   documentField: 'clientId'
            // }
            // this.customerStateService.loadCustomers(fetchPayloadData);
            // this.customerStateService.loadTenants(fetchPayloadData);
          }
          // this.serviceEventStateService.loadServicePointPackages();
          // this.router.navigate(['/admin']);
        })
      ),
    { dispatch: false }
  );

  //   map(([action, user, isClientUser]: [
  //     LoadClientForUser,
  //     User,
  //     boolean
  //   ]) => {
  //     if (isClientUser) {
  //       const selectedClient = findClientForUser(action.payload, user);
  //       if (!isNil(selectedClient)) {
  //         this.clientStateService.setSelectedClient(selectedClient);
  //       }
  //     }
  //   })
  // );

  clientListAddClient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.ClientsAdd),
      switchMap((action) =>
        this.clientsService.addClient(action.client).pipe(
          map(() => ClientsActions.ClientsAddSuccess({ client: action.client })),
          catchError((error) => of(ClientsActions.ClientsAddFail(error)))
        )
      )
    )
  );

  clientListAddMultipleClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.ClientsAddMultipleClients),
      switchMap((action) =>
        this.clientsService.addMultipleClients(action.clients).pipe(
          map(() => ClientsActions.ClientsAddMultipleClientsSuccess({ clients: action.clients })),
          catchError((error) => of(ClientsActions.ClientsAddMultipleClientsFail(error)))
        )
      )
    )
  );

  clientListUpdateClient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.ClientsUpdate),
      switchMap((action) =>
        this.clientsService.updateClient(action.clientListItem).pipe(
          map(() => ClientsActions.ClientsUpdateSuccess({ clientListItem: action.clientListItem })),
          catchError((error) => of(ClientsActions.ClientsUpdateFail(error)))
        )
      )
    )
  );

  clientListDeleteClient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.ClientsDelete),
      switchMap((action) =>
        this.clientsService.deleteClient(action.clientId).pipe(
          map(() => ClientsActions.ClientsDeleteSuccess()),
          catchError((error) => of(ClientsActions.ClientsDeleteFail(error)))
        )
      )
    )
  );

  // deleteClientSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(

  //       ofType(ClientsActions.ClientsAddSuccess, ClientsActions.ClientsUpdateSuccess),

  //       ofType<ClientListDeleteClientSuccess>(
  //         CLIENT_LIST_DELETE_CLIENT_SUCCESS,
  //         CLIENT_LIST_UPDATE_CLIENT_SUCCESS,
  //         CLIENT_LIST_ADD_MULTIPLE_CLIENTS_SUCCESS,
  //         CLIENT_LIST_ADD_CLIENT_SUCCESS
  //       ),
  //       map(() => {
  //         this.clientStateService.loadClients();
  //       })
  //     ),
  //   { dispatch: false }
  // );

  loadAndNavigateOnFinish$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientsActions.ClientsAddSuccess,
          ClientsActions.ClientsUpdateSuccess,
          ClientsActions.ClientsDeleteSuccess,
          ClientsActions.ClientsAddMultipleClientsSuccess
        ),
        map(() => {
          // console.log('MS navigateOnFinish ADMIN_ADD_CUSTOMER_SUCCESS!');
          // this.serviceEventStateService.loadServicePointPackages();
          this.clientStateService.loadClients();
          this.router.navigate(['/admin/clients-list']);
        })
      ),
    { dispatch: false }
  );

  setClientEditingStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientsActions.SetClientEditingStatus),
        tap((action) => {
          console.log('MS SetClientEditingStatus action.payload= ', action.editingStatus);
          this.uiStateService.setIsEditingEntity(action.editingStatus !== EditingStatus.NOT_EDITING);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private uiService: UiService,
    private authStateService: AuthStateService,
    private clientStateService: ClientStateService,
    private clientsService: ClientService,
    private uiStateService: UiStateService
  ) { }
}
