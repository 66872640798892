import { PDropdownItem, SinglePluralString } from './common-ux-types';

export type JSONString = string;
export type CSVString = string;
export type Guid = string;

export const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy';
export const LIST_DATE_FORMAT = 'MMM dd, yyyy';
export const REPORT_DATE_FORMAT = 'yyyyMMdd';
export const LIST_DATE_FORMAT_NO = 'MMM dd yyyy';
export const LIST_DATE_FORMAT_NO2 = 'yyyy mm dd HH:mm';
export const LIST_DATE_TIME_FORMAT = 'MMM dd yyyy hh:mm a';

// export enum AmPmType {
//   AM = 'AM',
//   PM = 'PM',
// }
export enum FirestoreCollectionType {
  CLIENTS = 'pjClients',
  CUSTOMERS = 'Customers',
  CONTRACTORS = 'Contractors',
  TENANTS = 'Tenants',
  PROJECTS = 'Projects',
  PROPERTIES = 'Properties',
  PRODUCTS = 'Products',
  SERVICE_PRODUCTS = 'ServiceProducts',
  SETTINGS = 'projSettings',
  PROJECT_EVENTS = 'ProjectEvents',
  TOUCH_POINT_LOCATIONS = 'TouchPointProjects',
  USERS = 'pjUsers',
  REPORT_OPTIONS = 'ReportOptions',
  REPORT_DOCUMENTS = 'ReportDocuments',
  WORK_ORDERS = 'WorkOrders',
  WORK_ORDER_TYPES = 'WorkOrderTypes',
}

export enum UserType {
  SUPER_USER = 'SuperUser',
  ADMIN = 'Admin',
  // SUPERVISOR = 'Supervisor',
  // RESIDENTIAL_CUSTOMER = 'ResidentualCustomer',
  // LOCATION_MANAGER = 'ProjectManager',
  // DISTRICT_MANAGER = 'DistrictManager',
  CLIENT_PM = 'ClientPM',
  CLIENT_SALES_REP = 'ClientSalesRep',
  CLIENT_SUB_CONTRACTOR = 'ClientSubContractor',
  CLIENT_CUSTOMER = 'ClientCustomer',
  CLIENT_TECHNICIAN = 'ClientTechnician',
}

export const userTypePDropdownItems: PDropdownItem[] = [
  { value: UserType.ADMIN, viewValue: UserType.ADMIN },
  { value: UserType.CLIENT_PM, viewValue: 'Project Manager' },
  { value: UserType.CLIENT_SALES_REP, viewValue: 'Sales Rep' },
  { value: UserType.CLIENT_TECHNICIAN, viewValue: 'Technician' },
];

export enum WorkOrderDetailsTableType {
  EVENTS,
  PRODUCTS,
  SERVICES,
  ALL,
}

export enum ProjectType {
  RESIDENTIAL = 'Residential',
  COMMERICAL = 'Commercial',
  OTHER = 'Other',
}

export const projectTypePDropdownItems: PDropdownItem[] = [
  { value: ProjectType.RESIDENTIAL, viewValue: ProjectType.RESIDENTIAL },
  { value: ProjectType.COMMERICAL, viewValue: ProjectType.COMMERICAL },
  { value: ProjectType.OTHER, viewValue: ProjectType.OTHER },
];

// export const PlattFileTypes: string[] = [
//   PlattFileType.PLATT_ORDER, PlattFileType.PLATT_CART
//   // { value: ProductType.MATERIAL, viewValue: ProductType.MATERIAL },
//   //  { value: ProductType.SUPPLY, viewValue: ProductType.SUPPLY },
// ];


// export const SUPjUserTypes: PDropdownItem[] = [
//   { value: UserType.ADMIN, viewValue: 'Admin' },
//   { value: UserType.SUPERVISOR, viewValue: 'Supervisor' },
//   { value: UserType.TECHNICIAN, viewValue: 'Technician' },
//   ...CustomerPjUserTypes
// ];

// export function isClientUserType(UserType: UserType): boolean {
//   return (UserType === UserType.CLIENT_TECHNICIAN ||
//     UserType === UserType.CLIENT ||
//     UserType === UserType.CLIENT_CUSTOMER)
// }

export enum SubscriptionType {
  FREE_TRAIL = 'Free Trial',
  BASIC = 'Basic',
  PREMIUM = 'Premium',
  // FIRST_THREE_FREE = 'ThreeMonthsFree',
  //  ENTERPRISE = 'Enterprise',
  // SUB_CONTRACTOR = 'Contractor with Sub-Contractors and Customers',
}
export const subscriptionTypePDropdownItems: PDropdownItem[] = [
  {
    value: SubscriptionType.FREE_TRAIL,
    viewValue: SubscriptionType.FREE_TRAIL,
  },
  { value: SubscriptionType.BASIC, viewValue: SubscriptionType.BASIC },
  { value: SubscriptionType.PREMIUM, viewValue: SubscriptionType.PREMIUM },
  //  { value: SubscriptionType.ENTERPRISE, viewValue: SubscriptionType.ENTERPRISE },
];
export enum SubscriptionLengthType {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
  // SUB_CONTRACTOR = 'Contractor with Sub-Contractors and Customers',
}
export const subscriptionLengthTypePDropdownItems: PDropdownItem[] = [
  {
    value: SubscriptionLengthType.MONTHLY,
    viewValue: SubscriptionLengthType.MONTHLY,
  },
  {
    value: SubscriptionLengthType.YEARLY,
    viewValue: SubscriptionLengthType.YEARLY,
  },
];

export enum ClientBusinessModelType {
  CONTRACTOR = 'Contractor',
  SUB_CONTRACTOR = 'SubContractor',
  PROPERTY_MANAGER = 'PropertyManager',
  // SUB_CONTRACTOR = 'Contractor with Sub-Contractors and Customers',
}
export const clientBusinessModelTypePDropdownItems: PDropdownItem[] = [
  {
    value: ClientBusinessModelType.CONTRACTOR,
    viewValue: ClientBusinessModelType.CONTRACTOR,
  },
  {
    value: ClientBusinessModelType.SUB_CONTRACTOR,
    viewValue: ClientBusinessModelType.SUB_CONTRACTOR,
  },
  {
    value: ClientBusinessModelType.PROPERTY_MANAGER,
    viewValue: 'Property Manager',
  },
];

export enum WorkOrderEditType {
  LOCATION = 'LOCATION',
  REQUEST = 'REQUEST',
  DETAILS = 'DETAILS',
  ASSIGNMENT = 'ASSIGNMENT',
}
export enum PriorityType {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  EXTREME = 'ASAP',
  // SUB_CONTRACTOR = 'Contractor with Sub-Contractors and Customers',
}
export const PriorityTypes: PDropdownItem[] = [
  { value: PriorityType.LOW, viewValue: PriorityType.LOW },
  { value: PriorityType.MEDIUM, viewValue: PriorityType.MEDIUM },
  { value: PriorityType.HIGH, viewValue: PriorityType.HIGH },
  { value: PriorityType.EXTREME, viewValue: PriorityType.EXTREME },
];

export enum ClientType {
  PROJASTIC_CLIENT,
  // SERVICE_GAMBIT_CLIENT_CUSTOMER = 'SG Client Customer',`
}
export enum ClientServiceType {
  SERVICE_GENERAL = 'General Contractor',
  SERVICE_ELECTRIC = 'Electrical Contractor',
  SERVICE_FLOORING = 'Flooring Contractor',
  SERVICE_MITIGATION = 'Mitigation Contractor',
  SERVICE_PLUMBING = 'Plumbing Contractor',
  SERVICE_ROOFING = 'Roofing Contractor',
  SERVICE_HVAC = 'HVAC Contractor',
  SERVICE_JANITORIAL = 'Janitorial Contractor',
  SERVICE_CABINET = 'Cabinet Contractor',
  SERVICE_LANDSCAPING = 'Landscaping Contractor',
  SERVICE_HOME_IMPROVEMENT = 'Home Improvement',
  SERVICE_HANDYMAN = 'Handyman',
  SERVICE_AUTOMOTIVE = 'Automotive Service',
  PROP_MANAGER = 'Property Manager',
  SERVICE_OTHER = 'Other',
  // SERVICE_GAMBIT_CLIENT_CUSTOMER = 'SG Client Customer',
}
export const clientServiceTypePDropdownItems: PDropdownItem[] = [
  {
    value: ClientServiceType.SERVICE_GENERAL,
    viewValue: ClientServiceType.SERVICE_GENERAL,
  },
  {
    value: ClientServiceType.SERVICE_ELECTRIC,
    viewValue: ClientServiceType.SERVICE_ELECTRIC,
  },
  {
    value: ClientServiceType.SERVICE_FLOORING,
    viewValue: ClientServiceType.SERVICE_FLOORING,
  },
  {
    value: ClientServiceType.SERVICE_MITIGATION,
    viewValue: ClientServiceType.SERVICE_MITIGATION,
  },
  {
    value: ClientServiceType.SERVICE_PLUMBING,
    viewValue: ClientServiceType.SERVICE_PLUMBING,
  },
  {
    value: ClientServiceType.SERVICE_JANITORIAL,
    viewValue: ClientServiceType.SERVICE_JANITORIAL,
  },
  {
    value: ClientServiceType.SERVICE_CABINET,
    viewValue: ClientServiceType.SERVICE_CABINET,
  },
  {
    value: ClientServiceType.SERVICE_LANDSCAPING,
    viewValue: ClientServiceType.SERVICE_LANDSCAPING,
  },
  {
    value: ClientServiceType.SERVICE_AUTOMOTIVE,
    viewValue: ClientServiceType.SERVICE_AUTOMOTIVE,
  },
  {
    value: ClientServiceType.SERVICE_HOME_IMPROVEMENT,
    viewValue: ClientServiceType.SERVICE_HOME_IMPROVEMENT,
  },
  {
    value: ClientServiceType.SERVICE_HANDYMAN,
    viewValue: ClientServiceType.SERVICE_HANDYMAN,
  },
  {
    value: ClientServiceType.PROP_MANAGER,
    viewValue: ClientServiceType.PROP_MANAGER,
  },
  {
    value: ClientServiceType.SERVICE_OTHER,
    viewValue: ClientServiceType.SERVICE_OTHER,
  },
];

export function getServiceLocationStr(clientServiceType: ClientServiceType, optional = true): string {
  switch (clientServiceType) {
    case ClientServiceType.SERVICE_AUTOMOTIVE:
      return 'Vehicle';
    default:
      if (optional) {
        return 'Service Location (Optional)';
      }
      return 'Service Location';
  }
}

// export enum SgImageType {
//   LARGE_IMAGE = 'Large Image',
//   SMALL_IMAGE = 'Small Image',
//   IMAGE_NONE = 'No Image',
//   // SERVICE_GAMBIT_CLIENT_CUSTOMER = 'SG Client Customer',
// }
// export const SgImageTypes: PDropdownItem[] = [
//   { value: SgImageType.IMAGE_NONE, viewValue: SgImageType.IMAGE_NONE },
//   { value: SgImageType.LARGE_IMAGE, viewValue: SgImageType.LARGE_IMAGE },
//   { value: SgImageType.SMALL_IMAGE, viewValue: SgImageType.SMALL_IMAGE }
// ];

// export enum OrganizationType {
//   CONTRACTOR = 'Contractor',
//   SUB_CONTRACTOR = 'SubContractor',
//   RESIDENTIAL = 'Residential',
//   SUPPLIER = 'Supplier',
//   CUSTOMER = 'Customer',
//   LOCATION = 'Project'
// }

// export const OrganizationTypes: PDropdownItem[] = [
//   { value: OrganizationType.RESIDENTIAL, viewValue: OrganizationType.RESIDENTIAL },
//   { value: OrganizationType.CONTRACTOR, viewValue: OrganizationType.CONTRACTOR },
//   { value: OrganizationType.SUB_CONTRACTOR, viewValue: OrganizationType.SUB_CONTRACTOR },
//   { value: OrganizationType.SUPPLIER, viewValue: OrganizationType.SUPPLIER },
//   { value: OrganizationType.CUSTOMER, viewValue: OrganizationType.CUSTOMER },
//   { value: OrganizationType.LOCATION, viewValue: OrganizationType.LOCATION },
// ];

export function getCustomerType(customerEditType: CustomerEditType): CustomerType {
  // let str = 'Project';
  switch (customerEditType) {
    case CustomerEditType.PROPERTY_CONTRACTOR:
      return CustomerType.CONTRACTOR;
    case CustomerEditType.TENANT:
      return CustomerType.TENANT;
    default: {
      return null;
    }
  }
}

export enum TenantType {
  RESIDENTIAL = 'Residential',
  COMMERCIAL = 'Commercial',
  //  COMMERCIAL_MULTIPLE_LOCATIONS = 'CommercialMultipleFacilities',
  // HOTEL_SINGLE_LOCATION = 'HotelSingleProject',
  // HOTEL_MULTIPLE_LOCATIONS = 'HotelMultipleFacilities',
  // HEALTH_CARE_COMPANY = 'HealthCare',
  // HEALTH_CARE_LOCATION = 'HealthCareProject',
  // SCHOOL_DISTRICT = 'SchoolDistrict',
  // SCHOOL_DISTRICT_LOCATION = 'SchoolDistrictProject',
  // INDUSTRIAL_COMPANY = 'IndustrialCompany',
  // INDUSTRIAL_LOCATION = 'IndustrialProject',
  // CHURCH_MULTIPLE_FACILITIES = 'ChurchMultipleFacilites',
  // CHURCH_LOCATION = 'ChurchProject',
  // AIRLINE_COMPANY = 'AirlineCompany',
  // AIRLINE_LOCATION = 'AirlineProject',
  DEPARTMENT_STORE = 'Department Store',
  // DEPARTMENT_STORE_LOCATION = 'DepartmentStore',
  RETAIL_STORE = 'Retail Store',
  // RETAIL_STORE_LOCATION = 'RetailStoreProject',
  // RESTAURANT_COMPANY = 'RestaurantCompany',
  // RESTAURANT_LOCATION = 'RestaurantProject',
  OTHER = 'Other',
}
export const TenantTypes: PDropdownItem[] = [
  { value: TenantType.RESIDENTIAL, viewValue: TenantType.RESIDENTIAL },
  //  { value: TenantType.RETAIL_STORE_COMPANY, viewValue: TenantType.RETAIL_STORE_COMPANY },
  { value: TenantType.COMMERCIAL, viewValue: TenantType.COMMERCIAL },
  { value: TenantType.OTHER, viewValue: TenantType.OTHER },
];

export enum CustomerType {
  RESIDENTIAL = 'Residential',
  COMMERCIAL = 'Commercial',
  CONTRACTOR = 'Contractor',
  SUB_CONTRACTOR = 'SubContractor',
  TENANT = 'Tenant',
  //  COMMERCIAL_MULTIPLE_LOCATIONS = 'CommercialMultipleFacilities',
  // HOTEL_SINGLE_LOCATION = 'HotelSingleProject',
  // HOTEL_MULTIPLE_LOCATIONS = 'HotelMultipleFacilities',
  // HEALTH_CARE_COMPANY = 'HealthCare',
  // HEALTH_CARE_LOCATION = 'HealthCareProject',
  // SCHOOL_DISTRICT = 'SchoolDistrict',
  // SCHOOL_DISTRICT_LOCATION = 'SchoolDistrictProject',
  // INDUSTRIAL_COMPANY = 'IndustrialCompany',
  // INDUSTRIAL_LOCATION = 'IndustrialProject',
  // CHURCH_MULTIPLE_FACILITIES = 'ChurchMultipleFacilites',
  // CHURCH_LOCATION = 'ChurchProject',
  // AIRLINE_COMPANY = 'AirlineCompany',
  // AIRLINE_LOCATION = 'AirlineProject',
  // DEPARTMENT_STORE_COMPANY = 'DepartmentStoreCompany',
  // DEPARTMENT_STORE_LOCATION = 'DepartmentStore',
  // RETAIL_STORE_COMPANY = 'RetailStoreCompany',
  // RETAIL_STORE_LOCATION = 'RetailStoreProject',
  // RESTAURANT_COMPANY = 'RestaurantCompany',
  // RESTAURANT_LOCATION = 'RestaurantProject',
  OTHER = 'Other',
}

export const customerTypePDropdownItems: PDropdownItem[] = [
  { value: CustomerType.RESIDENTIAL, viewValue: CustomerType.RESIDENTIAL },
  { value: CustomerType.COMMERCIAL, viewValue: CustomerType.COMMERCIAL },
  { value: CustomerType.CONTRACTOR, viewValue: CustomerType.CONTRACTOR },
  {
    value: CustomerType.SUB_CONTRACTOR,
    viewValue: CustomerType.SUB_CONTRACTOR,
  },
  { value: CustomerType.TENANT, viewValue: CustomerType.TENANT },
  { value: CustomerType.OTHER, viewValue: CustomerType.OTHER },
];

export enum PropertyType {
  RESIDENTIAL = 'Residential',
  COMMERCIAL = 'Commercial',
  //  TENANT = 'Tenent',
  //  COMMERCIAL_MULTIPLE_LOCATIONS = 'CommercialMultipleFacilities',
  // HOTEL_SINGLE_LOCATION = 'HotelSingleProject',
  // HOTEL_MULTIPLE_LOCATIONS = 'HotelMultipleFacilities',
  // HEALTH_CARE_COMPANY = 'HealthCare',
  // HEALTH_CARE_LOCATION = 'HealthCareProject',
  // SCHOOL_DISTRICT = 'SchoolDistrict',
  // SCHOOL_DISTRICT_LOCATION = 'SchoolDistrictProject',
  // INDUSTRIAL_COMPANY = 'IndustrialCompany',
  // INDUSTRIAL_LOCATION = 'IndustrialProject',
  // CHURCH_MULTIPLE_FACILITIES = 'ChurchMultipleFacilites',
  // CHURCH_LOCATION = 'ChurchProject',
  // AIRLINE_COMPANY = 'AirlineCompany',
  // AIRLINE_LOCATION = 'AirlineProject',
  // DEPARTMENT_STORE_COMPANY = 'DepartmentStoreCompany',
  // DEPARTMENT_STORE_LOCATION = 'DepartmentStore',
  // RETAIL_STORE_COMPANY = 'RetailStoreCompany',
  // RETAIL_STORE_LOCATION = 'RetailStoreProject',
  // RESTAURANT_COMPANY = 'RestaurantCompany',
  // RESTAURANT_LOCATION = 'RestaurantProject',
  OTHER = 'Other',
}

export const propertyTypePDropdownItems: PDropdownItem[] = [
  { value: PropertyType.COMMERCIAL, viewValue: PropertyType.COMMERCIAL },
  { value: PropertyType.RESIDENTIAL, viewValue: PropertyType.RESIDENTIAL },
  { value: PropertyType.OTHER, viewValue: PropertyType.OTHER },
];

// export function getCustomerSPSFromUserType(userType: UserType, isAdmin: boolean): SinglePluralString {
//   // let str = 'Project';
//   switch (userType) {
//     case UserType.CLIENT_PM:
//       if (isAdmin) {
//         return {
//           single: 'Contractor',
//           plural: 'Contractors'
//         }
//       }
//       return {
//         single: 'Tenant',
//         plural: 'Tenants'
//       }
//     default: {
//       return {
//         single: 'Customer',
//         plural: 'Customers'
//       }
//     }
//   }
// }

export function getCustomerSPSFromClientServiceType(
  clientServiceType: ClientServiceType,
  isAdmin: boolean
): SinglePluralString {
  // let str = 'Project';
  switch (clientServiceType) {
    case ClientServiceType.PROP_MANAGER:
      if (isAdmin) {
        return {
          single: 'Contractor',
          plural: 'Contractors',
        };
      }
      return {
        single: 'Tenant',
        plural: 'Tenants',
      };
    default: {
      return {
        single: 'Customer',
        plural: 'Customers',
      };
    }
  }
}

export function getProjectTypeStrObj(rcCustomerType: CustomerType): SinglePluralString {
  // let str = 'Project';
  switch (rcCustomerType) {
    // case CustomerType.AIRLINE_COMPANY:
    // case CustomerType.AIRLINE_LOCATION:
    // case CustomerType.CHURCH_MULTIPLE_FACILITIES:
    // case CustomerType.CHURCH_LOCATION:
    // case CustomerType.INDUSTRIAL_COMPANY:
    // case CustomerType.INDUSTRIAL_LOCATION:
    // case CustomerType.HEALTH_CARE_COMPANY:
    // case CustomerType.HEALTH_CARE_LOCATION:
    // case CustomerType.SCHOOL_DISTRICT:
    // case CustomerType.SCHOOL_DISTRICT_LOCATION:
    // case CustomerType.COMMERCIAL_SINGLE_LOCATION:
    // case CustomerType.COMMERCIAL_MULTIPLE_LOCATIONS:
    //   case CustomerType.HOTEL_MULTIPLE_LOCATIONS:
    //   case CustomerType.HOTEL_SINGLE_LOCATION:  {
    //   return {
    //     single: 'Project',
    //     plural: 'Facilities'
    //   }
    // }
    // case CustomerType.DEPARTMENT_STORE_COMPANY:
    // case CustomerType.DEPARTMENT_STORE_LOCATION:
    // case CustomerType.RETAIL_STORE_COMPANY:
    // case CustomerType.RETAIL_STORE_LOCATION: {
    //   return {
    //     single: 'Store',
    //     plural: 'Stores'
    //   }
    // }
    // // case CustomerType.HOTEL_MULTIPLE_LOCATIONS:
    // // case CustomerType.HOTEL_SINGLE_LOCATION: {
    // //   return {
    // //     single: 'Hotel',
    // //     plural: 'Hotels'
    // //   }
    // case CustomerType.RESIDENTIAL:
    // // }
    // case CustomerType.RESTAURANT_COMPANY:
    // case CustomerType.RESTAURANT_LOCATION:
    default: {
      return {
        single: 'Project',
        plural: 'Projects',
      };
    }
  }
}

export function getNewProjectTypeString(rcCustomerType: CustomerType): string {
  return `Enter ${getProjectTypeStrObj(rcCustomerType).single} Name`;
}

export function getProjectType(rcCustomerType: CustomerType): ProjectType {
  switch (rcCustomerType) {
    case CustomerType.COMMERCIAL:
      return ProjectType.COMMERICAL;
    //   case CustomerType.HOTEL_SINGLE_LOCATION:
    //   case CustomerType.HOTEL_MULTIPLE_LOCATIONS:
    //     return ProjectType.HOTEL_LOCATION;
    // case CustomerType.SCHOOL_DISTRICT:
    // case CustomerType.SCHOOL_DISTRICT_LOCATION:
    //   return ProjectType.SCHOOL_DISTRICT_LOCATION;
    case CustomerType.RESIDENTIAL:
      return ProjectType.RESIDENTIAL;
    // case CustomerType.HEALTH_CARE_COMPANY:
    // case CustomerType.HEALTH_CARE_LOCATION:
    //   return ProjectType.HEALTH_CARE_LOCATION;
    // case CustomerType.INDUSTRIAL_COMPANY:
    // case CustomerType.INDUSTRIAL_LOCATION:
    //   return ProjectType.INDUSTRIAL_LOCATION;
    // case CustomerType.CHURCH_MULTIPLE_FACILITIES:
    // case CustomerType.CHURCH_LOCATION:
    //   return ProjectType.CHURCH_FACILITY;
    // case CustomerType.AIRLINE_COMPANY:
    // case CustomerType.AIRLINE_LOCATION:
    //   return ProjectType.AIRLINE_LOCATION;
    // case CustomerType.DEPARTMENT_STORE_COMPANY:
    // case CustomerType.DEPARTMENT_STORE_LOCATION:
    //   return ProjectType.DEPARTMENT_STORE_LOCATION;
    // case CustomerType.RETAIL_STORE_COMPANY:
    // case CustomerType.RETAIL_STORE_LOCATION:
    //   return ProjectType.RETAIL_STORE_LOCATION;
    // case CustomerType.RESTAURANT_COMPANY:
    // case CustomerType.RESTAURANT_LOCATION:
    //   return ProjectType.RESTAURANT_LOCATION;
  }
  return ProjectType.OTHER;
}

export enum EditingStatus {
  NOT_EDITING = 'NOT_EDITING',
  ADDING = 'ADDING',
  EDITING = 'EDITING',
  DUPLICATING = 'DUPLICATING',
  REPORTING = 'REPORTING',
  ADDING_EVENT = 'ADDING_EVENT',
  CONFIRMATION = 'CONFIRMATION',
  DETAILS = 'DETAILS',
}
export enum PropertyViewStatus {
  PROPERTIES,
  TENANTS,
  USERS,
}
export enum CustomerEditType {
  CUSTOMER = 'Customer',
  TENANT = 'Tenant',
  PROPERTY_CONTRACTOR = 'Contractor',
}

// export function getCustomerEditType(UserType: UserType, isAdmin: boolean): CustomerEditType {
//   // let str = 'Project';
//   switch (UserType) {
//     case UserType.CLIENT_PM:
//       if (isAdmin) {
//         return CustomerEditType.PROPERTY_CONTRACTOR;
//       }
//       return CustomerEditType.TENANT;
//     default: {
//       return CustomerEditType.CUSTOMER;
//     }
//   }
// }

export enum WorkOrderEventStatus {
  OPEN = 'Open',
  ASSIGNED = 'Assigned',
  DISPATCHED = 'Dispatched',
  PAUSED = 'Paused',
  BID_REQUEST = 'Bid Requested',
  BID_ACCEPTED = 'Bid Accepted',
  SCHEDULED = 'Scheduled',
  STARTED = 'Started',
  COMPLETED = 'Completed',
  CLOSED = 'Closed',
  INVOICED = 'Invoiced',
  INVOICED_PAID = 'Invoice Paid',
  CANCELLED = 'Cancelled',
  VOID = 'Void',
}
export const WorkOrderEventStatusTypes: PDropdownItem[] = [
  { value: WorkOrderEventStatus.OPEN, viewValue: WorkOrderEventStatus.OPEN },
  {
    value: WorkOrderEventStatus.ASSIGNED,
    viewValue: WorkOrderEventStatus.ASSIGNED,
  },
  {
    value: WorkOrderEventStatus.DISPATCHED,
    viewValue: WorkOrderEventStatus.DISPATCHED,
  },
  {
    value: WorkOrderEventStatus.BID_REQUEST,
    viewValue: WorkOrderEventStatus.BID_REQUEST,
  },
  {
    value: WorkOrderEventStatus.BID_ACCEPTED,
    viewValue: WorkOrderEventStatus.BID_ACCEPTED,
  },
  {
    value: WorkOrderEventStatus.STARTED,
    viewValue: WorkOrderEventStatus.STARTED,
  },
  {
    value: WorkOrderEventStatus.SCHEDULED,
    viewValue: WorkOrderEventStatus.SCHEDULED,
  },
  {
    value: WorkOrderEventStatus.COMPLETED,
    viewValue: WorkOrderEventStatus.COMPLETED,
  },
  {
    value: WorkOrderEventStatus.CLOSED,
    viewValue: WorkOrderEventStatus.CLOSED,
  },
  {
    value: WorkOrderEventStatus.INVOICED,
    viewValue: WorkOrderEventStatus.INVOICED,
  },
  {
    value: WorkOrderEventStatus.INVOICED_PAID,
    viewValue: WorkOrderEventStatus.INVOICED_PAID,
  },
  {
    value: WorkOrderEventStatus.CANCELLED,
    viewValue: WorkOrderEventStatus.CANCELLED,
  },
  {
    value: WorkOrderEventStatus.PAUSED,
    viewValue: WorkOrderEventStatus.PAUSED,
  },
  { value: WorkOrderEventStatus.VOID, viewValue: WorkOrderEventStatus.VOID },
];

export enum ProjectStatus {
  OPEN = 'Open',
  PAUSED = 'Paused',
  BID_REQUEST = 'Bid Request',
  BID_INPROGRESS = 'Bid In Progress',
  BID_SUBMITTED = 'Bid Submitted',
  SCHEDULED = 'Scheduled',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  CLOSED = 'Closed',
  INVOICED = 'Invoiced',
  PAID_IN_FULL = 'Paid in Full',
  PARTIALLY_PAID = 'Partially Paid',
  CANCELLED = 'Cancelled',
  VOID = 'Void',
  REQUESTED = 'Requested',
}

export const ProjectStatusTypes: PDropdownItem[] = [
  { value: ProjectStatus.OPEN, viewValue: ProjectStatus.OPEN },
  { value: ProjectStatus.BID_REQUEST, viewValue: ProjectStatus.BID_REQUEST },
  {
    value: ProjectStatus.BID_INPROGRESS,
    viewValue: ProjectStatus.BID_INPROGRESS,
  },
  {
    value: ProjectStatus.BID_SUBMITTED,
    viewValue: ProjectStatus.BID_SUBMITTED,
  },
  { value: ProjectStatus.IN_PROGRESS, viewValue: ProjectStatus.IN_PROGRESS },
  { value: ProjectStatus.SCHEDULED, viewValue: ProjectStatus.SCHEDULED },
  { value: ProjectStatus.COMPLETED, viewValue: ProjectStatus.COMPLETED },
  { value: ProjectStatus.CLOSED, viewValue: ProjectStatus.CLOSED },
  { value: ProjectStatus.INVOICED, viewValue: ProjectStatus.INVOICED },
  { value: ProjectStatus.PAID_IN_FULL, viewValue: ProjectStatus.PAID_IN_FULL },
  {
    value: ProjectStatus.PARTIALLY_PAID,
    viewValue: ProjectStatus.PARTIALLY_PAID,
  },
  { value: ProjectStatus.CANCELLED, viewValue: ProjectStatus.CANCELLED },
  { value: ProjectStatus.PAUSED, viewValue: ProjectStatus.PAUSED },
  { value: ProjectStatus.REQUESTED, viewValue: ProjectStatus.REQUESTED },
  { value: ProjectStatus.VOID, viewValue: ProjectStatus.VOID },
];

export enum ServiceEventType {
  JOB = 'Job',
  BID = 'Bid',
  INVOICE = 'Invoice',
  CHANGE_ORDER = 'Change Order',
  // SCHEDULED = 'Scheduled',
  // IN_PROGRESS = 'In Progress',
  // COMPLETED = 'Completed',
  // INVOICED = 'Invoiced',
  // CANCELLED = 'Cancelled',
  // VOID = 'Void',
  // REQUESTED = 'Service(Job) Requested',
}

export const ServiceEventTypes: PDropdownItem[] = [
  { value: ServiceEventType.JOB, viewValue: ServiceEventType.JOB },
  { value: ServiceEventType.BID, viewValue: ServiceEventType.BID },
  { value: ServiceEventType.INVOICE, viewValue: ServiceEventType.INVOICE },
  {
    value: ServiceEventType.CHANGE_ORDER,
    viewValue: ServiceEventType.CHANGE_ORDER,
  },
  // { value: ServiceEventType.SCHEDULED, viewValue: ServiceEventType.SCHEDULED },
  // { value: ServiceEventType.COMPLETED, viewValue: ServiceEventType.COMPLETED },
  // { value: ServiceEventType.INVOICED, viewValue: ServiceEventType.INVOICED },
  // { value: ServiceEventType.CANCELLED, viewValue: ServiceEventType.CANCELLED },
  // { value: ServiceEventType.REQUESTED, viewValue: ServiceEventType.REQUESTED },
  // { value: ServiceEventType.VOID, viewValue: ServiceEventType.VOID }
];

export enum ProjectEventStatus {
  INITIALIZED = 'Initialzed',
  PAUSED = 'Paused',
  ESTIMATE = 'Estimate',
  BID_REQUEST = 'Bid Request',
  BID_INPROGRESS = 'Bid In Progress',
  BID_SUBMITTED = 'Bid Submitted',
  SCHEDULED = 'Scheduled',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  INVOICED = 'Invoiced',
  PAID_IN_FULL = 'Paid in Full',
  PARTIALLY_PAID = 'Partially Paid',
  CANCELLED = 'Cancelled',
  VOID = 'Void',
  REQUESTED = 'Requested',
}

export const ProjectEventStatusTypes: PDropdownItem[] = [
  {
    value: ProjectEventStatus.INITIALIZED,
    viewValue: ProjectEventStatus.INITIALIZED,
  },
  {
    value: ProjectEventStatus.ESTIMATE,
    viewValue: ProjectEventStatus.ESTIMATE,
  },
  {
    value: ProjectEventStatus.BID_REQUEST,
    viewValue: ProjectEventStatus.BID_REQUEST,
  },
  {
    value: ProjectEventStatus.BID_INPROGRESS,
    viewValue: ProjectEventStatus.BID_INPROGRESS,
  },
  {
    value: ProjectEventStatus.BID_SUBMITTED,
    viewValue: ProjectEventStatus.BID_SUBMITTED,
  },
  {
    value: ProjectEventStatus.IN_PROGRESS,
    viewValue: ProjectEventStatus.IN_PROGRESS,
  },
  {
    value: ProjectEventStatus.SCHEDULED,
    viewValue: ProjectEventStatus.SCHEDULED,
  },
  {
    value: ProjectEventStatus.COMPLETED,
    viewValue: ProjectEventStatus.COMPLETED,
  },
  {
    value: ProjectEventStatus.INVOICED,
    viewValue: ProjectEventStatus.INVOICED,
  },
  {
    value: ProjectEventStatus.PAID_IN_FULL,
    viewValue: ProjectEventStatus.PAID_IN_FULL,
  },
  {
    value: ProjectEventStatus.PARTIALLY_PAID,
    viewValue: ProjectEventStatus.PARTIALLY_PAID,
  },
  {
    value: ProjectEventStatus.CANCELLED,
    viewValue: ProjectEventStatus.CANCELLED,
  },
  { value: ProjectEventStatus.PAUSED, viewValue: ProjectEventStatus.PAUSED },
  {
    value: ProjectEventStatus.REQUESTED,
    viewValue: ProjectEventStatus.REQUESTED,
  },
  { value: ProjectEventStatus.VOID, viewValue: ProjectEventStatus.VOID },
];

// export enum ProjectEventStatus {
//   BID_REQUEST = 'Bid Request',
//   BID_INPROGRESS = 'Bid In Progress',
//   BID_SUBMITTED = 'Bid Submitted',
//   SCHEDULED = 'Scheduled',
//   IN_PROGRESS = 'In Progress',
//   COMPLETED = 'Completed',
//   INVOICED = 'Invoiced',
//   CANCELLED = 'Cancelled',
//   VOID = 'Void',
//   REQUESTED = 'Service(Job) Requested',
// }
// export const ProjectEventStatusTypes: PDropdownItem[] = [
//   { value: ProjectEventStatus.BID_REQUEST, viewValue: ProjectEventStatus.BID_REQUEST },
//   { value: ProjectEventStatus.BID_INPROGRESS, viewValue: ProjectEventStatus.BID_INPROGRESS },
//   { value: ProjectEventStatus.BID_SUBMITTED, viewValue: ProjectEventStatus.BID_SUBMITTED },
//   { value: ProjectEventStatus.IN_PROGRESS, viewValue: ProjectEventStatus.IN_PROGRESS },
//   { value: ProjectEventStatus.SCHEDULED, viewValue: ProjectEventStatus.SCHEDULED },
//   { value: ProjectEventStatus.COMPLETED, viewValue: ProjectEventStatus.COMPLETED },
//   { value: ProjectEventStatus.INVOICED, viewValue: ProjectEventStatus.INVOICED },
//   { value: ProjectEventStatus.CANCELLED, viewValue: ProjectEventStatus.CANCELLED },
//   { value: ProjectEventStatus.REQUESTED, viewValue: ProjectEventStatus.REQUESTED },
//   { value: ProjectEventStatus.VOID, viewValue: ProjectEventStatus.VOID }
// ];

export enum ServicePointStatus {
  SCHEDULED = 'Scheduled',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  VOID = 'Void',
}
export const TouchPointStatusTypes: PDropdownItem[] = [
  {
    value: ServicePointStatus.SCHEDULED,
    viewValue: ServicePointStatus.SCHEDULED,
  },
  {
    value: ServicePointStatus.COMPLETED,
    viewValue: ServicePointStatus.COMPLETED,
  },
  {
    value: ServicePointStatus.CANCELLED,
    viewValue: ServicePointStatus.CANCELLED,
  },
  { value: ServicePointStatus.VOID, viewValue: ServicePointStatus.VOID },
];

export enum ServiceEventReportType {
  WORK_ORDER = 'Work Order',
  QUOTE = 'Quotation',
  BID = 'Bid',
  INVOICE = 'Invoice',
  PROPOSAL = 'Proposal',
}

export const ServiceEventReportTypes: PDropdownItem[] = [
  {
    value: ServiceEventReportType.WORK_ORDER,
    viewValue: ServiceEventReportType.WORK_ORDER,
  },
  {
    value: ServiceEventReportType.QUOTE,
    viewValue: ServiceEventReportType.QUOTE,
  },
  { value: ServiceEventReportType.BID, viewValue: ServiceEventReportType.BID },
  {
    value: ServiceEventReportType.INVOICE,
    viewValue: ServiceEventReportType.INVOICE,
  },
  {
    value: ServiceEventReportType.PROPOSAL,
    viewValue: ServiceEventReportType.PROPOSAL,
  },
];
export enum ReportContentType {
  BOTH_2TABLES = 'Products And Services',
  BOTH_1TABLE = 'Products And Services (1 Table)',
  SERVICES_ONLY = 'Services Only',
  PRODUCTS_ONLY = 'Products Only',
}

export const ReportContentTypes: PDropdownItem[] = [
  {
    value: ReportContentType.BOTH_2TABLES,
    viewValue: ReportContentType.BOTH_2TABLES,
  },
  {
    value: ReportContentType.BOTH_1TABLE,
    viewValue: ReportContentType.BOTH_1TABLE,
  },
  {
    value: ReportContentType.SERVICES_ONLY,
    viewValue: ReportContentType.SERVICES_ONLY,
  },
  {
    value: ReportContentType.PRODUCTS_ONLY,
    viewValue: ReportContentType.PRODUCTS_ONLY,
  },
];

export enum PriceDisplayType {
  BOTH = 'Line Items with Prices',
  NONE = 'Line Item without Prices or Total',
  TOTAL_ONLY = 'Total Price Only',
}
export const PriceDisplayTypes: PDropdownItem[] = [
  { value: PriceDisplayType.BOTH, viewValue: PriceDisplayType.BOTH },
  {
    value: PriceDisplayType.TOTAL_ONLY,
    viewValue: PriceDisplayType.TOTAL_ONLY,
  },
  { value: PriceDisplayType.NONE, viewValue: PriceDisplayType.NONE },
];
export enum CustomerTerms {
  NET30 = 'Net 30',
  DUE_ON_RECEIPT = 'Due Upon Receipt',
}

export enum InstallationType {
  CIELING_FIXTURE = 'Cieling Fixture',
  HVAC = 'HVAC UV ServicePoint',
  CAN_LIGHT = 'UV LED Can Light',
  CORN_LAMP_FIXTURE = 'Corn Lamp Fixture',
  PORTABLE_MACHINE = 'Portable UV Disinfection Machine',
  OTHER = 'Other',
}

export const InstallationTypes: PDropdownItem[] = [
  { value: InstallationType.OTHER, viewValue: InstallationType.OTHER },
  { value: InstallationType.HVAC, viewValue: InstallationType.HVAC },
  {
    value: InstallationType.CIELING_FIXTURE,
    viewValue: InstallationType.CIELING_FIXTURE,
  },
  { value: InstallationType.CAN_LIGHT, viewValue: InstallationType.CAN_LIGHT },
  {
    value: InstallationType.CORN_LAMP_FIXTURE,
    viewValue: InstallationType.CORN_LAMP_FIXTURE,
  },
  {
    value: InstallationType.PORTABLE_MACHINE,
    viewValue: InstallationType.PORTABLE_MACHINE,
  },
];

export const UsStates: string[] = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const usStates = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];
