import { EditingStatus } from 'app/core/models/common-types';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { ServiceProduct, ServiceProductListItem, compareServiceProducts } from '../model/ServiceProduct';

export interface ServiceProductState extends EntityState<ServiceProduct> {
  editingStatus: EditingStatus;
  isLoaded: boolean;
  selectedServiceProduct: ServiceProductListItem;
  serviceProductListItems: ServiceProductListItem[];
}

export const adapter = createEntityAdapter<ServiceProduct>({
  sortComparer: compareServiceProducts,
});

export const INITIAL_SERVICE_PRODUCTS_STATE = adapter.getInitialState({
  editingStatus: EditingStatus.NOT_EDITING,
  isLoaded: false,
  selectedServiceProduct: undefined,
  serviceProductListItems: [],
});
