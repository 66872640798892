import { createAction, props } from '@ngrx/store';
import { FirebaseUser } from 'app/auth/auth-data.model';
import { ProjEmailRequest } from 'app/core/models/ProjEmailRequest';
import { User, UserListItem } from 'app/auth/model/User';
import { ProjSettings } from '@core/models/ProjSettings';
import { Guid, EditingStatus } from 'app/core/models/common-types';
import { Client } from 'app/features/admin/clients/model/Client';

export const SetUserEditingStatus = createAction(
  '[Auth] Set Editing Status',
  props<{ editingStatus: EditingStatus }>()
);

export const SetSelectedUser = createAction(
  '[Auth] Set Selected User',
  props<{ payload: {userListItem: UserListItem }}>()
);

export const SetFirebaseUserAuthenticated = createAction(
  '[Auth] Set Firebase User Authenticated',
  props<{ firebaseUser: FirebaseUser }>()
);

export const SetFirebaseUserUnAuthenticated = createAction(
  '[Auth] Set Firebase User UnAuthenticated'
);

export const LoadUserClientForAuth = createAction(
  '[Auth] Load User Client For Auth',
  props<{ user: User }>()
);
export const LoadUserClientForAuthSuccess = createAction(
  '[Auth] Load User Client For Auth Success',
  props<{ client: Client }>()
);
export const LoadUserClientForAuthFail = createAction(
  '[Auth] Load User Client For Auth Fail',
  props<{ error: any }>()
);
export const LoadUserForAuth = createAction(
  '[Auth] Load User For Auth',
  props<{ firebaseUser: FirebaseUser }>()
);
export const LoadUserForAuthSuccess = createAction(
  '[Auth] Load User For Auth Success',
  props<{  payload: {user: User; client: Client; }}>()
);
export const LoadUserForAuthFail = createAction(
  '[Auth] Load User For Auth Fail',
  props<{ error: any }>()
);
export const LoadUsers = createAction(
  '[Auth] Load Users'
);
export const LoadUsersSuccess = createAction(
  '[Auth] Load Users Success',
  props<{ payload: {userListItems: UserListItem[]} }>()
);
export const LoadUsersFail = createAction(
  '[Auth] Load Users Fail',
  props<{ error: any }>()
);
export const SetUserAuthenticated = createAction(
  '[Auth] Set User Authenticated',
  props<{ userListItem: UserListItem }>()
);
export const SetUserUnAuthenticated = createAction(
  '[Auth] Set User UnAuthenticated'
);
export const AddUser = createAction(
  '[Auth] Add User',
  props<{ userListItem: UserListItem }>()
);
export const AddUserSuccess = createAction(
  '[Auth] Add User Success',
  props<{ userListItem: UserListItem }>()
);
export const AddUserFail = createAction(
  '[Auth] Add User Fail',
  props<{ error: any }>()
);
export const UpdateUser = createAction(
  '[Auth] Update User',
  props<{ userListItem: UserListItem }>()
);
export const UpdateUserSuccess = createAction(
  '[Auth] Update User Success',
  props<{ userListItem: UserListItem }>()
);
export const UpdateUserFail = createAction(
  '[Auth] Update User Fail',
  props<{ error: any }>()
);
export const DeleteUser = createAction(
  '[Auth] Delete User',
  props<{ userId: Guid }>()
);
export const DeleteUserSuccess = createAction(
  '[Auth] Delete User Success',
  props<{ userId: Guid }>()
);
export const DeleteUserFail = createAction(
  '[Auth] Delete User Fail',
  props<{ error: any }>()
);
export const EmailNewClientContact = createAction(
  '[Auth] Emai lNew Client Contact',
  props<{
    projEmailRequest: ProjEmailRequest;
    newClient: Client;
  }>()
);

export const EmailNewClientContactSuccess = createAction(
  '[Auth] Email New Client Contact Success',
  props<{
    projEmailRequest: ProjEmailRequest;
    newClient: Client;
  }>()
);
export const EmailNewClientContactFail = createAction(
  '[Auth] Email New Client Contact Fail',
  props<{ error: any }>()
);

export const EmailCustomerServiceRequest = createAction(
  '[Auth] Email Customer Service Request',
  props<{ projEmailRequest: ProjEmailRequest; }>()
);

export const EmailCustomerServiceRequestSuccess = createAction(
  '[Auth] Email Customer Service Reques Success',
  props<{ projEmailRequest: ProjEmailRequest }>()
);

export const EmailCustomerServiceRequestFail = createAction(
  '[Auth] Email Customer Service Request Fail',
  props<{ error: any }>()
);

export const SendEmailToCustomer = createAction(
  '[Auth] Send Email To Customer',
  props<{ projEmailRequest: ProjEmailRequest; }>()
);

export const SendEmailToCustomerSuccess = createAction(
  '[Auth] Send Email To Customer Success'
);

export const SendEmailToCustomerFail = createAction(
  '[Auth] Send Email To Customer Fail',
  props<{ error: any }>()
);

export const SendRecaptchaToken = createAction(
  '[Auth] Send Recaptcha Token',
  props<{ token: string }>()
);

export const SendRecaptchaTokenSuccess = createAction(
  '[Auth] Send Recaptcha Token Success'
);
export const SendRecaptchaTokenFail = createAction(
  '[Auth] Send Recaptcha Token Fail',
  props<{ error: any }>()
);

export const LoadSettings = createAction(
  '[Auth] Load Projastic Settings'
);
export const LoadSettingsSuccess = createAction(
  '[Auth]Load Settings Success',
  props<{ projSettings: ProjSettings }>()
);
export const LoadSettingsFail = createAction(
  '[Auth] Load Settings Fail',
  props<{ error: any }>()
);

export const UpdatePjSettings = createAction(
  '[Auth] Update PjSettings',
  props<{ projSettings: ProjSettings }>()
);
export const UpdateSettingsSuccess = createAction(
  '[Auth] Update PjSettings Success',
  props<{ projSettings: ProjSettings }>()
);
export const UpdateSettingsFail = createAction(
  '[Auth] Update Settings Fail',
  props<{ error: any }>()
);
