import {Pipe, PipeTransform, NgModule} from '@angular/core';

@Pipe({
  name: 'odds',
})
export class GetOddsPipe implements PipeTransform {
  transform(value: number): string {
    if (value === undefined) {
      return '';
    }
    return value > 0 ? `+${value}` : `${value}`;
  }
}

@NgModule({
  declarations: [GetOddsPipe],
  exports: [GetOddsPipe],
})
export class GetOddsPipeModule {}
