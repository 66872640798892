/* eslint-disable @typescript-eslint/naming-convention */
/** *********************************************************************
  Copyright (C) 2023 OneWay Web Apps, LLC.
  This unpublished material is proprietary to OneWay Web Apps, LLC.
  All rights reserved.  The methods and techniques described herein
  are considered trade secrets and/or confidential.
  Reproduction or distribution, in whole or in part, is forbidden
  except by express written permission of OneWay Web Apps, LLC.
***********************************************************************/
// import browser from 'browser-detect';
import { detect } from 'detect-browser';
const browser = detect();

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
// import { actionSettingsChangeAnimationsPageDisabled } from './core/settings/settings.actions';
import { environment as env } from '../environments/environment';
import { UserListItem } from './auth/model/User';
// import { isNil } from './shared/utils';
// import { GamePlanStateService } from './featuresgame-plan/store/state-service';
import {
  APP_COPYRIGHT,
  APP_MENU_ABOUT,
  APP_MENU_DASHBOARD,
  APP_MENU_LOGOUT,
  APP_MENU_CONTACT_US,
  APP_MENU_LOGIN,
  APP_MENU_PRICING,
  APP_MENU_ABOUT_LINK,
  APP_MENU_PRICING_LINK,
  APP_MENU_CONTACT_US_LINK,
  APP_MENU_LOGIN_LINK,
  APP_MENU_DASHBOARD_LINK,
  APP_MENU_ADMIN,
  APP_MENU_HOME,
  APP_TITLE_LONG,
  APP_TITLE_SHORT,
  APP_MENU_HOME_LINK,
  APP_MENU_ADMIN_LINK,
  APP_MENU_FAQ,
  APP_MENU_FAQ_LINK,
  APP_MENU_HOW_TO,
  APP_MENU_HOW_TO_LINK,
  APP_MENU_PROJECTS,
  APP_MENU_PROJECTS_LINK,
  APP_MENU_CUSTOMERS_LINK,
  APP_MENU_CUSTOMERS,
} from './core/models/common-ux-types';
import { EditingStatus, UserType } from './core/models/common-types';
// import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { UiStateService } from './core/store/ui/state-service/ui.state-service';
import { AuthStateService } from './auth/store/auth.state-service';
import { LocalStorageService } from './core/local-storage/local-storage.service';
import { MenuItem, MessageService } from 'primeng/api';
// import { routeAnimations } from './core/animations/route.animations';
// import { LocalStorageService } from './core/local-storage/local-storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { Client, ClientDropdownItem, ClientListItem } from 'app/features/admin/clients/model/Client';
import { ClientStateService } from './features/admin/clients/store/client.state-service';
import { hasClientAdminPrivileges, isAdminUser } from './auth/model/types';
// import { GamePlanStateService } from './features/game-plan/store/state-service';
// temporary
export const noAuthNavButtons: MenuItem[] = [
  {
    routerLink: APP_MENU_HOME_LINK,
    label: APP_MENU_HOME,
    disabled: false,
    styleClass: 'menucus',
  },
  // { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT, disabled: false },
  // { routerLink: APP_MENU_FAQ_LINK, label: APP_MENU_FAQ, disabled: false },
  // { routerLink: APP_MENU_HOW_TO_LINK, label: APP_MENU_HOW_TO, disabled: false },
  {
    routerLink: APP_MENU_PRICING_LINK,
    label: APP_MENU_PRICING,
    disabled: false,
  },
  {
    routerLink: `auth/${APP_MENU_CONTACT_US_LINK}`,
    label: APP_MENU_CONTACT_US,
    disabled: false,
  },
  {
    routerLink: `auth/${APP_MENU_LOGIN_LINK}`,
    label: APP_MENU_LOGIN,
    disabled: false,
  },
];
export const noAuthNavButtonsSignUpDisabled: MenuItem[] = [
  { routerLink: APP_MENU_HOME_LINK, label: APP_MENU_HOME, disabled: false },
  { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT, disabled: false },
  //  { routerLink: APP_MENU_PRICING_LINK, label: APP_MENU_PRICING }
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
  //imports: [ RouterOutlet, RouterModule],
  // animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  isProd = env.production;
  isOld = false;
  envName = env.envName;
  version = env.versions.app;
  isAlmlycm = env.almlycm;

  year = new Date().getFullYear();
  // logo2 = new URL('assets/img/ecpLogo50H.jpg', import.meta.url);
  logo = '../assets/img/ecpLogo50H.jpg';
  // wagerEventsLink = APP_MENU_GAME_PLANS_LINK; // GAME_PLANS_CONTAINER_LINK;

  logoutMenuItems: MenuItem[] = [
    {
      label: 'AA',
      items: [
        {
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            this.onLogoutClick();
          },
        },
      ],
    },
  ];
  navMenuItems = [...noAuthNavButtons];

  APP_TITLE_SHORT = APP_TITLE_SHORT;
  APP_TITLE_LONG = APP_TITLE_LONG;
  APP_MENU_LOGOUT = APP_MENU_LOGOUT;
  APP_COPYRIGHT = APP_COPYRIGHT;

  isAdminActive$ = this.uiStateService.isAdminActive$;
  isAdminUser$ = this.authStateService.isAdminUser$;
  isUserAuthenticated$ = this.authStateService.isUserAuthenticated$;
  isClientUser$ = this.authStateService.isClientUser$;
  clientListItems$: Observable<Client[]> = this.clientStateService.clientListItems$;
  selectedClient$: Observable<Client> = this.clientStateService.selectedClient$;
  loggedInUser: UserListItem;
  userInitials: string;
  isSignUpActive = false;
  gamePlanEditStatus = EditingStatus.NOT_EDITING;
  isAdminActive = false;
  hideFooter = false;
  clientLogoPath: string;
  isEditingEntity: boolean;

  selectedClient: ClientListItem;
  clientListItems: ClientListItem[];
  clientDropdownItems: ClientDropdownItem[];
  selectedClientDropdownItem: ClientDropdownItem;

  private subscriptions = new Subscription();
  constructor(
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private uiStateService: UiStateService,
    private authStateService: AuthStateService,
    // private store: Store,
    private clientStateService: ClientStateService,
    private storageService: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.wagerEventReportService.setLogoForReport('/assets/img/ecpLogo50H.jpg');
    this.isIEorEdgeOrSafari();
    this.authService.loadPjSettings();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('MS event.url= ', event.url);
        if (event.url.indexOf(APP_MENU_ADMIN_LINK) !== -1) {
          // if (!this.isAdminActive) {
          this.isAdminActive = true;
          //  }
        } else {
          this.isAdminActive = false;
          // }
        }
        console.log('MS this.isAdminActive= ', this.isAdminActive);
        // if ((event.url.indexOf(APP_MENU_ADMIN_LINK) !== -1) || (event.url.indexOf('dashboard') !== -1)) {
        //   this.hideFooter = true;
        // } else {
        //   this.hideFooter = false;
        // }
      }
    });
    // this.signInAnonymously();

    // this.authService.autoAuthUser();

    this.authService.initAuthListener();
    this.storageService.testLocalStorage();
    // if (AppComponent.isIEorEdgeOrSafari()) {
    //   this.store.dispatch(
    //     actionSettingsChangeAnimationsPageDisabled({
    //       pageAnimationsDisabled: true
    //     })
    //   );
    // }
    this.subscriptions.add(
      this.authStateService.projSettings$.subscribe((projSettings) => {
        this.isSignUpActive = projSettings?.isSignUpActive ? projSettings.isSignUpActive : false;
        console.log('MS  this.isSignUpActive = ', this.isSignUpActive);
        if (!this.loggedInUser) {
          this.setNoAuthButtons();
        }
      })
    );
    this.subscriptions.add(
      this.clientStateService.clientListItems$.subscribe((clientListItems) => {
        this.clientListItems = clientListItems;
        console.log('MS this.clientListItems = ', this.clientListItems);
        console.log('MS APP this.navMenuItems = ', this.navMenuItems);
        this.changeDetector.detectChanges();
      })
    );
    this.subscriptions.add(
      this.clientStateService.clientDropdownItems$.subscribe((clientDropdownItems) => {
        this.clientDropdownItems = clientDropdownItems;
        console.log('MS this.clientDropdownItems = ', this.clientDropdownItems);
        this.changeDetector.detectChanges();
      })
    );

    this.subscriptions.add(
      this.clientStateService.selectedClient$.subscribe((selectedClient) => {
        this.selectedClient = selectedClient;
        if (this.selectedClient) {
          console.log('MS this.selectedClient.name = ', this.selectedClient.name);
          this.selectedClientDropdownItem = this.clientDropdownItems.find(
            (item) => item.name === this.selectedClient.name
          );

          // this.productStateService.loadProducts();
          // this.serviceListStateService.loadServices();
          // this.serviceEventStateService.loadServiceEvents();
          //   this.clientLogoPath = this.selectedClient.imagePath ? `../../assets/img/${this.selectedClient.imagePath}` : undefined;
          this.clientLogoPath = this.selectedClient.imageUrl ?? undefined;
          // if (this.clientLogoPath) {
          //   this.serviceEventReportService.setLogoForReport(this.clientLogoPath);
          // } else {
          //   this.serviceEventReportService.setLogoForReport('/assets/img/ecpLogo50H.jpg');
          // }
          this.changeDetector.detectChanges();
        }
      })
    );
    this.subscriptions.add(
      this.uiStateService.isEditingEntity$.subscribe((isEditingEntity) => {
        this.isEditingEntity = isEditingEntity;
        this.navMenuItems.forEach((item) => (item.disabled = isEditingEntity));
        this.changeDetector.detectChanges();
      })
    );

    this.subscriptions.add(
      this.authStateService.loggedInUser$.subscribe((loggedInUser) => {
        this.loggedInUser = loggedInUser;
        console.log('MS --------------------------- this.loggedInUser= ', this.loggedInUser);
        if (loggedInUser) {
          //   this.loggedInUser = loggedInUser;
          this.userInitials = '';
          this.setAuthNavButtons(loggedInUser.userType);
          if (
            loggedInUser.first &&
            loggedInUser.first.length > 0 &&
            loggedInUser.last &&
            loggedInUser.last.length > 0
          ) {
            this.userInitials = `${loggedInUser.first[0]}${loggedInUser.last[0]}`;
          }
        } else {
          this.setNoAuthButtons();
        }
      })
    );

    this.uiStateService.setIsMobileDevice(this.isMobileDevice());
  }

  onClientSelected(event) {
    // console.log('MS onClientSelectedevent= ', event.value);
    if (event.value) {
      const selectedClient: ClientListItem = this.clientListItems.find((item) => item.id === event.value.id);
      console.log('MS selectedClient= ', selectedClient);
      this.clientStateService.setSelectedClient(selectedClient);
    } else {
      this.clientStateService.setSelectedClient(null);
    }
  }

  isIEorEdgeOrSafari() {
    if (browser) {
      console.log(`MS isIEorEdgeOrSafari browser.name= ${browser.name} version= ${browser.version } os= ${browser.os }`);
      return ['ie', 'edge', 'safari'].includes(browser.name);
    }
    return false;
  }

  setAuthNavButtons(userType: UserType) {
    if (isAdminUser(userType)) {
      this.navMenuItems = [
        { routerLink: APP_MENU_HOME_LINK, label: APP_MENU_HOME, disabled: false },
        { routerLink: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD, disabled: false },
        { routerLink: APP_MENU_PRICING_LINK, label: APP_MENU_PRICING, disabled: false },
        { routerLink: APP_MENU_HOW_TO_LINK, label: APP_MENU_HOW_TO, disabled: false },
        { routerLink: APP_MENU_FAQ_LINK, label: APP_MENU_FAQ, disabled: false },
        { routerLink: APP_MENU_ADMIN_LINK, label: APP_MENU_ADMIN, disabled: false },
        { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT, disabled: false },
      ];
    }
    //   else if (hasPropMmgmtPrivileges(userType)) {
    //     this.navigation = [
    //       { link: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT },
    //       { link: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD },
    //       { link: APP_MENU_PROPERTIES_LINK, label: APP_MENU_PROPERTIES },
    //       { link: APP_MENU_WORK_ORDERS_LINK, label: APP_MENU_WORK_ORDERS },
    //      //  { link: APP_MENU_PROJECTS_LINK, label: APP_MENU_PROJECTS },
    //       { link: APP_MENU_ADMIN_LINK, label: APP_MENU_ADMIN }
    //     ];
    //   }
  else if (hasClientAdminPrivileges(userType)) {
    this.navMenuItems = [
      { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT },
      { routerLink: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD },
      { routerLink: APP_MENU_CUSTOMERS_LINK, label: APP_MENU_CUSTOMERS },
      // { link: APP_MENU_PROPERTIES_LINK, label: APP_MENU_PROPERTIES },
      { routerLink: APP_MENU_PROJECTS_LINK, label: APP_MENU_PROJECTS },
      { routerLink: APP_MENU_ADMIN_LINK, label: APP_MENU_ADMIN }
    ];
  } else {
      this.navMenuItems = [
        { routerLink: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT },
        { routerLink: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD },
      ];
    }
  }
  setNoAuthButtons() {
    console.log('MS setNoAuthButtons 1');
    this.userInitials = '';
    if (this.isSignUpActive) {
      this.navMenuItems = [...noAuthNavButtons];
    } else {
      this.navMenuItems = [...noAuthNavButtonsSignUpDisabled];
    }
    console.log('MS setNoAuthButtons this.navMenuItems = ', this.navMenuItems);
    this.changeDetector.detectChanges();
  }

  isMobileDevice() {
    return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
  }

  onSelectMenuItem(menuItem: MenuItem) {
    console.log('MS menuItem= ', menuItem);
    this.router.navigate([menuItem.routerLink]);
  }

  // getIsDisabled(linkObj: MenuItem) {
  //   // if ((linkObj.link === APP_MENU_GAME_PLANS_LINK && !this.selectedClient) || this.wagerEventEditingStatus !== EditingStatus.NOT_EDITING ||
  //   // this.isEditingEntity) {
  //   //   return true;
  //   // }
  //   return false;
  // }

  onSettingsClick() {
    // if (isAdminUser(this.loggedInUser.userType)) {
    //   this.router.navigate([APP_MENU_SETTINGS_LINK]);
    // } else if (this.isAdminActive) {
    // this.clientStateService.setClientEditingStatus(EditingStatus.EDITING);
    // this.clientStateService.setSelectedClient(this.selectedClient);
    // this.router.navigate(['/admin/edit-client']);
    // }
  }

  // onClientSelected(event: MatSelectChange) {
  //   const selectedClient: Client = event.value;
  //   this.clientStateService.setSelectedClient(selectedClient);
  // }

  // onLoginClick() {
  //   this.authStateService.setUserAuthenticated(adminUser);
  // }

  onLogoutClick() {
    this.authService.logout();
    // this.authService.signOut();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
