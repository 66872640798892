import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FirebaseUser } from 'app/auth/auth-data.model';
import { AppState } from 'app/core/core.state';
import { UserListItem } from 'app/auth/model/User';
import { ProjSettings } from '@core/models/ProjSettings';
import {
  userListItemsSelector,
  loggedInUserSelector,
  isUserAuthenticatedSelector,
  isAdminUserSelector,
  isFirebaseUserAuthenticatedSelector,
  fireBaseUserSelector,
  rcSettingsSelector,
  isClientUserSelector,
  isSuperUserSelector,
  userEditingStatusSelector,
  isDadoUserSelector,
  selectedUserListItemSelector,
  loggedInUserClientSelector,
  isRecaptchaTokenAuthenticatedSelector,
} from './auth.selectors';
import { Guid, EditingStatus } from 'app/core/models/common-types';
import { Client } from 'app/features/admin/clients/model/Client';
import { AuthActions } from './action-types';
import { ProjEmailRequest } from '@core/models/ProjEmailRequest';

@Injectable()
export class AuthStateService {
  isFirebaseUserAuthenticated$ = this.store.select(isFirebaseUserAuthenticatedSelector);
  fireBaseUser$ = this.store.select(fireBaseUserSelector);
  userListItems$ = this.store.select(userListItemsSelector);
  loggedInUser$ = this.store.select(loggedInUserSelector);
  loggedInUserClient$ = this.store.select(loggedInUserClientSelector);
  isDadoUser$ = this.store.select(isDadoUserSelector);
  isUserAuthenticated$ = this.store.select(isUserAuthenticatedSelector);
  isRecaptchaTokenAuthenticated$ = this.store.select(isRecaptchaTokenAuthenticatedSelector);
  projSettings$ = this.store.select(rcSettingsSelector);
  isAdminUser$ = this.store.select(isAdminUserSelector);
  isSuperUser$ = this.store.select(isSuperUserSelector);
  isClientUser$ = this.store.select(isClientUserSelector);
  userEditingStatus$ = this.store.select(userEditingStatusSelector);
  selectedUserListItem$ = this.store.select(selectedUserListItemSelector);

  constructor(private store: Store<AppState>) { }

  setFirebaseUserAuthenticated(firebaseUser: FirebaseUser) {
    this.store.dispatch(AuthActions.SetFirebaseUserAuthenticated({ firebaseUser }));
    this.store.dispatch(AuthActions.LoadUserForAuth({ firebaseUser }));
  }
  loadUsers() {
    this.store.dispatch(AuthActions.LoadUsers());
  }
  reloadUsers() {
    this.store.dispatch(AuthActions.LoadUsers());
  }

  setFirebaseUserUnAuthenticated() {
    this.store.dispatch(AuthActions.SetFirebaseUserUnAuthenticated());
  }

  setUserAuthenticated(userListItem: UserListItem) {
    this.store.dispatch(AuthActions.SetUserAuthenticated({ userListItem }));
  }

  setUserUnAuthenticated() {
    this.store.dispatch(AuthActions.SetUserUnAuthenticated());
  }

  setSelectedUserListItem(userListItem: UserListItem) {
    this.store.dispatch(AuthActions.SetSelectedUser({ payload: { userListItem } }));
  }

  setUserEditingStatus(editingStatus: EditingStatus) {
    this.store.dispatch(AuthActions.SetUserEditingStatus({ editingStatus }));
  }

  addUser(userListItem: UserListItem) {
    this.store.dispatch(AuthActions.AddUser({ userListItem }));
  }
  deleteUser(userId: Guid) {
    this.store.dispatch(AuthActions.DeleteUser({ userId }));
  }

  updateUser(userListItem: UserListItem) {
    this.store.dispatch(AuthActions.UpdateUser({ userListItem }));
  }

  loadSettings() {
    this.store.dispatch(AuthActions.LoadSettings());
  }

  loadSettingsSuccess(projSettings: ProjSettings) {
    this.store.dispatch(AuthActions.LoadSettingsSuccess({ projSettings }));
  }

  updatePjSettings(projSettings: ProjSettings) {
    this.store.dispatch(AuthActions.UpdatePjSettings({ projSettings }));
  }

  emailCustomerContactInfo(projEmailRequest: ProjEmailRequest, newClient: Client) {
    this.store.dispatch(AuthActions.EmailNewClientContact({ projEmailRequest, newClient }));
  }

  // emailCustomerContactInfo(projEmailRequest: ProjEmailRequest, newClient: Client) {
  //   this.store.dispatch(AuthActions.EmailNewClientContact({ projEmailRequest, newClient }));
  // }

  emailCustomerServiceRequest(projEmailRequest: ProjEmailRequest) {
    this.store.dispatch(AuthActions.EmailCustomerServiceRequest({ projEmailRequest }));
  }

  sendEmailToCustomer(projEmailRequest: ProjEmailRequest) {
    this.store.dispatch(AuthActions.SendEmailToCustomer({ projEmailRequest }));
  }

  // sendRecaptchaTokenForVerification(token: string) {
  //   this.store.dispatch(AuthActions.SendRecaptchaToken(token));
  // }
}
