import {environment} from 'environments/environment';

const BACKEND_URL = environment.apiUrl;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AppServiceApiConfig = {
  // users: BACKEND_URL + 'rcusers',
  // usersSignUp:  BACKEND_URL + 'rcusers/signup',
  // usersLogin:  BACKEND_URL + 'rcusers/login',
  // customers:  BACKEND_URL + 'customers',
  // products:  BACKEND_URL + 'products',
  //  servicePointPackages: BACKEND_URL + 'servicepointpackages',
  // touchPointProjectListItems: BACKEND_URL + 'servicepointlocations',
  // projects: BACKEND_URL + 'customerlocations',
  // gamePlanListItems: BACKEND_URL + 'serviceevents',
  email: BACKEND_URL + 'sendMail',
  // recaptcha: BACKEND_URL + 'recaptcha',
};


// setAuthNavButtons(sgUserType: SgUserType) {
//   if (isServiceGambitUser(sgUserType)) {
//     this.navigation = [
//       { link: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT },
//       { link: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD },
//       { link: APP_MENU_PRICING_LINK, label: APP_MENU_PRICING },
//       { link: APP_MENU_CUSTOMERS_LINK, label: APP_MENU_CUSTOMERS },
//       { link: APP_MENU_PROPERTIES_LINK, label: APP_MENU_PROPERTIES },
//       { link: APP_MENU_WORK_ORDERS_LINK, label: APP_MENU_WORK_ORDERS },
//       { link: APP_MENU_PROJECTS_LINK, label: APP_MENU_PROJECTS },
//       { link: APP_MENU_ADMIN_LINK, label: APP_MENU_ADMIN },
//       { link: APP_MENU_SETTINGS_LINK, label: APP_MENU_SETTINGS }
//     ];
//   }
//   else if (hasPropMmgmtPrivileges(sgUserType)) {
//     this.navigation = [
//       { link: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT },
//       { link: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD },
//       { link: APP_MENU_PROPERTIES_LINK, label: APP_MENU_PROPERTIES },
//       { link: APP_MENU_WORK_ORDERS_LINK, label: APP_MENU_WORK_ORDERS },
//      //  { link: APP_MENU_PROJECTS_LINK, label: APP_MENU_PROJECTS },
//       { link: APP_MENU_ADMIN_LINK, label: APP_MENU_ADMIN }
//     ];
//   }
//   else if (hasAdminPrivileges(sgUserType)) {
//     this.navigation = [
//       { link: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT },
//       { link: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD },
//       { link: APP_MENU_CUSTOMERS_LINK, label: APP_MENU_CUSTOMERS },
//       // { link: APP_MENU_PROPERTIES_LINK, label: APP_MENU_PROPERTIES },
//       { link: APP_MENU_PROJECTS_LINK, label: APP_MENU_PROJECTS },
//       { link: APP_MENU_ADMIN_LINK, label: APP_MENU_ADMIN }
//     ];
//   } else {
//     this.navigation = [
//       { link: APP_MENU_ABOUT_LINK, label: APP_MENU_ABOUT },
//       { link: APP_MENU_DASHBOARD_LINK, label: APP_MENU_DASHBOARD },
//       { link: APP_MENU_PROJECTS_LINK, label: APP_MENU_PROJECTS },
//     ];
//   }
//   this.navigationSideMenu = [
//     ...this.navigation
//     //  { link: APP_MENU_SETTINGS_LINK, label: APP_MENU_SETTINGS }
//   ];
// }
