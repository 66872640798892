import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from '../core/services/ui/ui.service';
import { UiStateService } from '../core/store/ui/state-service';
import { AuthStateService } from './store/auth.state-service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthData, FirebaseUser } from './auth-data.model';
import { seedUserList, User } from 'app/auth/model/User';
import { FirestoreCollectionType, Guid } from 'app/core/models/common-types';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { ProjSettings } from '@core/models/ProjSettings';
import { Client } from 'app/features/admin/clients/model/Client';
// import { GamePlanStateService } from 'app/features/game-plan/store/state-service';
@Injectable()
export class AuthService {
  addedUserEmail = '';
  origAuthData: AuthData;
  currentUser: FirebaseUser;
  authWorkerApp: firebase.app.App;
  authWorkerAuth: firebase.auth.Auth;
  isAuthWorkerAppInitialized = false;

  //  private fbSubs: Subscription[] = [];
  constructor(
    private db: AngularFirestore,
    private router: Router,
    private afAuth: AngularFireAuth,
    private uiService: UiService,
    private authStateService: AuthStateService,
    private uiStateService: UiStateService
    // private gamePlanStateService: GamePlanStateService
  ) { }

  // loadUsers(): Observable<DocumentChangeAction<User>[]> {
  //   this.uiStateService.startLoading();
  //   const clRef = this.db.collection<User>(FirestoreCollectionType.USERS);
  //   console.log('MS loadUsers clRef= ', clRef);
  //   const clRefSnapshot =  clRef.snapshotChanges();
  //   console.log('MS loadUsers clRefSnapshot= ', clRefSnapshot);
  //   return clRefSnapshot;
  // }

  //   loadUsers2() {
  //     this.uiStateService.startLoading();
  //      const usersCollection = this.db.collection<User>(FirestoreCollectionType.USERS, ref => ref.orderBy('last', 'asc'));
  //      const retVal = usersCollection.snapshotChanges();
  //     // console.log('MS loadUsers after snapshotChanges');
  //      return retVal;
  //    // or .valueChanges() does not have the id
  //  }

  getAllUsers() {
    this.uiStateService.startLoading();
    const docRef = this.db.collection<User>(FirestoreCollectionType.USERS, (ref) => ref.orderBy('last', 'asc'));
    console.log('MS getAllUsers docRef= ', docRef);
    return docRef.get();
  }

  loadUserForAuthentication(userEmail: string) {
    this.uiStateService.startLoading();
    const docRef = this.db.collection<User>(FirestoreCollectionType.USERS, (x) => x.where('email', '==', userEmail));
    return docRef.get();
  }

  loadUserClientForAuthentication(clientId: string) {
    this.uiStateService.startLoading();
    return (
      this.db
        .doc(`${FirestoreCollectionType.CLIENTS}/${clientId}`)
        // you can use either:
        .valueChanges()
    );
    // or .snapshotChanges()
  }

  loadUserClientForAuthenticationOld(clientId: string) {
    this.uiStateService.startLoading();
    console.log('MS loadUserClientForAuthentication clientId', clientId);
    const docRef = this.db.collection<Client>(FirestoreCollectionType.CLIENTS, (x) => x.where('id', '==', clientId));
    return docRef.get();
  }

  seedUsers() {
    seedUserList.map((user) => {
      console.log('MS seedUsers user', user);
      this.authStateService.addUser(user);
    });
  }

  // loadUsers2(sortField: string, directionStr: firebase.firestore.OrderByDirection, isReload: boolean) {
  //   this.uiStateService.startLoading();
  //  //  this.fbSubs.push(
  //     const subscription = this.db
  //       .collection<User>(FirestoreCollectionType.USERS, x => x.orderBy(sortField, directionStr))
  //       //  .collection<User>(FirestoreCollectionType.USERS)
  //       .snapshotChanges().pipe(
  //         map(docArray =>
  //           docArray.map(a => {
  //             const data = a.payload.doc.data() as User;
  //             const id = a.payload.doc['id'];
  //             return {
  //               ...data,
  //               id
  //             };
  //           })
  //         ))
  //       .subscribe(
  //         (users: User[]) => {
  //           this.uiStateService.stopLoading();
  //          // console.log('MS loadUsers users = ', users);
  //           this.authStateService.loadUsersSuccess(users, isReload);
  //           subscription.unsubscribe();
  //         },
  //         error => {
  //           //  console.log('MS users error = ', error);
  //           this.uiStateService.stopLoading();
  //           this.uiService.showSnackbar(
  //             'Fetching Users failed, please try again later',
  //             null,
  //             3000
  //           );
  //           subscription.unsubscribe();
  //         }
  //       )
  //  // );
  // }

  addUser(user: User) {
    return from(this.db.collection(FirestoreCollectionType.USERS).add(this.toUserPayload(user)));
  }

  deleteUser(userId: Guid) {
    return from(this.db.collection(FirestoreCollectionType.USERS).doc(userId).delete());
  }

  // deleteMultipleUsers(documentIdList: string[]) {
  //   const batch = this.db.firestore.batch()
  //   documentIdList.forEach(id => {
  //     const transDoc = this.db.firestore.doc(`${FirestoreCollectionType.USERS}/${id}`);
  //     batch.delete(transDoc);
  //   });
  //   return from(batch.commit());
  // }

  updateUser(user: User) {
    return from(this.db.collection(FirestoreCollectionType.USERS).doc(user.id).set(this.toUserPayload(user)));
  }

  updatePjSettings(rCSettings: ProjSettings) {
    return from(
      this.db.collection(FirestoreCollectionType.SETTINGS).doc(rCSettings.id).set(this.toPjSettingsPayload(rCSettings))
    );
  }

  initAuthListener() {
    this.afAuth.authState.subscribe((fbuser) => {
      console.log('MS initAuthListener this.addedUserEmail= ', this.addedUserEmail);
      console.log('MS initAuthListener firebaseUser = ', fbuser);
      this.currentUser = fbuser;
      if (fbuser) {
        console.log('MS 2 useruid = ', fbuser.uid);
        console.log('MS 3 user = ', fbuser.providerData);
        const firebaseUser: FirebaseUser = {
          ...fbuser.providerData[0],
        };
        console.log('MS firebaseUser = ', firebaseUser);
        // seed here
        // if (fbuser?.email === 'su@agsportsbet.com') {
        //   console.log('MS seedUsers');
        //   this.seedUsers();
        // }

        this.authStateService.setFirebaseUserAuthenticated(firebaseUser);
        //  this.router.navigate(['/game-plan-view']);
      } else {
        console.log('MS NOT firebaseUser = ');
        //  this.trainingService.cancelSubscriptions();
        this.uiStateService.stopLoading();
        //  this.cancelSubscriptions();
        // this.gamePlanService.cancelSubscriptions();
        this.authStateService.setFirebaseUserUnAuthenticated();
        this.authStateService.setUserUnAuthenticated();
        // this.gamePlanStateService.resetOnUserLogout();
        // this.customerStateService.resetOnUserLogout();
        // this.clientStateService.resetOnUserLogout();
        // this.store.dispatch(new Auth.SetFirebaseUserUnAuthenticated());

        // this.navigateToAbout();
        this.router.navigate(['/home']);
      }
      // // THIS IS FOR SEED THE USERS, PACKAGES and CUSTOMERS TABLEs
      // if (fbuser?.email === 'fred@agsportsbet.com') {
      //   console.log('MS seedUsers');
      //   this.seedAll();
      // }
    });
  }
  // seedAll() {
  //   console.log('MS seedUsers');
  //  // this.gamePlanService.seedUsers();
  //   //  console.log('MS seedCustomers');
  //   // this.gamePlanService.seedCustomers();
  //   //   console.log('MS seedServicePointPackages');
  //   // this.gamePlanService.seedServicePointPackages();
  // }

  registerUser(authData: AuthData, user: User) {
    if (!this.isAuthWorkerAppInitialized) {
      this.authWorkerApp = firebase.initializeApp(firebase.app().options, 'auth-worker');
      this.authWorkerAuth = firebase.auth(this.authWorkerApp);
      this.authWorkerAuth.setPersistence(firebase.auth.Auth.Persistence.NONE); // disables caching of account credentials
      this.isAuthWorkerAppInitialized = true;
    }

    // console.log('MS 1 registerUser user= ', user);
    // console.log('MS 1 registerUser authData= ', authData);
    this.uiStateService.startLoading();
    // this.store.dispatch(new UI.StartLoading());
    this.addedUserEmail = authData.email;
    //   console.log('MS 1 this.addedUserEmail= ', this.addedUserEmail);
    this.authWorkerAuth
      .createUserWithEmailAndPassword(authData.email, authData.password)
      .then((userCredential) => {
        // this.uiService.loadingStateChanged.next(false);
        // this.store.dispatch(new UI.StopLoading());
        const newUser = userCredential.user;
        //  console.log('MS 2 registerUser user = ', user);
        newUser.sendEmailVerification();

        this.authStateService.updateUser({
          ...user,
          isFirebaseUser: true,
        });

        // this.updateUser({
        //   ...user,
        //   isFirebaseUser: true
        // })
        // this.afAuth.updateCurrentUser (this.currentUser )
        // this.signOut();
        //  console.log('MS 2 registerUser this.origAuthData = ', this.origAuthData);
        // this.login({
        //   email: loggedInUser.email
        //   password: string;
        //  /// recaptchaToken : string;
        // });
        // this.sendEmailVerification();
        this.uiStateService.stopLoading();
      })
      .catch((error) => {
        // this.uiService.loadingStateChanged.next(false);
        //  console.log('MS 3 registerUser firebaseUser = ', error);
        alert(error.message);
        this.uiStateService.stopLoading();
        this.uiService.showSnackbar(error.message, null, 3000);
      });
  }
  async sendEmailVerification() {
    console.log('MS sendEmailVerification this.afAuth.currentUser = ', this.afAuth.currentUser);
    (await this.afAuth.currentUser).sendEmailVerification().then(function () {
      // Email Verification sent!
      console.log('MS sendEmailVerification Email Verification sent!');
      alert('Email Verification Sent! ');
    });
  }

  signOut() {
    this.afAuth
      .signOut()
      .then(function () {
        console.log('MS signOut= ');
        alert('User signed out!');
      })
      .catch(function (error) {
        alert(error.message);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // if (errorCode === 'auth/invalid-email') {
        //   alert(errorMessage);
        // } else if (errorCode === 'auth/user-not-found') {
        //   alert(errorMessage);
        // }
        console.log(error);
      });
  }

  sendPasswordReset(email: string) {
    //  var email = document.getElementById('email').value;
    this.afAuth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Password Reset Email Sent!
        alert(`Password Reset Email Sent to ${email} !`);
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/invalid-email') {
          alert(errorMessage);
        } else if (errorCode === 'auth/user-not-found') {
          alert(errorMessage);
        }
        console.log(error);
      });
  }

  // addFirebaseUser(user: User) {
  //   console.log('MS 1 addFirebaseUser user= ', user);
  // this.afAuth.createUserWithEmailAndPassword(user.email, user.password)
  // .then((userCredential) => {
  //   // Signed in
  //   const user = userCredential.user;
  //   console.log('MS 2 addFirebaseUser user = ', user);
  //   // ...
  // })
  // .catch((error) => {
  //   console.log('MS 3 addFirebaseUser firebaseUser = ', error);
  //   // var errorCode = error.code;
  //   // var errorMessage = error.message;
  //   // // ..
  // });

  // }

  login(authData: AuthData) {
    // this.uiService.loadingStateChanged.next(true);
    // console.log('MS login origAuthData = ', this.origAuthData);
    console.log('MS login authData = ', authData);
    this.origAuthData = { ...authData };
    this.uiStateService.startLoading();
    this.afAuth
      .signInWithEmailAndPassword(authData.email, authData.password)
      .then(() => {
        // this.uiService.loadingStateChanged.next(false);
        this.uiStateService.stopLoading();
      })
      .catch((error) => {
        console.log('MS error = ', error);
        // this.uiService.loadingStateChanged.next(false);
        this.uiStateService.stopLoading();
        this.uiService.showSnackbar(error.message, null, 3000);
      });
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      //  console.log('MS logout');
      this.router.navigate(['/home']);
    });
  }

  loadPjSettings() {
    this.uiStateService.startLoading();
    // this one we will only call 1x
    // this.fbSubs.push(
    const subscription = this.db
      .collection<ProjSettings>(FirestoreCollectionType.SETTINGS)
      .snapshotChanges()
      .pipe(
        map((docArray) =>
          docArray.map((a) => {
            const data = a.payload.doc.data() as ProjSettings;
            const id = a.payload.doc['id'];
            return {
              ...data,
              id,
            };
          })
        )
      )
      .subscribe({
        next: (settings: ProjSettings[]) => {
          this.uiStateService.stopLoading();
          // for now there is only 1
          this.authStateService.loadSettingsSuccess(settings[0]);
          subscription.unsubscribe();
        },
        error: (error) => {
          console.log('MS loadPjSettings error = ', error);
          this.uiStateService.stopLoading();
          this.uiService.showSnackbar('Fetching RC Settings failed', null, 3000);
          subscription.unsubscribe();
        },
      });
    // );
  }

  // cancelSubscriptions() {
  //   //  console.log('MS AUTH cancelSubscriptions  ');
  //   this.fbSubs.forEach((sub) => sub.unsubscribe());
  // }
  // removes the id for the update
  toUserPayload(user: User): User {
    // const newUser: User =
    return {
      clientId: user.clientId,
      isActive: user.isActive,
      email: user.email,
      //  facilities: user.facilities ? user.facilities : null,
      first: user.first,
      isFirebaseUser: user.isFirebaseUser,
      last: user.last,
      // password: user.password,
      phone: user.phone,
      userType: user.userType,
    };
  }

  toPjSettingsPayload(rCSettings: ProjSettings): ProjSettings {
    return {
      isSignUpActive: rCSettings.isSignUpActive,
    };
  }
  // logout3() {
  //  this.afAuth.signOut().then(() => {
  //     this.router.navigate(['sign-in']);
  //  )};

  //  // this.afAuth.signOut();
  // }
}
