import { createSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { EditingStatus, UserType } from '@core/models/common-types';
import { AuthState } from './auth.state.interface';

export const authStateSelector = (state: AppState): AuthState => state.authState;

export const isFirebaseUserAuthenticatedSelector = createSelector(authStateSelector, (state: AuthState) => {
  const retval = state.isFirebaseUserAuthenticated ? state.isFirebaseUserAuthenticated : false;
  return retval;
});
export const isUserAuthenticatedSelector = createSelector(authStateSelector, (state: AuthState) =>
  state.isUserAuthenticated ? state.isUserAuthenticated : false
);

export const fireBaseUserSelector = createSelector(authStateSelector, (state: AuthState) =>
  state.firebaseUser ? state.firebaseUser : undefined
);

export const rcSettingsSelector = createSelector(authStateSelector, (state: AuthState) =>
  state.projSettings ? state.projSettings : undefined
);

export const isRecaptchaTokenAuthenticatedSelector = createSelector(authStateSelector, (state: AuthState) =>
  state.isRecaptchaTokenAuthenticated ? state.isRecaptchaTokenAuthenticated : false
);

export const selectedUserListItemSelector = createSelector(
  authStateSelector,
  (state: AuthState) => state.seletectedUserListItem ?? undefined
);

// export const usersSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.users ? state.users : []
// );
export const userListItemsSelector = createSelector(authStateSelector, (state: AuthState) =>
  state.userListItems ? state.userListItems : []
);

export const userEditingStatusSelector = createSelector(authStateSelector, (state: AuthState) =>
  state.userEditingStatus ? state.userEditingStatus : EditingStatus.NOT_EDITING
);

// export const allUsersSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.users ? state.users : []
// );

// export const techniciansSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => {
//    // console.log('MS techniciansSelector 1 state.users= ', state?.users);
//     const techs = state.users ? state.users.filter(user => user.userType !== UserType.CLIENT_CUSTOMER) : [];
//  //  console.log('MS techniciansSelector 1 techs= ', techs);
//     return state? techs : undefined;
//   }

// );
//   {
//     // const loggedInUser = state.loggedInUser ? state.loggedInUser : undefined;
//    // console.log('MS techniciansSelector 1 retVal= ', loggedInUser);
//     // if (!isNil(loggedInUser)) {
//     //   return state.users ? state.users.filter(user => user.userType === UserType.TECHNICIAN && user.isQaUser) : [];
//     // }
//     state.users ? state.users.filter(user => user.userType === UserType.TECHNICIAN) : [];
//   }
//  );
// export const techniciansSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.loggedInUser ? isTechnicianUser(state.loggedInUser.userType) ? true : false : false
// );

export const loggedInUserSelector = createSelector(authStateSelector, (state: AuthState) =>
  state.loggedInUser ? state.loggedInUser : undefined
);

export const loggedInUserClientSelector = createSelector(authStateSelector, (state: AuthState) =>
  state.loggedInUserClient ? state.loggedInUserClient : undefined
);

// export const infoAdminUserSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.users.find(user => user.userType === UserType.ADMIN && user.email === 'info@servicegambit.com')
// );

export const isClientUserSelector = createSelector(
  authStateSelector,
  (state: AuthState) => state.loggedInUser && state.loggedInUser.userType === UserType.CLIENT_PM
);

// export const isClientCustomerUserSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.loggedInUser ? isClientCustomerUser(state.loggedInUser.userType) ? true : false : false
// );

export const isAdminUserSelector = createSelector(
  authStateSelector,
  (state: AuthState) =>
    state.loggedInUser &&
    (state.loggedInUser.userType === UserType.ADMIN || state.loggedInUser.userType === UserType.SUPER_USER)
);
export const isDadoUserSelector = createSelector(
  authStateSelector,
  (state: AuthState) =>
    state.loggedInUser &&
    state.loggedInUser.userType === UserType.ADMIN &&
    state.loggedInUser.email === 'dgflorida5@gmail.com'
);

export const isSuperUserSelector = createSelector(
  authStateSelector,
  (state: AuthState) => state.loggedInUser && state.loggedInUser.userType === UserType.SUPER_USER
);
// export const isSuperUserSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.loggedInUser ? (state.loggedInUser.userType === UserType.SUPERUSER) ? true : false : false
// );
// export const isAdminUserSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.loggedInUser ? (state.loggedInUser.userType === UserType.SUPER_USER || state.loggedInUser.userType === UserType.ADMIN) ? true : false : false
// );

// export const isMasterUserSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.loggedInUser ? (state.loggedInUser.userType === UserType.ADMIN) ? true : false : false
// );
// export const getPjUserTypeSelector = createSelector(
//   authStateSelector,
//   (state: AuthState) => state.loggedInUser ? state.loggedInUser.userType : undefined
// );
