// import { AuthService } from 'app/auth/service/auth.service';
import { AuthEffects } from 'app/auth/store/auth.effects';
import { AuthStateService } from 'app/auth/store/auth.state-service';
// import { GoogleAnalyticsEffects } from './google-analytics/google-analytics.effects';
import { ClientService } from '../features/admin/clients/clients.service';
// import { ConfirmationService } from './services/confirmation/confirmation.service';
import { EmailService } from './services/email/email.service';
import { ExportFileService } from './services/export-file/export-file.service';
import { FileUploadService } from './services/file-upload/file-upload.service';
import { UiService } from './services/ui/ui.service';
import { SettingsEffects } from './settings/settings.effects';
import { UiStateService } from './store/ui/state-service';
import { ClonerService } from './services/cloner-service/cloner-service';
import { AuthService } from 'app/auth/auth.service';
import { ClientListEffects } from '../features/admin/clients/store/client.effects';
import { ProductEffects } from '../features/admin/products/store/products.effects';
import { ProductService } from 'app/features/admin/products/products.service';
import { ProductStateService } from 'app/features/admin/products/store/products.state-service';
import { ServiceProductService } from 'app/features/admin/service-products/service-products.service';
import { ServiceProductEffects } from 'app/features/admin/service-products/store/service-products.effects';
import { ServiceProductStateService } from 'app/features/admin/service-products/store/service-products.state-service';
import { ClientStateService } from 'app/features/admin/clients/store/client.state-service';

export const WEB_SERVICES = [
  AuthService,
  UiService,
  // ConfirmationService,
  EmailService,
  ProductService,
  // ReportOptionsService,
  // ReportDocumentService,
  ExportFileService,
  // ReportService,
  FileUploadService,
  ClientService,
  ClonerService,
  ServiceProductService,
  // PropertyService
];

export const EFFECTS = [
  AuthEffects,
  // GoogleAnalyticsEffects,
  ServiceProductEffects,
  ClientListEffects,
  // SgAdminEffects,
  // ReportOptionsEffects,
  // ReportDocumentEffects,
  ProductEffects,
  SettingsEffects,
  // WagerSourceEffects
];

export const STATE_SERVICES = [
  UiStateService,
  AuthStateService,
  ClientStateService,
  ServiceProductStateService,
  ProductStateService,
];
