import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { EditingStatus, Guid } from 'app/core/models/common-types';
import { ClientListItem, Client } from '../model/Client';

export const SetClientEditingStatus = createAction(
  '[Clients] Set Editing Status',
  props<{ editingStatus: EditingStatus }>()
);

export const SetSelectedClient = createAction(
  '[Clients] Set Selected Client',
  props<{ clientListItem: ClientListItem }>()
);

export const ClientsLoad = createAction('[Clients] Load Clients');
export const ClientsLoadFail = createAction('[Clients] Load Clients Fail',
  props<{ error: any }>()
);
export const ClientsLoadSuccess = createAction(
  '[Clients] Load Clients Success',
  props<{ clients: Client[] }>()
);

export const ClientsLoadClientForUser = createAction('[Clients] Load Client For User',
  props<{ clientId: Guid }>()
);
export const ClientsLoadClientForUserFail = createAction('[Clients] Load Client For User Fail', props<{ error: any }>());
export const ClientsLoadClientForUserSuccess = createAction(
  '[Clients] Load Client For User Success',
  props<{ clientListItem: ClientListItem }>()
);

export const ClientsAdd = createAction('[Clients] Add New Client',
  props<{ client: Client }>());
export const ClientsAddSuccess = createAction('[Clients] Add New Client Success', props<{ client: Client }>());
export const ClientsAddFail = createAction('[Clients] Add New Client Fail', props<{ error: any }>());

export const ClientsAddMultipleClients =
  createAction('[Clients] Add Multiple New Clients', props<{ clients: Client[] }>());
export const ClientsAddMultipleClientsSuccess =
  createAction('[Clients] Add Multiple New Clients Success', props<{ clients: Client[] }>());
export const ClientsAddMultipleClientsFail =
  createAction('[Clients] Add Multiple New Clients Fail', props<{ error: any }>());

export const ClientsDeleteMultiple = createAction(
  '[Clients]  Delete Multiple Client Documents',
  props<{ clientIds: Guid[] }>()
);
export const ClientsDelete = createAction('[Clients] Delete Client', props<{ clientId: Guid }>());
export const ClientsDeleteSuccess = createAction('[Clients] Delete Client Success');
export const ClientsDeleteFail = createAction('[Clients] Load Client Fail', props<{ error: any }>());

export const clientsUpdated = createAction('[Clients] Client Updated', props<{ update: Update<Client> }>());
export const ClientsUpdate = createAction('[Clients] Update Client', props<{ clientListItem: ClientListItem }>());
export const ClientsUpdateSuccess = createAction(
  '[Clients] Update Client Success',
  props<{ clientListItem: ClientListItem }>()
);
export const ClientsUpdateFail = createAction('[Clients] Update Client Fail', props<{ error: any }>());
