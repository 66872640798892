import { Guid, UserType } from '../../core/models/common-types';
import { PTableColumn } from '../../core/models/common-ux-types';

export interface User {
  id?: Guid; // guid
  clientId: Guid;
  email: string;
  first: string;
  isFirebaseUser: boolean;
  isActive: boolean;
  last: string;
  phone: string;
  userType: UserType;
}

export interface UserListItem extends User {
  // RC Users
  clientName?: string;
}

export function toUser(item: UserListItem): User {
  return {
    id: item.id ?? null,
    clientId: item.clientId,
    email: item.email,
    first: item.first,
    last: item.last,
    isActive: item.isActive,
    isFirebaseUser: item.isFirebaseUser,
    phone: item.phone,
    userType: item.userType,
  };
}

export function toUserListItem(item: User, clientName: string): UserListItem {
  return {
    ...item,
    clientName,
  };
}

// data-label="subscriptionType">{{row.clientSubscriptionItem?.subscriptionType}}</mat-cell>
// </ng-container>
// <ng-container matColumnDef="subscriptionLengthType">
//   <mat-header-cell *matHeaderCellDef
//                    mat-sort-header>Length</mat-header-cell>
//   <mat-cell *matCellDef="let row"
//             data-label="subscriptionLengthType">{{row.clientSubscriptionItem?.subscriptionLengthType}}</mat-cell>
// </ng-container>
// <ng-container matColumnDef="startDate">
//   <mat-header-cell *matHeaderCellDef
//                    mat-sort-header> startDate </mat-header-cell>
//   <mat-cell *matCellDef="let row"
//             data-label="startDate">{{row.clientSubscriptionItem?.startDatePayload.dateStr}}</mat-cell>
// </ng-container>
// <ng-container matColumnDef="endDate">
//   <mat-header-cell *matHeaderCellDef
//                    mat-sort-header> endDate </mat-header-cell>
//   <mat-cell *matCellDef="let row"
//             data-label="endDate">{{row.clientSubscriptionItem?.endDatePayload.dateStr}}</mat-cell>

export const userListDisplayColumns: PTableColumn[] = [
  { field: 'first', header: 'First', width: '5%' },
  { field: 'last', header: 'Last', width: '5%' },
  { field: 'email', header: 'Email', width: '10%' },
  { field: 'phone', header: 'Phone', width: '8%' },
  { field: 'userType', header: 'UserType', width: '6%' },
  { field: 'subscriptionType', header: 'SubType', width: '6%' },
  { field: 'subscriptionLengthType', header: 'SubLen', width: '6%' },
  { field: 'subStartDate', header: 'Start', width: '5%' },
  { field: 'subEndDate', header: 'End', width: '5%' },
  { field: 'isActive', header: 'Active', width: '6%' },
  { field: 'isFirebaseUser', header: 'FbUser', width: '6%' },
  { field: 'actions', header: 'Actions', width: '12%' },
];
// ['first', 'last', 'email', 'phone', 'userType',
//   'subscriptionType',
//   'subscriptionLengthType', 'startDate', 'endDate', 'isActive', 'isFirebaseUser', 'actions'];

export const defaultUser: User = {
  userType: null,
  first: '',
  last: '',
  email: '',
  phone: '',
  clientId: null,
  isFirebaseUser: false,
  isActive: true,
};
export const dummyUser: User = {
  ...defaultUser,
  userType: UserType.CLIENT_PM,
  first: 'Billy',
  last: 'Bob',
  email: 'billy@bob.com',
  phone: '360-679-4441',
};
//  export const dummyTechUser: UserListItem =
//  {
//      ...defaultUser,
//      userType: UserType.CLIENT_TECHNICIAN, first: 'Ray', last: 'Technician', displayName: 'Ray Technician', email: 'hisrodshop@outlook.com', phone: '360-679-4441',

//  };
const adminUser: User = {
  ...defaultUser,
  userType: UserType.ADMIN,
  first: 'admin',
  last: 'projastic',
  email: 'admin@projastic.com',
  phone: '360-679-4441',
  isFirebaseUser: true,
  // clientId: AG_SPORTS_BET_CLIENT_ID,
};

const projasticUserList: User[] = [
  {
    ...defaultUser,
    userType: UserType.SUPER_USER,
    first: 'Super',
    last: 'User', // clientId: AG_SPORTS_BET_CLIENT_ID,
    email: 'su@projastic.com',
    phone: '360-679-1231',
    isFirebaseUser: true,
  },
  { ...adminUser },
];

export const seedUserList: User[] = [...projasticUserList];

// export interface UserResponse { // RC Users
//   _id?: Guid; // guid
//   facilities?: string[];
//   customerId?: Guid;
//   displayName: string;
//   email: string;
//   first: string;
//   last: string;
//   password?: string;
//   phone: string;
//   userType: UserType;
//   // isQaUser: boolean;
//   // parentOrganizationId?: number;
//   // address?: string;
//   // city?: string;
//   // state?: string;
//   // zip?: string;
// }
