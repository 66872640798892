
import { ServiceProductsActions } from './action-types';
import { createReducer, on } from '@ngrx/store';
import { INITIAL_SERVICE_PRODUCTS_STATE } from './service-products.state.interface';

export const serviceProductsReducer = createReducer(
  INITIAL_SERVICE_PRODUCTS_STATE,

  on(ServiceProductsActions.ServiceProductsLoad, ServiceProductsActions.ServiceProductsLoadFail, (state) => ({
    ...state,
    serviceProductListItems: [],
    selectedServiceProduct: undefined,
    isLoaded: false,
  })),

  on(ServiceProductsActions.ServiceProductsLoadSuccess, (state, { serviceProductListItems }) => {
    const newServiceProducts = [...serviceProductListItems];
    return {
      ...state,
      isLoaded: true,
      serviceProductListItems: newServiceProducts.sort((a, b) => a.title.localeCompare(b.title)),
    };
  }),

  on(ServiceProductsActions.SetServiceProductsEditingStatus, (state, { editingStatus }) => {
    return {
      ...state,
      editingStatus,
    };
  }),

  on(ServiceProductsActions.SetSelectedServiceProduct, (state, { serviceProductListItem }) => {
    return {
      ...state,
      selectedServiceProduct: serviceProductListItem,
    };
  })
);

// export function productsReducer2(
//   state = INITIAL_PRODUCTS_STATE,
//   action: ServiceProductsActions
// ): ProductState {
//   switch (action.type) {
//     case PRODUCTS_LOAD:
//     case PRODUCTS_LOAD_FAIL:
//       return {
//         ...state,
//         serviceProductListItems: [],
//         selectedProduct: undefined,
//         isLoaded: false,
//       };
//     case PRODUCTS_LOAD_SUCCESS: {
//       const newServiceProducts = [...action.payload];
//       return {
//         ...state,
//         isLoaded: true,
//         serviceProductListItems: newServiceProducts.sort((a, b) =>
//           a.title.localeCompare(b.title)
//         ),
//       };
//     }
//     case SET_PRODUCTS_EDITING_STATUS:
//       return {
//         ...state,
//         editingStatus: action.payload,
//       };
//     case PRODUCTS_SET_SELECTED_PRODUCT:
//       return {
//         ...state,
//         selectedProduct: action.payload,
//       };
//     default: {
//       return state;
//     }
//   }
// }
