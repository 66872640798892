import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import {
  serviceProductEditingStatusSelector,
  isServiceProductSelectedSelector,
  isLoadedServiceProductsSelector,
  selectedServiceProductSelector,
  serviceProductListItemsSourceSelector,
} from './service-products.selectors';
import { ServiceProduct, ServiceProductListItem } from '../model/ServiceProduct';
import { ServiceProductsActions } from './action-types';
import { EditingStatus, Guid } from '@core/models/common-types';

@Injectable()
export class ServiceProductStateService {
  serviceProductListItems$ = this.store.select(serviceProductListItemsSourceSelector);
  selectedServiceProduct$ = this.store.select(selectedServiceProductSelector);
  isServiceProductSelected$ = this.store.select(isServiceProductSelectedSelector);
  editingStatus$ = this.store.select(serviceProductEditingStatusSelector);
  isLoaded$ = this.store.select(isLoadedServiceProductsSelector);

  constructor(private store: Store<AppState>) { }

  loadServiceProducts() {
    this.store.dispatch(ServiceProductsActions.ServiceProductsLoad());
  }
  setSelectedServiceProduct(serviceProductListItem: ServiceProductListItem) {
    this.store.dispatch(ServiceProductsActions.SetSelectedServiceProduct({ serviceProductListItem }));
  }
  setServiceProductsEditingStatus(editingStatus: EditingStatus) {
    this.store.dispatch(ServiceProductsActions.SetServiceProductsEditingStatus({ editingStatus }));
  }
  addServiceProduct(serviceProduct: ServiceProduct) {
    this.store.dispatch(ServiceProductsActions.ServiceProductsAdd({ serviceProduct }));
  }
  deleteMultipleServiceProducts(serviceProductIds: Guid[]) {
    this.store.dispatch(ServiceProductsActions.ServiceProductsDeleteMultiple({ serviceProductIds }));
  }
  deleteServiceProduct(serviceProductId: Guid) {
    this.store.dispatch(ServiceProductsActions.ServiceProductsDelete({ serviceProductId }));
  }
  updateServiceProduct(serviceProductListItem: ServiceProductListItem) {
    this.store.dispatch(ServiceProductsActions.ServiceProductsUpdate({ serviceProductListItem }));
  }
}
