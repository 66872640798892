import {Injectable, ErrorHandler} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../notifications/notification.service';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private notificationsService: NotificationService) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    // let displayMessage = 'An error occurred.';

    // if (!environment.production) {
    //   displayMessage += ' See console for details.';
    // }
    const toManyAttemps = `Access to this account has been temporarily disabled due to many failed login attempts.\n
    You can immediately restore it by resetting your password or you can try again later.`;
    const pwInvalid = `The password is invalid or the user does not have a password. \n
    Please contact customer support.`;
    const emailInvalid = `The email entered is invalid, if you feel this is an error. \n
    Please contact customer support.`;

    if (error.message?.includes('The password is invalid or the user does not have a password')) {
      this.notificationsService.error(pwInvalid, 10000);
    } else if (error.message?.includes('Access to this account has been temporarily disabled')) {
      this.notificationsService.error(toManyAttemps, 10000);
    } else if (error.message?.includes('There is no user record corresponding to this identifier')) {
      this.notificationsService.error(emailInvalid, 10000);
    } else {
      this.notificationsService.error(error.message, 10000);
    }
    super.handleError(error);
  }
}
