import { Client, ClientDropdownItem, ClientListItem } from 'app/features/admin/clients/model/Client';
import { EditingStatus } from 'app/core/models/common-types';

export interface ClientState {
  clientEditingStatus: EditingStatus;
  clientListItems: ClientListItem[];
  clientDropdownItems: ClientDropdownItem[];
  // loadedUserClient: Client;
  selectedClient: ClientListItem;
}

export const INITIAL_CLIENTS_STATE: ClientState = {
  clientEditingStatus: EditingStatus.NOT_EDITING,
  clientListItems: [],
  clientDropdownItems: [],
  // loadedUserClient: undefined,
  selectedClient: undefined,
};
