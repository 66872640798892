import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { EditingStatus, Guid } from 'app/core/models/common-types';
import { ProductListItem, Product } from '../model/Product';

export const SetProductEditingStatus = createAction(
  '[Products] Set Editing Status',
  props<{ editingStatus: EditingStatus }>()
);

export const SetSelectedProduct = createAction(
  '[Products] Set Selected Product',
  props<{ productListItem: ProductListItem }>()
);

export const ProductsLoad = createAction('[Products] Load Products');
export const ProductsLoadFail = createAction('[Products] Load Products Fail', props<{ error: any }>());
export const ProductsLoadSuccess = createAction(
  '[Products] Load Products Success',
  props<{ productListItems: ProductListItem[] }>()
);

export const ProductsAdd = createAction('[Products] Add New Product', props<{ product: Product }>());
export const ProductsAddSuccess = createAction('[Products] Add New Product Success', props<{ product: Product }>());
export const ProductsAddFail = createAction('[Products] Add New Product Fail', props<{ error: any }>());

export const ProductsDeleteMultiple = createAction(
  '[Products]  Delete Multiple Product Documents',
  props<{ productIds: Guid[] }>()
);
export const ProductsDelete = createAction('[Products] Delete Product', props<{ productId: Guid }>());
export const ProductsDeleteSuccess = createAction('[Products] Delete Product Success');
export const ProductsDeleteFail = createAction('[Products] Load Product Fail', props<{ error: any }>());

export const productUpdated = createAction('[Products] Product Updated', props<{ update: Update<Product> }>());
export const ProductsUpdate = createAction('[Products] Update Product', props<{ productListItem: ProductListItem }>());
export const ProductsUpdateSuccess = createAction(
  '[Products] Update Product Success',
  props<{ productListItem: ProductListItem }>()
);
export const ProductsUpdateFail = createAction('[Products] Update Product Fail', props<{ error: any }>());
