import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  CanActivateChild,
} from '@angular/router';
import { UiStateService } from 'app/core/store/ui/state-service';
import { trace } from 'console';
// import { GamePlanStateService } from 'app/featuresgame-plan/store/state-service';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import { AuthStateService } from './store/auth.state-service';
import { of } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {
  constructor(private authStateService: AuthStateService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return of(true); // this.authStateService.isFirebaseUserAuthenticated$.pipe(take(1));
  }

  canLoad(route: Route) {
    return of(true); // return this.authStateService.isFirebaseUserAuthenticated$.pipe(take(1));
  }
}

@Injectable()
export class AdminGuardService implements CanActivate, CanLoad {
  constructor(private authStateService: AuthStateService) { }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return of(true);
    return this.authStateService.isUserAuthenticated$.pipe(take(1)) && this.authStateService.isAdminUser$.pipe(take(1));
  }

  canLoad(_route: Route) {
    return of(true);
    return this.authStateService.isUserAuthenticated$.pipe(take(1)) && this.authStateService.isAdminUser$.pipe(take(1));
  }
}

// @Injectable()
// export class EditingGuardService implements CanActivate, CanLoad {
//   constructor(private uiStateService: UiStateService) { }

//   canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
//     return true; // (!!this.uiStateService.isEditingEntity$.pipe(take(1)) )
//   }

//   canLoad(_route: Route) {
//     return true; // return (!!this.uiStateService.isEditingEntity$.pipe(take(1)) )
//   }
// }

// @Injectable()
// export class AlmlyPmAuthGuard implements CanActivate, CanActivateChild {
//   almlypm = environment.almlypm;

//   canActivate() {
//     return this.almlypm;
//   }

//   canActivateChild() {
//     return this.canActivate();
//   }
// }
// @Injectable()
// export class AlmlyCmAuthGuard implements CanActivate, CanActivateChild {
//   almlycm = environment.almlycm;

//   canActivate() {
//     return this.almlycm;
//   }

//   canActivateChild() {
//     return this.canActivate();
//   }
// }

// @Injectable()
// export class GamePlansGuardService implements CanActivate, CanLoad {
//   constructor(private authStateService: AuthStateService,
//     private gamePlanStateService: GamePlanStateService) {}

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     return (this.authStateService.isUserAuthenticated$.pipe(take(1)) &&
//     this.gamePlanStateService.isCustomerSelected$.pipe(take(1)))
//   }

//   canLoad(route: Route) {
//     return (this.authStateService.isUserAuthenticated$.pipe(take(1)) &&
//     this.gamePlanStateService.isCustomerSelected$.pipe(take(1)))
//   }
// }
