import { EditingStatus } from 'app/core/models/common-types';
import { Product, ProductListItem, compareProducts } from '../model/Product';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface ProductState extends EntityState<Product> {
  editingStatus: EditingStatus;
  isLoaded: boolean;
  selectedProduct: ProductListItem;
  productListItems: ProductListItem[];
}

export const adapter = createEntityAdapter<Product>({
  sortComparer: compareProducts,
});

export const INITIAL_PRODUCTS_STATE = adapter.getInitialState({
  editingStatus: EditingStatus.NOT_EDITING,
  isLoaded: false,
  selectedProduct: undefined,
  productListItems: [],
});

// export const INITIAL_PRODUCTS_STATE: ProductState = {
//   editingStatus: EditingStatus.NOT_EDITING,
//   isLoaded: false,
//   selectedProduct: undefined,
//   productListItems: [],
// };

// export interface CoursesState extends EntityState<Course> {
//   allCoursesLoaded: boolean;
// }

// export const initialCoursesState = adapter.getInitialState({
//   allCoursesLoaded: false
// });
