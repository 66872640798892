import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { UiStateService } from 'app/core/store/ui/state-service';
import { FirestoreCollectionType, Guid } from 'app/core/models/common-types';
import { Observable, from } from 'rxjs';
import { PROJASTIC_ADMIN_CLIENT_ID } from 'app/core/models/common-ux-types';
import { ServiceProduct, ServiceProductListItem } from './model/ServiceProduct';

@Injectable({ providedIn: 'root' })
export class ServiceProductService {
  constructor(
    private db: AngularFirestore,
    private uiStateService: UiStateService
  ) { }

  loadServiceProducts(clientId: Guid) {
    this.uiStateService.startLoading();
    if (clientId === PROJASTIC_ADMIN_CLIENT_ID) {
      const customerCollection = this.db.collection<ServiceProduct>(FirestoreCollectionType.SERVICE_PRODUCTS, (ref) =>
        ref.orderBy('title', 'asc')
      );
      return customerCollection.snapshotChanges();
    } else {
      const customerCollection = this.db.collection<ServiceProduct>(FirestoreCollectionType.SERVICE_PRODUCTS, (x) =>
        x.where('clientId', '==', clientId)
      );
      return customerCollection.snapshotChanges();
    }
    // or .valueChanges() does not have the id
  }

  // loadServiceProducts(sortField: string, directionStr: firebase.firestore.OrderByDirection) {

  //   this.uiStateService.startLoading();
  //   const subscription = this.db
  //     .collection<Service>(FirestoreCollectionType.SERVICES, x => x.orderBy(sortField, directionStr))
  //     .snapshotChanges().pipe(
  //       map(docArray =>
  //         docArray.map(a => {
  //           const data = a.payload.doc.data() as Service;
  //           const id = a.payload.doc['id'];
  //           return {
  //             ...data,
  //             id
  //           } as ServiceListItem;
  //         })
  //       ))
  //     .subscribe(
  //       (services: ServiceListItem[]) => {
  //         this.uiStateService.stopLoading();
  //         this.serviceListStateService.loadServiceProductsSuccess(services);
  //         subscription.unsubscribe();
  //       },
  //       error => {
  //         console.log('MS loadServiceProducts error = ', error);
  //         this.uiStateService.stopLoading();
  //         this.uiService.showSnackbar(
  //           'Fetching Services failed',
  //           null,
  //           3000
  //         );
  //         subscription.unsubscribe();
  //       }
  //     )
  // }

  addServiceProduct(serviceProduct: ServiceProduct): Observable<DocumentReference<any>> {
    //  return this.http.post<CustomerResponse>(AppServiceApiConfig.customers, customer);
    return from(this.db.collection(FirestoreCollectionType.SERVICE_PRODUCTS).add(serviceProduct));
  }

  deleteServiceProduct(serviceProductId: string) {
    const collection = `${FirestoreCollectionType.SERVICE_PRODUCTS}`;
    return from(this.db.collection(collection).doc(serviceProductId).delete());
  }

  deleteMultipleServiceProducts(serviceProductIdList: string[]) {
    const batch = this.db.firestore.batch();
    serviceProductIdList.forEach((serviceProductId) => {
      const transDoc = this.db.firestore.doc(`${FirestoreCollectionType.SERVICE_PRODUCTS}/${serviceProductId}`);
      batch.delete(transDoc);
    });
    return from(batch.commit());
  }

  updateServiceProduct(serviceProductListItem: ServiceProductListItem) {
    const collection = `${FirestoreCollectionType.SERVICE_PRODUCTS}`;
    const documentId = serviceProductListItem.id;
    return from(
      this.db
        .collection(collection)
        .doc(serviceProductListItem.id)
        .set(this.toServiceProductPayload(serviceProductListItem))
    );
  }

  private toServiceProductPayload(serviceProductListItem: ServiceProductListItem): ServiceProduct {
    const serviceProduct: ServiceProduct = {
      clientId: serviceProductListItem.clientId,
      description: serviceProductListItem.description,
      itemNumber: serviceProductListItem.itemNumber,
      price: serviceProductListItem.price,
      serviceProductType: serviceProductListItem.serviceProductType,
      title: serviceProductListItem.title,
      updatedTimestamp: serviceProductListItem.updatedTimestamp,
      uomType: serviceProductListItem.uomType,
    };
    return serviceProduct;
  }
}
