import {environment} from 'environments/environment';
import {Guid} from './common-types';
import {Time} from '@angular/common';

export const PROJASTIC_ADMIN_CLIENT_ID = 'ProjasticAdmin';
export const CLIENT_ID_DOCUMENT_FIELD = 'ClientId';

export const APP_MENU_HOME = 'Home';
export const APP_MENU_ABOUT = 'About';
export const APP_MENU_HOW_TO = 'How To';
export const APP_MENU_FEATURES = 'Features';
export const APP_MENU_INDUSTRIES = 'Industries';
export const APP_MENU_DASHBOARD = 'Dashboard';
export const APP_MENU_FAQ = 'FAQ';
export const APP_MENU_PROPERTIES = 'Properties';
export const APP_MENU_WORK_ORDERS = 'Work Orders';
export const APP_MENU_CUSTOMERS = 'Customers';
export const APP_MENU_PROJECTS = 'Projects';
export const APP_MENU_PRICING = 'Pricing';
export const APP_MENU_ADMIN = 'Admin';
export const APP_MENU_SIGN_UP = 'Sign Up';
export const APP_MENU_LOGIN = 'Login';
export const APP_MENU_LOGOUT = 'Logout';
export const APP_MENU_SETTINGS = 'Settings';
export const APP_MENU_CONTACT_US = 'Contact Us';

export const APP_MENU_HOME_LINK = 'home';
export const APP_MENU_ABOUT_LINK = 'about';
export const APP_MENU_FAQ_LINK = 'faq';
export const APP_MENU_DASHBOARD_LINK = 'dashboard';
export const APP_MENU_PRICING_LINK = 'pricing';
export const APP_MENU_HOW_TO_LINK = 'how-to';
export const APP_MENU_PROPERTIES_LINK = 'property-view';
export const APP_MENU_CUSTOMERS_LINK = 'customer-view';
export const APP_MENU_WORK_ORDERS_LINK = 'work-order-view';
export const APP_MENU_PROJECTS_LINK = 'project-view';
export const APP_MENU_AUTH_LINK = 'auth';
export const APP_MENU_ADMIN_LINK = 'admin';
export const APP_MENU_SETTINGS_LINK = 'settings';
export const APP_MENU_SIGN_UP_LINK = 'sign-up';
export const APP_MENU_LOGIN_LINK = 'login';
export const APP_MENU_CONTACT_US_LINK = 'contact-us';

export const APP_TITLE_LONG = 'Projastic';
export const APP_TITLE_SHORT = 'Projastic';
export const APP_PARENT_COMPANY = 'Projastic';
// export const APP_COPYRIGHT = 'OneWay Web Applications, LLC.';
export const APP_COPYRIGHT = 'OneWay Web Apps';
// export const APP_NAME = 'Projastic';
export const APP_INFO_EMAIL = 'info@projastic.com';
export const APP_SUPPORT_EMAIL = 'support@projastic.com';
// TODO SEE IF THIS WORKS in about.html
export const APP_INFO_HREF = 'mailto:info@projastic.com';

export const APP_LINK_ADMIN = '/admin';

export function getAppTitleShort() {
  if (environment.almlycm) {
    return 'ProJastic';
  } else {
    return 'PropJastic';
  }
}
export function getAppTitleLong() {
  if (environment.almlycm) {
    return 'ProJastic';
  } else {
    return 'PropJastic';
  }
}

export interface PJNavigationLink {
  link: string;
  label: string;
}
export interface SinglePluralString {
  single: string;
  plural: string;
}
export interface FetchPayloadData {
  entityId: Guid;
  documentField: string;
}

export interface DatePayload {
  date: Date;
  gameDateTime: Date;
  gameTime?: Time;
  dateStr: string;
  sortDateStr: string;
}

export interface PTableColumn {
  field: string;
  header: string;
  width?: string;
}

export interface PDropdownItem {
  value: string;
  viewValue?: string;
}

export function getPDropdownItems<Type>(arg: Type): PDropdownItem[] {
  const items: PDropdownItem[] = [];

  Object.values(arg).forEach((value) => {
    items.push({value: value});
  });

  console.log('MS getTypeDropdownItems  items= ', items);
  return items;
}
