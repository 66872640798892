/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from '../../../../core/models/common-types';
import firebase from 'firebase/compat/app';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PTableColumn } from '../../../../core/models/common-ux-types';
import { ServiceProductType, ServiceProductUomType } from './types';

export class ServiceProduct {
  id?: Guid;
  clientId: Guid;
  // itemName?: string;
  description?: string;
  itemNumber: string;
  price?: number;
  serviceProductType: ServiceProductType;
  title: string;
  uomType: ServiceProductUomType;
  updatedTimestamp: firebase.firestore.Timestamp;
}

export class ServiceProductItem {
  serviceId: Guid;
  itemNumber: string;
  title: string;
  quantity: number;
  price: number;
  priceUpdatedTimestamp: firebase.firestore.Timestamp;
  total: number;
  uomType: ServiceProductUomType;
}

export const serviceItemDisplayColumns = [
  'itemNumber',
  'title',
  'description',
  'quantity',
  'price',
  'total',
  'actions',
];

export class ServiceProductListItem extends ServiceProduct {
  currentPrice: number;
  isSelected: boolean;
  clientName?: string;
  updatedDateStr: string;
  quantity: number;
  total: number;

  static asFormGroup(serviceListItem: ServiceProductListItem): FormGroup {
    const fg = new FormGroup({
      id: new FormControl(serviceListItem.id, Validators.required),
      // isSelected: new FormControl(productItem.isSelected, Validators.required),
      title: new FormControl(serviceListItem.title, Validators.required),
      description: new FormControl(serviceListItem.description),
      itemNumber: new FormControl(serviceListItem.itemNumber, Validators.required),
      price: new FormControl(serviceListItem.price, Validators.required),
      uomType: new FormControl(serviceListItem.uomType),
      currentPrice: new FormControl(serviceListItem.currentPrice),
      quantity: new FormControl(serviceListItem.quantity, Validators.required),
      total: new FormControl(serviceListItem.total, Validators.required),
      updatedDateStr: new FormControl(serviceListItem.updatedDateStr, Validators.required),
    });
    return fg;
  }
}

export function toServiceItem(item: ServiceProductListItem): ServiceProductItem {
  return {
    serviceId: item.id,
    itemNumber: item.itemNumber,
    title: item.title,
    quantity: item.quantity,
    price: item.price,
    total: item.total,
    priceUpdatedTimestamp: item.updatedTimestamp,
    uomType: item.uomType,
  };
}

export const defaultServiceProductListItem: ServiceProductListItem = {
  clientId: '',
  clientName: '',
  description: '',
  itemNumber: '',
  price: 0,
  total: 0,
  serviceProductType: ServiceProductType.RESIDENTIAL,
  title: '',
  updatedTimestamp: null,
  updatedDateStr: '',
  // imagePath: '',
  isSelected: false,
  currentPrice: 0,
  quantity: 0,
  uomType: ServiceProductUomType.FLAT_RATE,
};

export const dummyServiceProductListItem: ServiceProductListItem = {
  serviceProductType: ServiceProductType.COMMERICAL,
  clientName: '',
  clientId: '',
  description: 'This is the description',
  itemNumber: '0485001A',
  title: 'This is the title',
  updatedTimestamp: null,
  updatedDateStr: '',
  total: 0,
  quantity: 0,
  isSelected: false,
  currentPrice: 0,
  uomType: ServiceProductUomType.FLAT_RATE,
};

export const userServiceProductListDisplayColumns: PTableColumn[] = [
  { field: 'itemNumber', header: 'Item #', width: '8%' },
  { field: 'title', header: 'Title', width: '5%' },
  { field: 'description', header: 'Description', width: '22%' },
  // { field: 'quantity', header: 'quantity', width: '7%' },
  { field: 'price', header: 'Price/Rate', width: '7%' },
  { field: 'uomType', header: 'UOM', width: '7%' },
  // { field: 'total', header: 'total', width: '7%' },
  // { field: 'currentPrice', header: 'currentPrice', width: '7%' },
  { field: 'updatedDateStr', header: 'Updated', width: '7%' },
  { field: 'actions', header: '', width: '12%' },
];

export const adminServiceProductListDisplayColumns: PTableColumn[] = [
  { field: 'clientName', header: 'Client', width: '8%' },
  ...userServiceProductListDisplayColumns,
];
// export const serviceTableListDisplayColumns =
// ['serviceType', 'title', 'description', 'price', 'actions'];

export const pjAdminServiceTableListDisplayColumns = [
  'clientName',
  'serviceType',
  'itemNumber',
  'title',
  'description',
  'price',
  'uomType',
  'updatedDateStr',
  'actions',
];

export function compareServiceProducts(c1: ServiceProduct, c2: ServiceProduct) {
  const compareIt = c1.title.localeCompare(c2.title);

  if (compareIt > 0) {
    return 1;
  } else if (compareIt < 0) {
    return -1;
  } else return 0;
}
