import {
  ClientSubscription,
  ClientSubscriptionItem,
  defaultClientSubscription,
  toClientSubscription,
  toClientSubscriptionItem,
} from './ClientSubscription';
import {
  Guid,
  ClientServiceType,
  ClientType,
  SubscriptionType,
  SubscriptionLengthType,
} from '../../../../core/models/common-types';
import { PTableColumn } from '../../../../core/models/common-ux-types';

export const DEFAULT_REPORT_HEADER_COLOR: string = '#2d428c';

export interface ClientDropdownItem {
  id: Guid; // guid
  name: string;
}

export interface Client {
  id?: Guid; // guid
  address?: string;
  // businessModelType: ClientBusinessModelType;
  city?: string;
  clientSubscription: ClientSubscription;
  clientType: ClientType;
  contactName: string;
  email: string;
  isActive: boolean;
  imageUrl?: string;
  imagePath?: string;
  // sgImageType?: SgImageType;
  name: string;
  path: string; // for storage
  phone?: string;
  reportHeaderColor: string;
  serviceType: ClientServiceType;
  slogan: string;
  state?: string;
  zip?: string;
}
export interface ClientListItem extends Client {
  //  clientTypeStr: string; punt for now, in case we have different client types
  clientSubscriptionItem: ClientSubscriptionItem;
  subscriptionType: SubscriptionType;
  subscriptionLengthType: SubscriptionLengthType;
  subStartDate: string;
  subEndDate: string;
}

export function toClientListItem(client: Client): ClientListItem {
  const clientSubscriptionItem = client.clientSubscription
    ? toClientSubscriptionItem(client.clientSubscription)
    : undefined;
  return {
    ...client,
    clientSubscriptionItem,
    subscriptionType: clientSubscriptionItem?.subscriptionType,
    subscriptionLengthType: clientSubscriptionItem?.subscriptionLengthType,
    subStartDate: clientSubscriptionItem?.startDatePayload?.sortDateStr,
    subEndDate: clientSubscriptionItem?.endDatePayload?.sortDateStr,

    // clientTypeStr: client.clientType,
  };
}

export function toClientPayload(clientListItem: ClientListItem): Client {
  console.log('MS toClientPayload clientListItem = ', clientListItem);
  const clientSubscription = toClientSubscription(clientListItem.clientSubscriptionItem);
  const clientPayload: Client = {
    address: clientListItem.address ?? null,
    // businessModelType: clientListItem.businessModelType ?? ClientBusinessModelType.CONTRACTOR,
    city: clientListItem.city ?? null,
    clientSubscription: clientSubscription ?? null,
    clientType: clientListItem.clientType,
    contactName: clientListItem.contactName ?? '',
    email: clientListItem.email ?? '',
    isActive: clientListItem.isActive,
    imageUrl: clientListItem.imageUrl ?? null,
    imagePath: clientListItem.imagePath ?? null,
    name: clientListItem.name ?? '',
    path: clientListItem.path ?? '',
    phone: clientListItem.phone ?? '',
    reportHeaderColor: clientListItem.reportHeaderColor ?? '',
    serviceType: clientListItem.serviceType,
    slogan: clientListItem.slogan ?? '',
    state: clientListItem.state ?? '',
    zip: clientListItem.zip ?? '',
  };
  console.log('MS toClientPayload clientPayload= ', clientPayload);
  return clientPayload;
}

export const clientDisplayedColumnsOLd = [
  'name',
  'path',
  'contactName',
  'email',
  'phone',
  'address',
  'city',
  'state',
  'zip',
  'serviceType',
  'imagePath',
  'isActive',
  'actions',
]; // 'terms', 'clientType',

export const clientDisplayedColumns: PTableColumn[] = [
  { field: 'name', header: 'Company', width: '10%' },
  { field: 'path', header: 'path', width: '5%' },
  { field: 'contactName', header: 'Contact', width: '5%' },
  { field: 'phone', header: 'Phone', width: '8%' },
  { field: 'email', header: 'Email', width: '6%' },
  { field: 'address', header: 'Address', width: '6%' },
  { field: 'city', header: 'City', width: '6%' },
  { field: 'state', header: 'State', width: '4%' },
  { field: 'zip', header: 'zip', width: '4%' },
  { field: 'serviceType', header: 'serviceType', width: '6%' },
  { field: 'imagePath', header: 'imagePath', width: '6%' },
  { field: 'isActive', header: 'Active', width: '4%' },
  { field: 'actions', header: 'Actions', width: '8%' },
];

export const defaultClient: Client = {
  address: '',
  // businessModelType: ClientBusinessModelType.CONTRACTOR,
  city: '',
  clientSubscription: { ...defaultClientSubscription },
  clientType: ClientType.PROJASTIC_CLIENT,
  contactName: '',
  email: '',
  imagePath: '',
  // sgImageType:SgImageType.IMAGE_NONE,
  isActive: true,
  name: '',
  path: '',
  phone: '',
  reportHeaderColor: DEFAULT_REPORT_HEADER_COLOR,
  serviceType: null,
  slogan: '',
  state: '',
  zip: '',
};

export const dummyClient: Client = {
  ...defaultClient,
  clientSubscription: { ...defaultClientSubscription },
  name: 'Your Name Here',
  path: 'yourcompany',
  email: 'info@yourcompany.com',
  contactName: 'John Owner',
  address: '2344 3rd Street',
  city: 'Arlington',
  slogan: 'We exceed all expectations!',
  state: 'TX',
  zip: '76011',
  phone: '(817) 892-4100',
};

// export const clientSeedList: Client[] = [
//   {
//     city: 'Kirkland',
//     address: '2344 3rd Street',
//     imagePath: 'cbre.jpg',
//     path: 'cbrekirkland',
//     email: 'akma.gerzic@cbre.com',
//     terms: 'Due Upon Receipt',
//     state: 'WA',
//     reportHeaderColor: '#126409',
//     slogan: '',
//     clientSubscription: null,
//     phone: '(425) 269-6598',
//     zip: '98033',
//     serviceType: ClientServiceType.PROP_MANAGER,
//     businessModelType: ClientBusinessModelType.SUB_CONTRACTOR,
//     name: 'CBRE Kirkland',
//     clientType: ClientType.PROJASTIC_CLIENT,
//     contactName: 'Akma Gerzic',
//     isActive: true
//   },
//   {
//     clientSubscription: null,
//     slogan: '',
//     address: '2344 3rd Street',
//     terms: 'Due Upon Receipt',
//     clientType: ClientType.PROJASTIC_CLIENT,
//     phone: '(817) 892-4100',
//     reportHeaderColor: '#2d428c',
//     isActive: true,
//     serviceType: ClientServiceType.SERVICE_LANDSCAPING,
//     contactName: 'Joey Jones',
//     state: 'TX',
//     imageUrl: null,
//     city: 'Arlington',
//     path: 'jjspressurewashing',
//     zip: '76011',
//     name: 'JJs Pressure Washing',
//     imagePath: null,
//     email: 'info@jjspw.com',
//     businessModelType: ClientBusinessModelType.SUB_CONTRACTOR
//   },
//   {
//     slogan: '',
//     address: '2344 3rd Street',
//     imagePath: null,
//     reportHeaderColor: '#2d428c',
//     name: 'Leatherneck PDR, LLC',
//     clientSubscription: null,
//     imageUrl: null,
//     isActive: true,
//     state: 'WA',
//     clientType: ClientType.PROJASTIC_CLIENT,
//     path: 'leatherneckpdr,llc',
//     terms: 'Due Upon Receipt',
//     businessModelType: ClientBusinessModelType.SUB_CONTRACTOR,
//     serviceType: ClientServiceType.SERVICE_AUTOMOTIVE,
//     id: '71mK8AmENCg5bOCJmE5u',
//     contactName: 'Jarrid Wilcken',
//     email: 'jarrid@pdr.com',
//     zip: '98006',
//     city: 'Bellevue',
//     phone: '(206) 682-5693'
//   },
//   {
//     clientSubscription: null,
//     city: 'Issaquah',
//     terms: 'Due Upon Receipt',
//     clientType: ClientType.PROJASTIC_CLIENT,
//     address: '18838 SE 46TH Way',
//     contactName: 'Greg Dixon',
//     serviceType: ClientServiceType.SERVICE_ELECTRIC,
//     name: 'MRK Electric Inc.',
//     reportHeaderColor: '#268f2e',
//     state: 'WA',
//     phone: '(425) 766-4222',
//     businessModelType: ClientBusinessModelType.SUB_CONTRACTOR,
//     isActive: true,
//     path: 'mrkelectric',
//     zip: '98027',
//     email: 'greg@mrk.com'
//   },
//   {
//     clientSubscription: null,
//     serviceType: ClientServiceType.SERVICE_MITIGATION,
//     contactName: 'Info Rescom',
//     state: 'WA',
//     businessModelType: ClientBusinessModelType.SUB_CONTRACTOR,
//     city: 'Hoquiam',
//     clientType: ClientType.PROJASTIC_CLIENT,
//     address: '233 3rd St',
//     path: 'rescomusa',
//     phone: '(360) 650-4793',
//     terms: 'Due Upon Receipt',
//     name: 'ResComUSA, LLC',
//     isActive: true,
//     zip: '98055',
//     email: 'info@rescomusa.com',
//     reportHeaderColor: '#5a7c7e',
//   },
//   {
//     serviceType: ClientServiceType.SERVICE_ELECTRIC,
//     clientSubscription: null,
//     reportHeaderColor: '#2d428c',
//     path: 'yourcompanyname',
//     phone: '(817) 892-4100',
//     address: '2344 3rd Street',
//     terms: 'Due Upon Receipt',
//     state: 'TX',
//     email: 'info@yourcompany.com',
//     slogan: '',
//     contactName: 'Johnny Subcontractor',
//     isActive: true,
//     businessModelType: ClientBusinessModelType.SUB_CONTRACTOR,
//     zip: '76011',
//     city: 'Arlington',
//     clientType: ClientType.PROJASTIC_CLIENT,
//     name: 'Your Company Name',
//   }
// ]
