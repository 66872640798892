import * as AuthActions from './auth.action';
export { AuthActions };


// export const SET_USER_EDITING_STATUS = '[Auth] Set Selected User Editing Status';
// export const SET_USER_LIST_SELECTED_USER = '[Auth] Set User List Selected User';

// export const SET_FIREBASE_USER_AUTHENTICATED = '[Auth] Set Firebase User Authenticated';
// export const SET_FIREBASE_USER_UNAUTHENTICATED = '[Auth] Set Firebase User Unauthenticated';

// export const LOAD_USERS = '[Auth] Load RC Users';
// export const LOAD_USERS_FAIL = '[Auth] Load RC Users Fail';
// export const LOAD_USERS_SUCCESS = '[Auth] Load RC Users Success';

// export const LOAD_USER_FOR_AUTH = '[Auth] Load RC User for Auth';
// export const LOAD_USER_FOR_AUTH_FAIL = '[Auth] Load RC Users Auth Fail';
// export const LOAD_USER_FOR_AUTH_SUCCESS = '[Auth] Load RC Users Auth Success';

// export const LOAD_USER_CLIENT_FOR_AUTH = '[Auth] Load RC User Client for Auth';
// export const LOAD_USER_CLIENT_FOR_AUTH_FAIL = '[Auth] Load RC Users Client Auth Fail';
// export const LOAD_USER_CLIENT_FOR_AUTH_SUCCESS = '[Auth] Load RC Users Client Auth Success';

// export const SET_USER_AUTHENTICATED = '[Auth] Set RC User Authenticated';
// export const SET_USER_UNAUTHENTICATED = '[Auth] Set RC User Unauthenticated';

// export const LOAD_SETTINGS = '[Auth] Load PjSettings';
// export const LOAD_SETTINGS_FAIL = '[Auth] Load PjSettings Fail';
// export const LOAD_SETTINGS_SUCCESS = '[Auth] Load PjSettings Success';
// export const UPDATE_SETTINGS = '[Auth] Update PjSettings Document';
// export const UPDATE_SETTINGS_FAIL = '[Auth] Update PjSettings Document Fail';
// export const UPDATE_SETTINGS_SUCCESS = '[Auth] Update PjSettings Document Success';

// export const ADD_USER = '[Auth] Add New User';
// export const ADD_USER_FAIL = '[Auth] Add New User Fail';
// export const ADD_USER_SUCCESS = '[Auth] Add New User Success';
// export const UPDATE_USER = '[Auth] Update User Document';
// export const UPDATE_USER_FAIL = '[Auth] Update User Document Fail';
// export const UPDATE_USER_SUCCESS = '[Auth] Update User Document Success';
// export const DELETE_USER = '[Auth] Delete User Document';
// export const DELETE_USER_FAIL = '[Auth] Delete User Document Fail';
// export const DELETE_USER_SUCCESS = '[Auth] Delete User Document Success';

// // this is to send the sign up for to support@projastic.com
// export const EMAIL_NEW_CLIENT_CONTACT_INFO = '[Auth] Email New Client Contact Info';
// export const EMAIL_NEW_CLIENT_CONTACT_INFO_FAIL = '[Auth] Email New Client Contact Info Fail';
// export const EMAIL_NEW_CLIENT_CONTACT_INFO_SUCCESS = '[Auth] Email New Client Contact Info Success';
// // this is to send an email to a customer
// export const SEND_EMAIL_TO_CUSTOMER = '[Auth] Send Email to Customer';
// export const SEND_EMAIL_TO_CUSTOMER_FAIL = '[Auth] Send Email to Customer Fail';
// export const SEND_EMAIL_TO_CUSTOMER_SUCCESS = '[Auth] Send Email to Customer Success';

// export const SEND_RECAPTCHA_TOKEN = '[Auth] Send Recaptcha';
// export const SEND_RECAPTCHA_TOKEN_FAIL = '[Auth] Send Recaptcha Fail';
// export const SEND_RECAPTCHA_TOKEN_SUCCESS = '[Auth] Send Recaptcha Success';

// export const EMAIL_CUSTOMER_SERVICE_REQUEST = '[Auth] Email Customer Service Request';
// export const EMAIL_CUSTOMER_SERVICE_REQUEST_FAIL = '[Auth] Email Customer Service Request Fail';
// export const EMAIL_CUSTOMER_SERVICE_REQUEST_SUCCESS = '[Auth] Email Customer Service Request Success';
