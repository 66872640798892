import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';

@Injectable()
export class UiService {
  constructor(private messageService: MessageService) {}

  showSnackbar(message: string, severity: string, life = 4000, closable = false) {
    if (!severity) {
      severity = 'info';
    }
    if (closable) {
      this.messageService.add({severity, summary: message, closable});
    } else {
      this.messageService.add({severity, summary: message, life});
      // this.messageService.add({severity, summary:'Service Message', detail:'Via MessageService'});
    }
  }

  // showSnackbar(message, action, duration) {

  // this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});

  //   // this.snackbar.open(message, action, {
  //   //   duration: duration,
  //   //   // horizontalPosition: this.horizontalPosition,
  //   //   verticalPosition: 'top'
  //   // });
  // }
}
