import { UserType } from '@core/models/common-types';

export function isAdminUser(userType: UserType) {
  return userType === UserType.ADMIN || userType === UserType.SUPER_USER;
}
export function isSuperUser(userType: UserType) {
  return userType === UserType.SUPER_USER;
}
export function hasPropMmgmtPrivileges(userType: UserType) {
  return userType === UserType.CLIENT_PM || userType === UserType.ADMIN || userType === UserType.SUPER_USER;
}
export function hasClientAdminPrivileges(userType: UserType) {
  return userType === UserType.CLIENT_PM || userType === UserType.ADMIN || userType === UserType.SUPER_USER;
}

export function isClientUserType(userType: UserType): boolean {
  return userType !== UserType.SUPER_USER &&
    userType !== UserType.ADMIN &&
    userType !== UserType.CLIENT_CUSTOMER;
}
export function isClientCustomerUser(userType: UserType): boolean {
  return userType === UserType.CLIENT_CUSTOMER;
}

export function isTechnicianUser(userType: UserType) {
  return userType === UserType.CLIENT_TECHNICIAN;
}

