import {createSelector} from '@ngrx/store';
import {AppState} from 'app/core/core.state';
import {UiState} from '../state/ui.state.interface';

export const uiStateSelector = (state: AppState): UiState => state.uiState;

export const isLoadingSelector = createSelector(uiStateSelector, (state: UiState) =>
  state.isLoading ? state.isLoading : false
);

export const isMobileDeviceSelector = createSelector(uiStateSelector, (state: UiState) =>
  state.isMobileDevice ? state.isMobileDevice : false
);
export const isAdminActiveSelector = createSelector(
  uiStateSelector,
  (state: UiState) => state.isAdminActive ?? state.isAdminActive
);
export const isEditingEntitySelector = createSelector(
  uiStateSelector,
  (state: UiState) => state.isEditingEntity ?? state.isEditingEntity
);

// export const selectedAdminTabIndexSelector = createSelector(
//   uiStateSelector,
//   (state: UiState) => state.selectedAdminTabIndex ?? state.selectedAdminTabIndex
// );
