import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
// import { EditingStatus, Guid } from 'app/core/models/common-types';
// import {
//   ProductsLoad,
//   ProductsSetSelectedProduct,
//   SetProductEditingStatus,
//   ProductsAdd,
//   ProductsDelete,
//   ProductsUpdate,
//   ProductsDeleteMultiple,
// } from '../actions';
import {
  selectedProductSelector,
  isProductSelectedSelector,
  wagerSourceEditingStatusSelector,
  isLoadedProductSelector,
  productListItemsSourceSelector,
} from './products.selectors';
import { Product, ProductListItem } from '../model/Product';
import { ProductsActions } from './action-types';
import { EditingStatus, Guid } from '@core/models/common-types';

@Injectable()
export class ProductStateService {
  productListItems$ = this.store.select(productListItemsSourceSelector);
  selectedProduct$ = this.store.select(selectedProductSelector);
  isProductSelected$ = this.store.select(isProductSelectedSelector);
  editingStatus$ = this.store.select(wagerSourceEditingStatusSelector);
  isLoaded$ = this.store.select(isLoadedProductSelector);

  constructor(private store: Store<AppState>) { }

  loadProducts() {
    this.store.dispatch(ProductsActions.ProductsLoad());
  }
  setSelectedProduct(productListItem: ProductListItem) {
    this.store.dispatch(ProductsActions.SetSelectedProduct({ productListItem }));
  }
  setProductEditingStatus(editingStatus: EditingStatus) {
    this.store.dispatch(ProductsActions.SetProductEditingStatus({ editingStatus }));
  }
  addProduct(product: Product) {
    this.store.dispatch(ProductsActions.ProductsAdd({ product }));
  }
  deleteMultipleProducts(productIds: Guid[]) {
    this.store.dispatch(ProductsActions.ProductsDeleteMultiple({ productIds }));
  }
  deleteProduct(productId: Guid) {
    this.store.dispatch(ProductsActions.ProductsDelete({ productId }));
  }
  updateProduct(productListItem: ProductListItem) {
    this.store.dispatch(ProductsActions.ProductsUpdate({ productListItem }));
  }
}
