import { FirebaseUser } from 'app/auth/auth-data.model';
import { EditingStatus } from 'app/core/models/common-types';
import { ProjSettings } from '@core/models/ProjSettings';
import { UserListItem } from '../model/User';
import { Client } from 'app/features/admin/clients/model/Client';

export interface AuthState {
  firebaseUser: FirebaseUser;
  isFirebaseUserAuthenticated: boolean;
  isRecaptchaTokenAuthenticated: boolean;
  isUserAuthenticated: boolean;
  loggedInUser: UserListItem;
  loggedInUserClient: Client;
  seletectedUserListItem: UserListItem;
  userEditingStatus: EditingStatus;
  userListItems: UserListItem[];
  // userListItems: UserListItem[];
  projSettings: ProjSettings;
  // isRecaptchaPassed: boolean;
}

export const INITIAL_PJ_SETTINGS: ProjSettings = {
  isSignUpActive: false,
};

export const INITIAL_AUTH_STATE: AuthState = {
  firebaseUser: undefined,
  isFirebaseUserAuthenticated: false,
  isRecaptchaTokenAuthenticated: false,
  isUserAuthenticated: false,
  loggedInUser: undefined,
  loggedInUserClient: undefined,
  seletectedUserListItem: undefined,
  userEditingStatus: EditingStatus.EDITING,
  userListItems: [],
  projSettings: INITIAL_PJ_SETTINGS,
};
