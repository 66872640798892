import { FirebaseUser } from 'app/auth/auth-data.model';
import { Client } from 'app/features/admin/clients/model/Client';
import { ProjEmailRequest } from 'app/core/models/ProjEmailRequest';
import { User } from 'app/auth/model/User';

export function authenticateUser(users: User[], firebaseUser: FirebaseUser): User {
  let user: User;
  console.log('MS authenticateUser firebaseUser= ', firebaseUser);
  if (firebaseUser) {
    user = users.find((user) => user.email === firebaseUser.email);
  }
  console.log('MS authenticateUser user= ', user);
  return user;
}

export function transformSgEmailRequest(projEmailRequest: ProjEmailRequest, newClient: Client): ProjEmailRequest {
  return {
    email: newClient.email,
    subject: 'Thank You from Projastic',
    html: `Thanks for contacting us, ${newClient.contactName}. <br><br>
          One of our account specialists will be in contact with you soon!<br><br>
          We look forward to speaking with you.<br>
          Best Regards,<br>
          Projastic`,
    // recaptchaToken: projEmailRequest.recaptchaToken
  };
}
// }

// sendThankYouToCustomer(newCustomer, token: string): ProjEmailRequest {
//     const reqObj: ProjEmailRequest = {
//       email: newCustomer.email,
//       subject: 'Thank You from OneWay Web Apps',
//       html: `Thanks for contacting us, ${newCustomer.contactName}. <br><br>
//       One of our account specialists will be in contact with you soon!<br><br>
//       We look forward to speaking with you.<br>
//       Best Regards,<br>
//       OneWay Web Apps`,
//       recaptchaToken: token
//     };
//     return reqObj;
//     // this.emailService.sendMessage(reqObj).subscribe(data => {
//     //   console.log('sendMessage ', data);
//     // });
//   }
