import { createSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { EditingStatus } from 'app/core/models/common-types';
import { ServiceProductState } from './service-products.state.interface';

export const serviceStateSelector = (state: AppState): ServiceProductState => state.serviceProductState;

export const serviceProductListItemsSourceSelector = createSelector(
  serviceStateSelector,
  (state: ServiceProductState) => (state.serviceProductListItems ? state.serviceProductListItems : [])
  // (state: RcAdminState) => state.customers? state.customers.sort((a, b) => a.name.localeCompare(b.name)) : []
);
export const selectedServiceProductSelector = createSelector(
  serviceStateSelector,
  (state: ServiceProductState) => state.selectedServiceProduct ?? undefined
);
export const isLoadedServiceProductsSelector = createSelector(
  serviceStateSelector,
  (state: ServiceProductState) => state.isLoaded ?? false
);

export const isServiceProductSelectedSelector = createSelector(serviceStateSelector, (state: ServiceProductState) =>
  state.selectedServiceProduct ? true : false
);
export const serviceProductEditingStatusSelector = createSelector(serviceStateSelector, (state: ServiceProductState) =>
  state.editingStatus ? state.editingStatus : EditingStatus.NOT_EDITING
);
