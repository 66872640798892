import {createSelector} from '@ngrx/store';
import {AppState} from 'app/core/core.state';
import {EditingStatus} from 'app/core/models/common-types';
import {ProductState} from './products.state.interface';

export const productStateSelector = (state: AppState): ProductState => state.productState;

export const productListItemsSourceSelector = createSelector(
  productStateSelector,
  (state: ProductState) => (state.productListItems ? state.productListItems : [])
  // (state: RcAdminState) => state.customers? state.customers.sort((a, b) => a.name.localeCompare(b.name)) : []
);
export const selectedProductSelector = createSelector(
  productStateSelector,
  (state: ProductState) => state.selectedProduct ?? undefined
);
export const isLoadedProductSelector = createSelector(
  productStateSelector,
  (state: ProductState) => state.isLoaded ?? false
);

export const isProductSelectedSelector = createSelector(productStateSelector, (state: ProductState) =>
  state.selectedProduct ? true : false
);

// export const loadedUserProductSelector = createSelector(
//   productStateSelector,
//   (state: ProductState) => state.loadedUserProduct ? state.loadedUserProduct : undefined
// );

export const wagerSourceEditingStatusSelector = createSelector(productStateSelector, (state: ProductState) =>
  state.editingStatus ? state.editingStatus : EditingStatus.NOT_EDITING
);
