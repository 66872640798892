import { Injectable } from '@angular/core';
import { Client } from 'app/features/admin/clients/model/Client';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { FirestoreCollectionType, Guid } from 'app/core/models/common-types';
import { Observable, from } from 'rxjs';
import { UiStateService } from 'app/core/store/ui/state-service';

@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(
    private db: AngularFirestore,
    private uiStateService: UiStateService
  ) { }

  loadClients(sortField: string, directionStr: firebase.firestore.OrderByDirection) {
    this.uiStateService.startLoading();

    console.log('MS loadClients sortField = ', sortField);
    return this.db
      .collection<Client>(FirestoreCollectionType.CLIENTS, (x) => x.orderBy(sortField, directionStr))
      .snapshotChanges();
    // or .snapshotChanges()
  }

  loadClient(clientId: Guid) {
    this.uiStateService.startLoading();
    return (
      this.db
        .doc(`${FirestoreCollectionType.CLIENTS}/${clientId}`)
        // you can use either:
        .valueChanges()
    );
    // or .snapshotChanges()
  }

  addClient(client: Client): Observable<DocumentReference<any>> {
    return from(this.db.collection(FirestoreCollectionType.CLIENTS).add(client));
  }

  deleteMultipleProjects(documentIdList: string[]) {
    const batch = this.db.firestore.batch();
    documentIdList.forEach((id) => {
      const transDoc = this.db.firestore.doc(`${FirestoreCollectionType.PROJECTS}/${id}`);
      batch.delete(transDoc);
    });
    return from(batch.commit());
  }

  addMultipleClients(clients: Client[]) {
    const batch = this.db.firestore.batch();
    clients.forEach((client) => {
      const transDoc = this.db.firestore.doc(`${FirestoreCollectionType.CLIENTS}`);
      // const transDoc = doc(this.db,.collection(FirestoreCollectionType.CLIENTS);
      batch.set(transDoc, client);
    });
    return from(batch.commit());
  }

  //   import { writeBatch, doc } from "firebase/firestore";

  // // Get a new write batch
  // const batch = writeBatch(db);

  // // Set the value of 'NYC'
  // const nycRef = doc(db, "cities", "NYC");
  // batch.set(nycRef, {name: "New York City"});

  // // Update the population of 'SF'
  // const sfRef = doc(db, "cities", "SF");
  // batch.update(sfRef, {"population": 1000000});

  // // Delete the city 'LA'
  // const laRef = doc(db, "cities", "LA");
  // batch.delete(laRef);

  // // Commit the batch
  // await batch.commit();

  deleteClient(clientId: string) {
    const collection = `${FirestoreCollectionType.CLIENTS}`;
    return from(this.db.collection(collection).doc(clientId).delete());
  }

  updateClient(client: Client) {
    const collection = `${FirestoreCollectionType.CLIENTS}`;
    const documentId = client.id;
    return from(this.db.collection(collection).doc(documentId).set(client));
  }
}
