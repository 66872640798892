export enum ServiceProductUomType {
  FLAT_RATE = 'FR',
  HOURLY = 'HR',
  EACH = 'EA',
  SQFT = 'SF',
  SQYD = 'SY',
  OTHER = 'Other'
  // MATERIAL = 'Material',
  //  SUPPLY = 'Supply'
}
// export const serviceUomTypePDropdownItems: PDropdownItem[] = [
//   {value: ServiceUomType.FLAT_RATE, viewValue: ServiceUomType.FLAT_RATE},
//   {value: ServiceUomType.HOURLY, viewValue: ServiceUomType.HOURLY},
//   {value: ServiceUomType.OTHER, viewValue: ServiceUomType.OTHER},
//   {value: ServiceUomType.MONTHLY, viewValue: ServiceUomType.MONTHLY},
//   {value: ServiceUomType.YEARLY, viewValue: ServiceUomType.YEARLY},
// ];

export enum ServiceProductType {
  RESIDENTIAL = 'Residential',
  COMMERICAL = 'Commercial',
  RETAIL = 'Retail',
  OTHER = 'Other'
}

// export const serviceTypePDropdownItems: PDropdownItem[] = [
//   {value: ServiceType.RESIDENTIAL, viewValue: ServiceType.RESIDENTIAL},
//   {value: ServiceType.COMMERICAL, viewValue: ServiceType.COMMERICAL},
//   {value: ServiceType.CHANGE_ORDER, viewValue: ServiceType.CHANGE_ORDER},
//   {value: ServiceType.TIME_MATERIAL, viewValue: ServiceType.TIME_MATERIAL},
//   {value: ServiceType.OTHER, viewValue: ServiceType.OTHER},
// ];
