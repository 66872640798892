import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UiService } from '../../core/services/ui/ui.service';
import { AuthService } from 'app/auth/auth.service';
import { Router } from '@angular/router';
import { FirebaseUser } from 'app/auth/auth-data.model';
import { AuthStateService } from './auth.state-service';
import { EmailService } from 'app/core/services/email/email.service';
import { ProjEmailRequest } from 'app/core/models/ProjEmailRequest';
import { ConfirmationService } from 'primeng/api';
import { User, UserListItem, toUser, toUserListItem } from 'app/auth/model/User';
import { UiStateService } from 'app/core/store/ui/state-service';
import { QuerySnapshot } from '@angular/fire/compat/firestore';
import { checkUserSubscription } from 'app/features/admin/clients/model/ClientSubscription';
import { transformSgEmailRequest } from './auth.effects.utils';
import { EditingStatus } from 'app/core/models/common-types';
import { APP_MENU_HOME_LINK } from '@core/models/common-ux-types';
import { Client } from 'app/features/admin/clients/model/Client';
import { ClientStateService } from 'app/features/admin/clients/store/client.state-service';
import { AuthActions } from './action-types';
import { isAdminUser, isClientUserType } from '../model/types';

@Injectable()
export class AuthEffects {
  loadUserForAuth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoadUserForAuth),
      switchMap((action) =>
        this.authService.loadUserForAuthentication(action.firebaseUser.email).pipe(
          map((querySnapshot: QuerySnapshot<User>) => {
            console.log('MS LOAD_USER_FOR_AUTH querySnapshot= ', querySnapshot);
            const users: User[] = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data() as User;
              const id = doc['id'];
              const user: User = {
                ...data,
                id,
              };
              users.push(user);
            });
            console.log('MS LOAD_USER_FOR_AUTH users= ', users);
            this.uiStateService.stopLoading();
            if (isAdminUser(users[0].userType)) {
              return AuthActions.LoadUserForAuthSuccess({ payload: {
                user: users[0],
                client: undefined,
              }});
            } else {
              return AuthActions.LoadUserClientForAuth({ user: users[0] });
            }
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            if (error.code !== 'permission-denied') {
              this.uiService.showSnackbar('Fetching Users failed', null, 3000);
            }
            return of(AuthActions.LoadUserForAuthFail(error));
          })
        )
      )
    )
  );

  loadUserClientForAuth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoadUserClientForAuth),
      switchMap((action) =>
        this.authService.loadUserClientForAuthentication(action.user.clientId).pipe(
          // map((querySnapshot: QuerySnapshot<Client>) => {
          map((client: Client) => {
            console.log('MS LOAD_USER_CLIENT_FOR_AUTH 1 action.payload.clientId= ', action.user.clientId);
            console.log('MS LOAD_USER_CLIENT_FOR_AUTH 2 client= ', client);
            //  //  const client: Client = {...querySnapshot};
            //   const clients: Client[] = [];
            //   querySnapshot.forEach(doc => {
            //     const data = doc.data() as Client;
            //     const id = doc['id'];
            //     const client: Client = {
            //       ...data,
            //       id,
            //     };
            //     clients.push(client);
            //   });
            //   console.log('MS LOAD_USER_CLIENT_FOR_AUTH 3 clients= ', clients);
            //   this.uiStateService.stopLoading();
            return AuthActions.LoadUserForAuthSuccess({ payload: {
              user: action.user,
              client,
            }});
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            if (error.code !== 'permission-denied') {
              this.uiService.showSnackbar('Fetching Users failed', null, 3000);
            }
            return of(AuthActions.LoadUserForAuthFail(error));
          })
        )
      )
    )
  );

  // @Effect({ dispatch: false })
  // loadUserForAuthSuccess$ = this.actions$.pipe(

  loadUserForAuthSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoadUserForAuthSuccess),
      withLatestFrom(
        this.authStateService.fireBaseUser$,
        this.authStateService.isUserAuthenticated$,
        this.authStateService.isFirebaseUserAuthenticated$,
        this.uiStateService.isAdminActive$
      ),
      tap(
        ([action, firebaseUser, _isUserAuthenticated, isFirebaseUserAuthenticated, isAdminActive]: [
          { payload: {user: User; client: Client; }},
          FirebaseUser,
          boolean,
          boolean,
          boolean,
        ]): void => {
          console.log('MS loadUserForAuthSuccess firebaseUser= ', firebaseUser);
          console.log('MS loadUserForAuthSuccess isFirebaseUserAuthenticated= ', isFirebaseUserAuthenticated);
          console.log('MS loadUserForAuthSuccess action.payload= ', action);
          if (isAdminActive) {
            console.log('MS LoadUserForAuthSuccess isAdminActive!!! STOP!');
            return;
          }
          if (firebaseUser && isFirebaseUserAuthenticated) {
            console.log('MS LoadUserForAuthSuccess action.payload', action.payload.user);
            let message = `The user for ${firebaseUser.email} was not found. If this is an error contact customer support.`;
            if (action.payload.user) {
              console.log('MS LoadUserForAuthSuccess action.payload.user.userType= ', action.payload.user.userType);
              const adminUser = isAdminUser(action.payload.user.userType);
              console.log('MS LoadUserForAuthSuccess adminUser= ', adminUser);
              if (adminUser) {
                this.authStateService.setUserAuthenticated(toUserListItem(action.payload.user, null));
                this.authStateService.loadUsers();
                return;
              } else {
                console.log(
                  'MS LoadUserForAuthSuccess action.payload.client.clientSubscription= ',
                  action.payload.client.clientSubscription
                );
                const clientSubscriptionCheck = checkUserSubscription(
                  action.payload.client.clientSubscription.endTimestamp
                );
                console.log('MS loadUserForAuthSuccess retVal clientSubscriptionCheck= ', clientSubscriptionCheck);
                if (action.payload.client.isActive && !clientSubscriptionCheck.isExpired) {
                  this.authStateService.setUserAuthenticated(
                    toUserListItem(action.payload.user, action.payload.client.name)
                  );
                  return;
                } else if (!action.payload.client.isActive) {
                  message = 'Your account is not activated. If this is an error please contact customer support.';
                } else if (clientSubscriptionCheck.isExpired) {
                  message = `${clientSubscriptionCheck.expirationStr}. If this is an error please contact customer support. `;
                }
                this.confirmationService.confirm({
                  header: 'Authentication Error',
                  message,
                  icon: 'pi pi-exclamation-triangle',
                  rejectVisible: false,
                  acceptLabel: 'OK',
                  // accept: () => {
                  //   // this.messageService.add({ severity: 'info', summary: 'OK', detail: 'You have accepted' });
                  // },
                });
              }
            }
            this.authStateService.setUserUnAuthenticated();
          }
        }
      )
    ),
    { dispatch: false }
  );
  // addUser$: Observable<Action> = this.actions$.pipe(
  //   ofType(AuthActions.LoadUserForAuth>(LOAD_USER_FOR_AUTH),
  //   switchMap((action) => this.authService
  //     .loadUserForAuthentication(action.payload.email).pipe(map((docRef) =>
  //     console.log('MS LOAD_USERS querySnapshot= ', querySnapshot);
  //     // querySnapshot.forEach(doc => {
  //     //   const data = doc.data() as User;
  //     //   const id = doc['id'];
  //     //   const user: User = {
  //     //     ...data,
  //     //     id,

  //     //   };

  //     return AuthActions.LoadUserForAuthSuccess()

  //     ),
  //       catchError((error) =>
  //         of(AuthActions.AddUserFail(error))
  //       )
  //     ))
  // );
  // TO DO REFACTOR THIS LATER
  loadUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoadUsers),
      withLatestFrom(this.clientStateService.clientListItems$),
      switchMap(([_action, clients]) =>
        this.authService.getAllUsers().pipe(
          map((querySnapshot: QuerySnapshot<User>) => {
            console.log('MS loadUsers querySnapshot= ', querySnapshot);
            const userListItems: UserListItem[] = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data() as User;
              const id = doc['id'];
              const user: User = {
                ...data,
                id,
              };
              const client = clients?.find((client) => {
                client.id === user.clientId;
              });
              userListItems.push(toUserListItem(user, client ? client.name : ''));
            });
            console.log('MS loadUsers userListItems= ', userListItems);
            this.uiStateService.stopLoading();
            return AuthActions.LoadUsersSuccess( {payload: { userListItems }});
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            if (error.code !== 'permission-denied') {
              this.uiService.showSnackbar('Fetching Users failed', null, 3000);
            }
            return of(AuthActions.LoadUsersFail(error));
          })
        )
      )
    )
  );
  // @Effect()
  // loadUsersOLD$: Observable<Action> = this.actions$.pipe(
  //   ofType(AuthActions.LoadUsers>(LOAD_USERS),
  //   //  withLatestFrom(this.authStateService.loggedInUser$),
  //   switchMap(() => this.authService
  //     .loadUsers2()
  //     .pipe(
  //       map((docArray) => {
  //         //  console.log('MS LOAD_USERS docArray= ', docArray);
  //         const users: User[] = docArray.map(a => {
  //           const data = a.payload.doc.data() as User;
  //           const id = a.payload.doc['id'];
  //           return {
  //             ...data,
  //             id
  //           };
  //         });
  //         //  console.log('MS LOAD_USERS users= ', users);

  //         this.uiStateService.stopLoading();
  //         return AuthActions.LoadUsersSuccess(users)
  //       }),
  //       catchError((error) => {
  //         this.uiStateService.stopLoading();
  //         if (error.code !== 'permission-denied') {
  //           this.uiService.showSnackbar(
  //             'Fetching Users failed',
  //             null,
  //             3000
  //           );
  //         }
  //         return of(AuthActions.LoadUsersFail(error))
  //       })
  //     ))
  // );

  // @Effect({ dispatch: false })
  // loadUsersSuccess$ = this.actions$.pipe(
  //   ofType(AuthActions.LoadUsersSuccess>(LOAD_USERS_SUCCESS),
  //   withLatestFrom(
  //     this.authStateService.fireBaseUser$,
  //     this.authStateService.isUserAuthenticated$,
  //     this.authStateService.isFirebaseUserAuthenticated$,
  //     this.uiStateService.isAdminActive$,
  //   ),
  //   map(([action, firebaseUser, _isUserAuthenticated, isFirebaseUserAuthenticated, isAdminActive]:
  //     [LoadUsersSuccess, FirebaseUser, boolean, boolean, boolean]) => {
  //     console.log('MS LoadUsersSuccess firebaseUser', firebaseUser);
  //     console.log('MS LoadUsersSuccess isFirebaseUserAuthenticated', isFirebaseUserAuthenticated);
  //     console.log('MS LoadUsersSuccess action.payload', action.payload);
  //     if (isAdminActive) {
  //       console.log('MS LoadUsersSuccess isAdminActive!!! STOP!');
  //       return;
  //     }
  //     if (firebaseUser && isFirebaseUserAuthenticated) {
  //       const user = authenticateUser(action.payload, firebaseUser);
  //       // console.log('MS LoadUsersSuccess user', user);
  //       if (user) {
  //         // const retVal = checkSubscription(user);
  //         // console.log('MS LoadUsersSuccess user', user);

  //         this.authStateService.setUserAuthenticated(user);
  //       } else {
  //         this.authStateService.setUserUnAuthenticated();
  //       }
  //     }
  //   })
  // );

  // @Effect({ dispatch: false })
  // reloadUsers$ = this.actions$.pipe(
  //   ofType(AuthActions.LoadUsers>(
  //     UPDATE_USER_SUCCESS,
  //     DELETE_USER_SUCCESS,
  //     ADD_USER_SUCCESS),
  //   tap(() => {
  //     this.authService.loadUsers('last', 'asc', true);
  //   })
  // );

  addUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AddUserSuccess),
        withLatestFrom(this.authStateService.isAdminUser$),
        map(([action, isAdminUser]: [{ userListItem: UserListItem }, boolean]) => {
          // console.log('MS LoadUsersSuccess firebaseUser', firebaseUser);
          // console.log('MS LoadUsersSuccess isFirebaseUserAuthenticated', isFirebaseUserAuthenticated);
          // console.log('MS LoadUsersSuccess isUserAuthenticated', isUserAuthenticated);
          //   this.authStateService.addFirebaseUser(action.payload);

          if (isAdminUser) {
            this.confirmationService.confirm({
              message: `The user: ${action.userListItem.first} ${action.userListItem.last} with the email ${action.userListItem.email} has been added! <p>
          Would you like to add user to the Firebase account now?`,
              header: 'Add User',
              icon: 'pi pi-exclamation-triangle',
              acceptButtonStyleClass: 'p-button-danger p-button-text',
              rejectButtonStyleClass: 'p-button-text p-button-text',
              acceptLabel: 'Yes',
              rejectLabel: 'No',
              accept: () => {
                this.authService.registerUser(
                  {
                    email: action.userListItem.email,
                    password: `${action.userListItem.first}123`,
                  },
                  action.userListItem
                );
              },
              reject: () => { },
            });

            // modalRef.acceptFunc$.subscribe(() => {
            //   this.confirmationService.dismiss();

            //   // console.log('MS confirm user= ', user);
            //   //  this.authStateService.deleteUser(user);
            //   this.authService.registerUser({
            //     email: action.payload.email,
            //     password: `${action.payload.first}123`,
            //   }, action.payload);

            // });
          }
        })
      ),
    { dispatch: false }
  );

  loadPjSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoadSettings),
        tap(() => {
          this.authService.loadPjSettings();
        })
      ),
    { dispatch: false }
  );

  setUserAuthenticated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.SetUserAuthenticated),
        tap((action) => {
          console.log('MS setUserAuthenticated action.userListItem= ', action.userListItem);
          this.router.navigate(['/home']);
          if (isClientUserType(action.userListItem.userType)) {
            this.clientStateService.loadClientForUser(action.userListItem.clientId);
          } else {
            this.clientStateService.loadClients();
          }
        })
      ),
    { dispatch: false }
  );

  userUnAuthenticated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.SetUserUnAuthenticated),
        ofType(AuthActions.SetFirebaseUserUnAuthenticated),
        tap(() => {
          this.uiService.showSnackbar('Your Login Failed, Please Try Again or Contact Customer Support!', null, 3000);
        })
      ),
    { dispatch: false }
  );

  addUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.AddUser),
      switchMap((action) =>
        this.authService.addUser(toUser(action.userListItem)).pipe(
          map(
            (docRef) => {

              const userListItem: UserListItem = {
                ...action.userListItem,
                id: docRef.id
              };
              return AuthActions.AddUserSuccess({ userListItem })
            }
          ),
          catchError((error) => of(AuthActions.AddUserFail(error)))
        )
      )
    )
  );

  updateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.UpdateUser),
      switchMap((action) =>
        this.authService.updateUser(toUser(action.userListItem)).pipe(
          map(() => AuthActions.UpdateUserSuccess(action)),
          catchError((error) => of(AuthActions.UpdateUserFail(error)))
        )
      )
    )
  );

  // @Effect()
  // deleteMultipleUsers$: Observable<Action> = this.actions$.pipe(
  //   ofType(AuthActions.DeleteMultipleUsers>(DELETE_MULTIPLE_USERS),
  //   switchMap((action) => this.authService
  //     .deleteMultipleUsers(action.payload).pipe(map(() =>
  //       AuthActions.DeleteMultipleUsersSuccess()
  //     ),
  //       catchError((error) =>
  //         of(AuthActions.DeleteUserFail(error))
  //       )
  //     ))
  // );

  // @Effect({ dispatch: false })
  // deleteMultipleUsersSuccess$ = this.actions$.pipe(
  //   ofType(AuthActions.DeleteMultipleUsersSuccess>(DELETE_MULTIPLE_USERS_SUCCESS),
  //   tap(() => {
  //     this.authStateService.reloadUsers();
  //   })
  // );

  deleteUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.DeleteUser),
      switchMap((action) =>
        this.authService.deleteUser(action.userId).pipe(
          map(() => AuthActions.DeleteUserSuccess(action)),
          catchError((error) => of(AuthActions.DeleteUserFail(error)))
        )
      )
    )
  );

  updatePjSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.UpdatePjSettings),
      switchMap((action) =>
        this.authService.updatePjSettings(action.projSettings).pipe(
          map(() => AuthActions.UpdateSettingsSuccess(action)),
          catchError((error) => of(AuthActions.UpdateUserFail(error)))
        )
      )
    )
  );

  // @Effect()
  // sendRecaptchaToken$: Observable<Action> = this.actions$.pipe(
  //   ofType(AuthActions.SendRecaptchaToken>(SEND_RECAPTCHA_TOKEN),
  //   switchMap((action) => this.authService
  //     .sendRecaptchaToken(action.payload).pipe(map((res: RecaptchaResponse) => {
  //       if (res.success) {
  //         return AuthActions.SendRecaptchaTokenSuccess()
  //       }
  //     }
  //     ),
  //       catchError((error) =>
  //      //    console.log('MS SendRecaptchaToken error = ', error);
  //          of(AuthActions.SendRecaptchaTokenFail(error))

  //       )
  //     ))
  // );

  // @Effect()
  // emailCustomerContactInfo$: Observable<Action> = this.actions$.pipe(
  //   ofType(AuthActions.EmailNewClientContact>(EMAIL_NEW_CLIENT_CONTACT_INFO),
  //   switchMap((action) => this.emailService
  //     .sendMessage(action.payload.projEmailRequest).pipe(map(() =>
  //     AuthActions.EmailNewClientContactSuccess(action.payload)
  //     ),
  //       catchError((error) =>
  //         of(AuthActions.EmailNewClientContactFail(error))
  //       )
  //     ))
  // );
  emailNewClientContact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.EmailNewClientContact),
      switchMap((action) =>
        this.emailService.sendMessage(action.projEmailRequest).pipe(
          map((res) => {
            this.uiStateService.stopLoading();
            return AuthActions.EmailNewClientContactSuccess(action);
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            return of(AuthActions.EmailNewClientContactFail(error));
          })
        )
      )
    )
  );

  // @Effect()
  // emailCustomerServiceRequest$: Observable<Action> = this.actions$.pipe(
  //   ofType(AuthActions.EmailCustomerServiceRequest>(EMAIL_CUSTOMER_SERVICE_REQUEST),
  //   switchMap((action) => this.emailService
  //     .sendMessage(action.payload).pipe(map((res) => AuthActions.EmailCustomerServiceRequestSuccess(res)
  //     ),
  //       catchError((error) =>
  //         of(AuthActions.EmailCustomerServiceRequestFail(error))
  //       )
  //     ))
  // );

  emailCustomerServiceRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.EmailCustomerServiceRequestSuccess),
        tap((action) => {
          console.log('MS EmailCustomerServiceRequestSuccess action.payload = ', action.projEmailRequest);
          this.uiService.showSnackbar(
            'Your Request has been successfully sent! We look forward to speaking with you.',
            null,
            5000
          );
          // const newSgEmailRequest: ProjEmailRequest = transformSgEmailRequest(action.payload.projEmailRequest, action.payload.newCustomer);
          //   console.log('MS EmailNewClientContactSuccess newSgEmailRequest= ',newSgEmailRequest);
          // TURNING THIS OFF FOR NOW, FOR TESTING we max at 25 per day
          // this.authStateService.sendEmailToCustomer(newSgEmailRequest);
          this.router.navigate([APP_MENU_HOME_LINK]);
        })
      ),
    { dispatch: false }
  );

  sendThankYouToCustomer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.EmailNewClientContactSuccess),
        tap((action) => {
          this.uiService.showSnackbar(
            'Your Request has been successfully sent! We look forward to speaking with you.',
            null,
            3000
          );
          const newSgEmailRequest: ProjEmailRequest = transformSgEmailRequest(
            action.projEmailRequest,
            action.newClient
          );
          //   console.log('MS EmailNewClientContactSuccess newSgEmailRequest= ',newSgEmailRequest);
          // TURNING THIS OFF FOR NOW, FOR TESTING we max at 25 per day
          // this.authStateService.sendEmailToCustomer(newSgEmailRequest);
          this.router.navigate(['/home']);
        })
      ),
    { dispatch: false }
  );

  sendEmailToCustomer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SendEmailToCustomer),
      switchMap((action) =>
        this.emailService.sendMessage(action.projEmailRequest).pipe(
          map((res) => {
            this.uiStateService.stopLoading();
            return AuthActions.SendEmailToCustomerSuccess();
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            return of(AuthActions.SendEmailToCustomerFail(error));
          })
        )
      )
    )
  );

  setUserEditingStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.SetUserEditingStatus),
        tap((action) => {
          console.log('MS SetUserEditingStatus action.editingStatus= ', action.editingStatus);
          this.uiStateService.setIsEditingEntity(action.editingStatus !== EditingStatus.NOT_EDITING);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private uiService: UiService,
    private clientStateService: ClientStateService,
    private uiStateService: UiStateService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private authStateService: AuthStateService,
    private router: Router,
    private emailService: EmailService
  ) { }
}
