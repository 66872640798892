import { ActionReducerMap, MetaReducer, createFeatureSelector } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { RouterStateUrl } from './router/router.state';
import { settingsReducer } from './settings/settings.reducer';
import { SettingsState } from "./settings/settings.model";
import { authReducer } from 'app/auth/store/auth.reducer';
import { AuthState } from 'app/auth/store/auth.state.interface';
import { uiReducer } from './store/ui/reducers';
import { UiState } from './store/ui/state/ui.state.interface';
import { ClientState } from '../features/admin/clients/store/client.state.interface';
import { productsReducer } from '../features/admin/products/store/products.reducer';
import { ProductState } from '../features/admin/products/store/products.state.interface';
import { serviceProductsReducer } from 'app/features/admin/service-products/store/service-products.reducer';
import { ServiceProductState } from 'app/features/admin/service-products/store/service-products.state.interface';
import { clientsReducer } from 'app/features/admin/clients/store/client.reducer';

export const reducers: ActionReducerMap<AppState> = {
  uiState: uiReducer,
  settingsState: settingsReducer,
  router: routerReducer,
  authState: authReducer,
  // gamePlanState: gamePlanReducer,
  clientState: clientsReducer,
  productState: productsReducer,
  serviceProductState: serviceProductsReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [initStateFromLocalStorage];

if (!environment.production) {
  if (!environment.test) {
    metaReducers.unshift(debug);
  }
}

export const selectAuthState = createFeatureSelector<AppState, AuthState>('authState');

export const selectSettingsState = createFeatureSelector<AppState, SettingsState>('settingsState');

export const selectRouterState = createFeatureSelector<AppState, RouterReducerState<RouterStateUrl>>('router');

export interface AppState {
  authState: AuthState;
  clientState: ClientState;
  settingsState: SettingsState;
  router: RouterReducerState<RouterStateUrl>;
  // reportOptionsState: ReportOptionsState;
  // reportDocumentState: ReportDocumentState;
  productState: ProductState;
  // sportBetState: SportBetState;
  // projectState: ProjectState;
  uiState: UiState;
  serviceProductState: ServiceProductState;
}
