import {Action} from '@ngrx/store';
import * as fromActionTypes from '../action-types';

export class StartLoading implements Action {
  readonly type = fromActionTypes.START_LOADING;
}

export class StopLoading implements Action {
  readonly type = fromActionTypes.STOP_LOADING;
}
export class SetIsMobileDevice implements Action {
  readonly type = fromActionTypes.SET_IS_MOBILE_DEVICE;
  constructor(public payload: boolean) {}
}
export class SetIsAdminActive implements Action {
  readonly type = fromActionTypes.SET_IS_ADMIN_ACTIVE;
  constructor(public payload: boolean) {}
}

// export class SetSelectedAdminTabIndex implements Action {
//   readonly type = fromActionTypes.SET_SELECTED_ADMIN_TAB_INDEX;
//   constructor(public payload: number) {}
// }

export class SetIsEditingEntity implements Action {
  readonly type = fromActionTypes.SET_IS_EDITING_ENTITY;
  constructor(public payload: boolean) {}
}

export type UIActions =
  | StartLoading
  | StopLoading
  | SetIsMobileDevice
  | SetIsAdminActive
  | SetIsEditingEntity
  // | SetSelectedAdminTabIndex;
