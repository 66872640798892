import { AuthActions } from './action-types';
import { INITIAL_AUTH_STATE } from './auth.state.interface';
import { createReducer, on } from '@ngrx/store';

export const authReducer = createReducer(
  INITIAL_AUTH_STATE,

  on(AuthActions.SetFirebaseUserAuthenticated, (state, { firebaseUser }) => ({
    ...state,
    isFirebaseUserAuthenticated: true,
    firebaseUser: firebaseUser
  })),
  on(AuthActions.SetUserAuthenticated, (state, { userListItem }) => ({
    ...state,
    isUserAuthenticated: true,
    loggedInUser: userListItem,
  })),
  on(AuthActions.SetUserUnAuthenticated, AuthActions.SetFirebaseUserUnAuthenticated, (state) => ({
    ...INITIAL_AUTH_STATE,
    projSettings: state.projSettings,
  })),
  on(AuthActions.LoadUsers, AuthActions.LoadUsersFail, (state) => ({
    ...state,
    userListItems: [],
  })),
  on(AuthActions.LoadUserForAuthSuccess, (state, {payload }) => ({
    ...state,
    loggedInUserClient: payload.client,
  })),
  on(AuthActions.LoadSettingsSuccess, AuthActions.UpdateSettingsSuccess, (state, { projSettings }) => ({
    ...state,
    projSettings
  })),
  on(AuthActions.SendRecaptchaToken, AuthActions.SendRecaptchaTokenFail, (state) => ({
    ...state,
    isRecaptchaTokenAuthenticated: false,
  })),
  on(AuthActions.SendRecaptchaTokenSuccess, (state) => ({
    ...state,
    isRecaptchaTokenAuthenticated: true,
  })),
  on(AuthActions.LoadUsersSuccess, (state, { payload }) => {
    const newUsers = [...payload.userListItems];
    return {
      ...state,
      userListItems: newUsers.sort((a, b) => a.last.localeCompare(b.last)),
    };
  }),
  on(AuthActions.AddUserSuccess, (state, { userListItem }) => {
    const newUsers = [...state.userListItems, userListItem];
    return {
      ...state,
      userListItems: newUsers.sort((a, b) => a.last.localeCompare(b.last)),
    };
  }),
  on(AuthActions.UpdateUserSuccess, (state, { userListItem }) => {
    const newUsers = [...state.userListItems];
    const newuserListItems = [...state.userListItems];
    const idx2 = newUsers.findIndex((item) => item.id === userListItem.id);
    newuserListItems.splice(idx2, 1, userListItem);
    return {
      ...state,
      userListItems: newuserListItems
    };
  }),
  on(AuthActions.DeleteUserSuccess, (state, { userId }) => {
    const newUsers = [...state.userListItems];
    const newuserListItems = [...state.userListItems];
    const idx2 = newUsers.findIndex((item) => item.id === userId);
    newuserListItems.splice(idx2, 1);
    return {
      ...state,
      userListItems: newuserListItems
    };
  }),
  on(AuthActions.SetSelectedUser, (state, { payload }) => ({
    ...state,
    seletectedUserListItem: payload.userListItem,
  })),
  on(AuthActions.SetUserEditingStatus, (state, { editingStatus }) => ({
    ...state,
    userEditingStatus: editingStatus,
  })),
);
