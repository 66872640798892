import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { ProductsActions } from './action-types';
import { Router } from '@angular/router';
import { UiStateService } from '../../../../core/store/ui/state-service';
import { UiService } from 'app/core/services/ui/ui.service';
import { EditingStatus, LIST_DATE_TIME_FORMAT } from 'app/core/models/common-types';
import { getDatePayloadFromTimeStamp } from '@shared/utils/timestamp-utils';
import { ClientListItem } from 'app/features/admin/clients/model/Client';
import { User } from 'app/auth/model/User';
import { AuthStateService } from 'app/auth/store/auth.state-service';
import { ProductUomType } from '../model/types';
import { ProductService } from '../products.service';
import { Product, ProductListItem } from '../model/Product';
import { ProductStateService } from './products.state-service';
import { ClientStateService } from '../../clients/store/client.state-service';

@Injectable()
export class ProductEffects {
  productsLoadProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.ProductsLoad),
      withLatestFrom(this.authStateService.loggedInUser$, this.clientStateService.clientListItems$),
      switchMap(([_, sgUser, clients]: [Action, User, ClientListItem[]]) =>
        this.productService.loadProducts(sgUser.clientId).pipe(
          map((docArray) => {
            const productListItems: ProductListItem[] = docArray.map((a) => {
              const data = a.payload.doc.data() as Product;
              const id = a.payload.doc['id'];
              const client = clients.find((item) => item.id === data.clientId);
              const dateRetVal = getDatePayloadFromTimeStamp(data.updatedTimestamp, LIST_DATE_TIME_FORMAT);
              const productListItem: ProductListItem = {
                ...data,
                id,
                uomType: data.uomType ?? ProductUomType.EACH,
                clientName: client ? client.name : '',
                isSelected: false,
                currentPrice: data.price,
                updatedDateStr: dateRetVal.dateStr,
                quantity: 0,
                total: 0,
              };
              return productListItem;
            });
            this.uiStateService.stopLoading();
            return ProductsActions.ProductsLoadSuccess({
              productListItems,
            });
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            if (error.code !== 'permission-denied') {
              this.uiService.showSnackbar('Fetching Products failed', null, 3000);
            }
            return of(ProductsActions.ProductsLoadFail(error));
          })
        )
      )
    )
  );

  productAddProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.ProductsAdd),
      switchMap((action) =>
        this.productService.addProduct(action.product).pipe(
          map(() => ProductsActions.ProductsAddSuccess({ product: action.product })),
          catchError((error) => of(ProductsActions.ProductsAddFail(error)))
        )
      )
    )
  );

  productUpdateProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.ProductsUpdate),
      switchMap((action) =>
        this.productService.updateProduct(action.productListItem).pipe(
          map(() =>
            ProductsActions.ProductsUpdateSuccess({
              productListItem: action.productListItem,
            })
          ),
          catchError((error) => of(ProductsActions.ProductsUpdateFail(error)))
        )
      )
    )
  );

  productDeleteMultiple$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.ProductsDeleteMultiple),
      switchMap((action) =>
        this.productService.deleteMultipleProducts(action.productIds).pipe(
          map(() => ProductsActions.ProductsDeleteSuccess()),
          catchError((error) => of(ProductsActions.ProductsDeleteFail(error)))
        )
      )
    )
  );

  productDeleteProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.ProductsDelete),
      switchMap((action) =>
        this.productService.deleteProduct(action.productId).pipe(
          map(() => ProductsActions.ProductsDeleteSuccess()),
          catchError((error) => of(ProductsActions.ProductsDeleteFail(error)))
        )
      )
    )
  );

  deleteProductSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductsActions.ProductsDeleteSuccess,
          ProductsActions.ProductsUpdateSuccess,
          ProductsActions.ProductsAddSuccess
        ),
        map(() => {
          this.productStateService.loadProducts();
        })
      ),
    { dispatch: false }
  );

  navigateOnFinish$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductsActions.ProductsDeleteSuccess,
          ProductsActions.ProductsUpdateSuccess,
          ProductsActions.ProductsAddSuccess
        ),
        map(() => {
          // console.log('MS navigateOnFinish ADMIN_ADD_CUSTOMER_SUCCESS!');
          // this.serviceEventStateService.loadServicePointPackages();
          this.router.navigate(['/admin/product-list']);
        })
      ),
    { dispatch: false }
  );

  setProductEditingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.SetProductEditingStatus),
      tap((action) => {
        console.log('MS SetProductEditingStatus action.payload= ', action.editingStatus);
        this.uiStateService.setIsEditingEntity(action.editingStatus !== EditingStatus.NOT_EDITING);
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private uiService: UiService,
    private productStateService: ProductStateService,
    private authStateService: AuthStateService,
    private clientStateService: ClientStateService,
    private productService: ProductService,
    private uiStateService: UiStateService
  ) { }
}
