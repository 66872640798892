
import { ProductsActions } from './action-types';
import { INITIAL_PRODUCTS_STATE } from './products.state.interface';
import { createReducer, on } from '@ngrx/store';

export const productsReducer = createReducer(
  INITIAL_PRODUCTS_STATE,

  on(ProductsActions.ProductsLoad, ProductsActions.ProductsLoadFail, (state) => ({
    ...state,
    productListItems: [],
    selectedProduct: undefined,
    isLoaded: false,
  })),

  on(ProductsActions.ProductsLoadSuccess, (state, { productListItems }) => {
    const newProducts = [...productListItems];
    return {
      ...state,
      isLoaded: true,
      productListItems: newProducts.sort((a, b) => a.title.localeCompare(b.title)),
    };
  }),

  on(ProductsActions.SetProductEditingStatus, (state, { editingStatus }) => {
    return {
      ...state,
      editingStatus,
    };
  }),

  on(ProductsActions.SetSelectedProduct, (state, { productListItem }) => {
    return {
      ...state,
      selectedProduct: productListItem,
    };
  })
);
