import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { ServiceProductsActions } from './action-types';
import { Router } from '@angular/router';
import { UiStateService } from '../../../../core/store/ui/state-service';
import { UiService } from 'app/core/services/ui/ui.service';
import { EditingStatus, LIST_DATE_TIME_FORMAT } from 'app/core/models/common-types';
import { getDatePayloadFromTimeStamp } from '@shared/utils/timestamp-utils';
import { ClientListItem } from 'app/features/admin/clients/model/Client';
import { User } from 'app/auth/model/User';
import { AuthStateService } from 'app/auth/store/auth.state-service';
import { ServiceProduct, ServiceProductListItem } from '../model/ServiceProduct';
import { ServiceProductStateService } from './service-products.state-service';
import { ServiceProductService } from '../service-products.service';
import { ServiceProductUomType } from '../model/types';
import { ClientStateService } from '../../clients/store/client.state-service';

@Injectable()
export class ServiceProductEffects {
  loadServiceProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceProductsActions.ServiceProductsLoad),
      withLatestFrom(this.authStateService.loggedInUser$, this.clientStateService.clientListItems$),
      switchMap(([_, sgUser, clients]: [Action, User, ClientListItem[]]) =>
        this.serviceProductService.loadServiceProducts(sgUser.clientId).pipe(
          map((docArray) => {
            const serviceProductListItems: ServiceProductListItem[] = docArray.map((a) => {
              const data = a.payload.doc.data() as ServiceProduct;
              const id = a.payload.doc['id'];
              const client = clients.find((item) => item.id === data.clientId);
              const dateRetVal = getDatePayloadFromTimeStamp(data.updatedTimestamp, LIST_DATE_TIME_FORMAT);
              const serviceListItem: ServiceProductListItem = {
                ...data,
                id,
                uomType: data.uomType ?? ServiceProductUomType.EACH,
                clientName: client ? client.name : '',
                isSelected: false,
                currentPrice: data.price,
                updatedDateStr: dateRetVal.dateStr,
                quantity: 0,
                total: 0
              };
              return serviceListItem;
            });
            this.uiStateService.stopLoading();
            return ServiceProductsActions.ServiceProductsLoadSuccess({
              serviceProductListItems
            });
          }),
          catchError((error) => {
            this.uiStateService.stopLoading();
            if (error.code !== 'permission-denied') {
              this.uiService.showSnackbar('Fetching Services failed', null, 3000);
            }
            return of(ServiceProductsActions.ServiceProductsLoadFail(error));
          })
        )
      )
    )
  );

  addServiceProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceProductsActions.ServiceProductsAdd),
      switchMap((action) =>
        this.serviceProductService.addServiceProduct(action.serviceProduct).pipe(
          map(() => ServiceProductsActions.ServiceProductsAddSuccess({ serviceProduct: action.serviceProduct })),
          catchError((error) => of(ServiceProductsActions.ServiceProductsAddFail(error)))
        )
      )
    )
  );

  serviceUpdateService$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceProductsActions.ServiceProductsUpdate),
      switchMap((action) =>
        this.serviceProductService.updateServiceProduct(action.serviceProductListItem).pipe(
          map(() =>
            ServiceProductsActions.ServiceProductsUpdateSuccess({
              serviceProductListItem: action.serviceProductListItem,
            })
          ),
          catchError((error) => of(ServiceProductsActions.ServiceProductsUpdateFail(error)))
        )
      )
    )
  );

  deleteMultipleServiceProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceProductsActions.ServiceProductsDeleteMultiple),
      switchMap((action) =>
        this.serviceProductService.deleteMultipleServiceProducts(action.serviceProductIds).pipe(
          map(() => ServiceProductsActions.ServiceProductsDeleteSuccess()),
          catchError((error) => of(ServiceProductsActions.ServiceProductsDeleteFail(error)))
        )
      )
    )
  );

  deleteServiceProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceProductsActions.ServiceProductsDelete),
      switchMap((action) =>
        this.serviceProductService.deleteServiceProduct(action.serviceProductId).pipe(
          map(() => ServiceProductsActions.ServiceProductsDeleteSuccess()),
          catchError((error) => of(ServiceProductsActions.ServiceProductsDeleteFail(error)))
        )
      )
    )
  );

  deleteServiceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ServiceProductsActions.ServiceProductsDeleteSuccess,
          ServiceProductsActions.ServiceProductsUpdateSuccess,
          ServiceProductsActions.ServiceProductsAddSuccess
        ),
        map(() => {
          this.serviceStateService.loadServiceProducts();
        })
      ),
    { dispatch: false }
  );

  navigateOnFinish$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ServiceProductsActions.ServiceProductsDeleteSuccess,
          ServiceProductsActions.ServiceProductsUpdateSuccess,
          ServiceProductsActions.ServiceProductsAddSuccess
        ),
        map(() => {
          // console.log('MS navigateOnFinish ADMIN_ADD_CUSTOMER_SUCCESS!');
          // this.serviceEventStateService.loadServicePointPackages();
          this.router.navigate(['/admin/service-list']);
        })
      ),
    { dispatch: false }
  );

  setServiceEditingStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ServiceProductsActions.SetServiceProductsEditingStatus),
        tap((action) => {
          console.log('MS SetServiceEditingStatus action.payload= ', action.editingStatus);
          this.uiStateService.setIsEditingEntity(action.editingStatus !== EditingStatus.NOT_EDITING);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private uiService: UiService,
    private serviceStateService: ServiceProductStateService,
    private authStateService: AuthStateService,
    private clientStateService: ClientStateService,
    private serviceProductService: ServiceProductService,
    private uiStateService: UiStateService
  ) { }
}
