import {Pipe, PipeTransform, NgModule} from '@angular/core';

@Pipe({
  name: 'rating',
})
export class SportBetRatingPipe implements PipeTransform {
  getRatingStars(rating: number): string {
    if (rating === 2) {
      return '**';
    } else if (rating === 3) {
      return '***';
    }
    return '*';
  }

  transform(value: string, rating: number): string {
    if (value === undefined) {
      return '';
    }
    return `${value}${this.getRatingStars(rating)}`;
  }
}

@NgModule({
  declarations: [SportBetRatingPipe],
  exports: [SportBetRatingPipe],
})
export class SportBetRatingPipeModule {}
