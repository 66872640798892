import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { Client, ClientListItem } from 'app/features/admin/clients/model/Client';
import { EditingStatus, Guid } from 'app/core/models/common-types';
import { clientListItemsSelector, clientDropdownItemsSelector, selectedClientSelector, isClientSelectedSelector, clientEditingStatusSelector } from './client.selectors';

import { ClientsActions } from './action-types';

@Injectable()
export class ClientStateService {
  clientListItems$ = this.store.select(clientListItemsSelector);
  clientDropdownItems$ = this.store.select(clientDropdownItemsSelector);
  selectedClient$ = this.store.select(selectedClientSelector);
  isClientSelected$ = this.store.select(isClientSelectedSelector);
  // loadedUserClient$ = this.store.select(loadedUserClientSelector);
  clientEditingStatus$ = this.store.select(clientEditingStatusSelector);

  constructor(private store: Store<AppState>) { }

  loadClientForUser(clientId: Guid) {
    this.store.dispatch(ClientsActions.ClientsLoadClientForUser({ clientId }));
  }

  // loadClientForUserSuccess(clientListItem: ClientListItem) {
  //   this.store.dispatch(ClientsActions.LoadClientForUserSuccess(clientListItem));
  // }
  loadClients() {
    this.store.dispatch(ClientsActions.ClientsLoad());
  }
  // loadClientsSuccess(clients: Client[]) {
  //   this.store.dispatch(ClientsActions.ClientsActions.ClientListLoadClientsSuccess(clients));
  // }
  setSelectedClient(clientListItem: ClientListItem) {
    this.store.dispatch(ClientsActions.SetSelectedClient({ clientListItem }));
  }
  setClientEditingStatus(editingStatus: EditingStatus) {
    this.store.dispatch(ClientsActions.SetClientEditingStatus({ editingStatus }));
  }
  addClient(client: Client) {
    this.store.dispatch(ClientsActions.ClientsAdd({ client }));
  }
  addMultipleClients(clients: Client[]) {
    this.store.dispatch(ClientsActions.ClientsAddMultipleClients({ clients }));
  }

  deleteClient(clientId: Guid) {
    this.store.dispatch(ClientsActions.ClientsDelete({ clientId }));
  }
  updateClient(clientListItem: ClientListItem) {
    this.store.dispatch(ClientsActions.ClientsUpdate({ clientListItem }));
  }
}
