import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { EditingStatus, Guid } from 'app/core/models/common-types';
import { ServiceProduct, ServiceProductListItem } from '../model/ServiceProduct';

export const SetServiceProductsEditingStatus = createAction(
  '[ServiceProducts] Set Editing Status',
  props<{ editingStatus: EditingStatus }>()
);

export const SetSelectedServiceProduct = createAction(
  '[ServiceProducts] Set Selected ServiceProduct',
  props<{ serviceProductListItem: ServiceProductListItem }>()
);

export const ServiceProductsLoad = createAction('[ServiceProducts] Load ServiceProducts');

export const ServiceProductsLoadFail = createAction(
  '[ServiceProducts] Load ServiceProducts Fail',
  props<{ error: any }>()
);
export const ServiceProductsLoadSuccess = createAction(
  '[ServiceProducts] Load ServiceProducts Success',
  props<{ serviceProductListItems: ServiceProductListItem[] }>()
);

export const ServiceProductsAdd = createAction(
  '[ServiceProducts] Add New ServiceProduct',
  props<{ serviceProduct: ServiceProduct }>()
);
export const ServiceProductsAddSuccess = createAction(
  '[ServiceProducts] Add New ServiceProduct Success',
  props<{ serviceProduct: ServiceProduct }>()
);
export const ServiceProductsAddFail = createAction(
  '[ServiceProducts] Add New ServiceProduct Fail',
  props<{ error: any }>()
);

export const ServiceProductsDeleteMultiple = createAction(
  '[ServiceProducts]  Delete Multiple ServiceProduct Documents',
  props<{ serviceProductIds: Guid[] }>()
);
export const ServiceProductsDelete = createAction(
  '[ServiceProducts] Delete ServiceProduct',
  props<{ serviceProductId: Guid }>()
);
export const ServiceProductsDeleteSuccess = createAction('[ServiceProducts] Delete ServiceProduct Success');
export const ServiceProductsDeleteFail = createAction(
  '[ServiceProducts] Load ServiceProduct Fail',
  props<{ error: any }>()
);

export const ServiceProductsUpdated = createAction(
  '[ServiceProducts] ServiceProduct Updated',
  props<{ update: Update<ServiceProduct> }>()
);

export const ServiceProductsUpdate = createAction(
  '[ServiceProducts] Update ServiceProduct',
  props<{ serviceProductListItem: ServiceProductListItem }>()
);
export const ServiceProductsUpdateSuccess = createAction(
  '[ServiceProducts] Update ServiceProduct Success',
  props<{ serviceProductListItem: ServiceProductListItem }>()
);
export const ServiceProductsUpdateFail = createAction(
  '[ServiceProducts] Update ServiceProduct Fail',
  props<{ error: any }>()
);
