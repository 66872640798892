import {Type} from '@angular/core';
import {ClientServiceType} from '@core/models/common-types';
import {PDropdownItem, SinglePluralString} from '@core/models/common-ux-types';

export enum ProductType {
  PRODUCT = 'Product',
  OTHER = 'Other',
  // MATERIAL = 'Material',
  //  SUPPLY = 'Supply'
}

// export const productTypePDropdownItems: PDropdownItem[] = [
//   { value: ProductType.PRODUCT, viewValue: ProductType.PRODUCT },
//   { value: ProductType.OTHER, viewValue: ProductType.OTHER },
//   // { value: ProductType.MATERIAL, viewValue: ProductType.MATERIAL },
//   //  { value: ProductType.SUPPLY, viewValue: ProductType.SUPPLY },
// ];

export enum ProductUomType {
  EACH = 'EA',
  FT = 'FT',
  SQFT = 'SF',
  BG = 'BG',
  HR = 'HR',
  // CUFT = 'CUFT',
  OTHER = 'Other',
  NA = 'NA',
  // MATERIAL = 'Material',
  //  SUPPLY = 'Supply'
}

// export function getProductUomTypeDropdownItems(): PDropdownItem[]  {
//   const keys = Object.keys(ProductUomType);
//   const items: PDropdownItem[] =[];

// Object.values(ProductUomType).forEach((value) => {
//   items.push({value: value});
// });

// // keys.forEach((key, value) => {
// //         console.log(`MS ${key} has value ${value}`);
// // })
// console.log('MS getProductUomTypeDropdownItems items= ', items);
// return items;

// // }
// export const productUomTypePDropdownItems: PDropdownItem[] = [
//   { value: ProductUomType.EACH, viewValue: ProductUomType.EACH },
//   { value: ProductUomType.FT, viewValue: ProductUomType.FT },
//   { value: ProductUomType.SQFT, viewValue: ProductUomType.SQFT },
//   { value: ProductUomType.OTHER, viewValue: ProductUomType.OTHER },
// ];

export enum SupplierType {
  PLATT = 'Platt',
  HOME_DEPOT = 'HomeDepot',
  OTHER = 'Other',
  // MATERIAL = 'Material',
  //  SUPPLY = 'Supply'
}
export const SupplierTypes: string[] = [
  SupplierType.PLATT,
  SupplierType.HOME_DEPOT,
  SupplierType.OTHER,
  // { value: ProductType.MATERIAL, viewValue: ProductType.MATERIAL },
  //  { value: ProductType.SUPPLY, viewValue: ProductType.SUPPLY },
];
export enum ProductImportFileType {
  PLATT_ORDER = 'PlattOrder',
  PLATT_CART = 'PlattCart',
  RESCOM_PRODUCTS = 'ResComProducts',
  RESCOM_SERVICES = 'ResComServices',
}

export enum ServiceImportFileType {
  RESCOM_SERVICES = 'ResComServ',
}

export function getProductMaterialStr(clientServiceType: ClientServiceType): SinglePluralString {
  switch (clientServiceType) {
    case ClientServiceType.SERVICE_CABINET:
    case ClientServiceType.SERVICE_MITIGATION:
    case ClientServiceType.SERVICE_FLOORING:
      return {
        single: 'Product',
        plural: 'Products',
      };
  }
  return {
    single: 'Material',
    plural: 'Materials',
    // single: 'Product',
    // plural: 'Products'
  };
}
