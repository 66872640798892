import { getDatePayloadFromTimeStamp } from 'app/shared/utils/timestamp-utils';
import firebase from 'firebase/compat/app';
import { add } from 'date-fns';
import { DISPLAY_DATE_FORMAT, SubscriptionLengthType, SubscriptionType } from '../../../../core/models/common-types';
import { DatePayload } from '../../../../core/models/common-ux-types';

export interface ClientSubscription {
  subscriptionType: SubscriptionType;
  subscriptionLengthType: SubscriptionLengthType;
  startTimestamp: firebase.firestore.Timestamp;
  endTimestamp: firebase.firestore.Timestamp;
  monthlyPrice: number;
  yearlyPrice: number;
  discount: number;
}
export interface ClientSubscriptionCheck {
  isExpired: boolean;
  daysTillExpiration: number;
  expirationStr: string;
}

export interface ClientSubscriptionItem extends ClientSubscription {
  startDatePayload: DatePayload;
  endDatePayload: DatePayload;
  clientSubscriptionCheck?: ClientSubscriptionCheck;
  buttonTitle?: string;
  features?: string[];
}

export const defaultClientSubscription: ClientSubscription = {
  subscriptionType: SubscriptionType.PREMIUM,
  subscriptionLengthType: SubscriptionLengthType.MONTHLY,
  startTimestamp: null,
  endTimestamp: null,
  discount: 0,
  monthlyPrice: 0,
  yearlyPrice: 0,
};

export function getDefultClientSubscription(): ClientSubscription {
  const endDate = add(new Date(), { months: 1 });

  //const endDate = add(new Date()).add(1, 'month').toDate();
  return {
    ...defaultClientSubscription,
    startTimestamp: firebase.firestore.Timestamp.now(),
    endTimestamp: firebase.firestore.Timestamp.fromDate(endDate),
  };
}

export function toClientSubscription(item: ClientSubscriptionItem): ClientSubscription {
  return {
    subscriptionType: item.subscriptionType,
    subscriptionLengthType: item.subscriptionLengthType,
    startTimestamp: firebase.firestore.Timestamp.fromDate(item.startDatePayload.date),
    endTimestamp: firebase.firestore.Timestamp.fromDate(item.endDatePayload.date),
    discount: item.discount,
    monthlyPrice: item.monthlyPrice,
    yearlyPrice: item.yearlyPrice,
  };
}

export function toClientSubscriptionItem(item: ClientSubscription): ClientSubscriptionItem {
  return {
    ...item,
    startDatePayload: item.startTimestamp
      ? getDatePayloadFromTimeStamp(item.startTimestamp, DISPLAY_DATE_FORMAT)
      : null,
    endDatePayload: item.endTimestamp ? getDatePayloadFromTimeStamp(item.endTimestamp, DISPLAY_DATE_FORMAT) : null,
    clientSubscriptionCheck: item.endTimestamp ? checkUserSubscription(item.endTimestamp) : null,
  };
}

// export interface ClientSubscriptionCheck {
//   isExpired: boolean;
//   daysTillExpiration: number;
//   expirationStr: string;
// }
export function checkUserSubscription(endTimestamp: firebase.firestore.Timestamp): ClientSubscriptionCheck {
  const subEndPayload = getDatePayloadFromTimeStamp(endTimestamp);
  // console.log('MS checkUserSubscription subEndPayload= ', subEndPayload);
  // const nowPayload = getDatePayloadFromTimeStamp(firebase.firestore.Timestamp.now());
  // console.log('MS checkUserSubscription nowPayload= ', nowPayload);
  let diffInSeconds = endTimestamp.seconds - firebase.firestore.Timestamp.now().seconds;
  if (diffInSeconds < 0) {
    diffInSeconds *= -1;
  }
  // console.log('MS checkUserSubscription diffInSeconds= ', diffInSeconds);
  let isExpired = false;
  const daysTillExpiration = Math.round(diffInSeconds / 86400);
  let expirationStr = null;

  // const daysTillExpiration =  Math.round((diffInSeconds /  86400));
  if (diffInSeconds > 0) {
    // console.log('MS checkUserSubscription daysTillExpiration= ', daysTillExpiration);
    expirationStr = `Your Subscription expires in ${daysTillExpiration.toString()} Days`;
  } else {
    isExpired = true;
    expirationStr = `Your Subscription Expired ${subEndPayload.dateStr}`;
  }
  // console.log('MS checkUserSubscription daysTillExpiration= ', daysTillExpiration);
  // console.log('MS checkUserSubscription expirationStr= ', expirationStr);
  return { isExpired, daysTillExpiration, expirationStr };
}
